import { action, makeObservable, observable } from 'mobx';

import type {
  IAcceptAch,
  IAcceptCard,
} from '../interfaces/IServicesAcceptance';

class ServicesStore {
  ach: IAcceptAch;

  card: IAcceptCard;

  errors: any = {};

  constructor() {
    this.ach = {
      acceptWeb: false,
      acceptPPD: false,
      acceptCCD: false,
      price: [],
      fees: [],
    };
    this.card = {
      acceptVisa: false,
      acceptMastercard: false,
      acceptDiscover: false,
      acceptAmex: false,
      price: [],
      fees: [],
    };
    makeObservable(this, {
      ach: observable,
      card: observable,
      errors: observable,
      setError: action,
    });
  }

  /**
   * It takes a key of the ServicesStore interface and a boolean value and sets the value of the key in
   * the errors object to the boolean value
   * @param {N} name - The name of the service.
   * @param {boolean} value - The value of the input.
   */
  setError<N extends keyof ServicesStore>(name: N, value: boolean): void {
    this.errors[name] = value;
  }

  /**
   * It takes a string that is a key of the ServicesStore interface, and returns a boolean
   * @param {N} name - The name of the service you want to check the error state of.
   * @returns A boolean value.
   */
  getError<N extends keyof ServicesStore>(name: N): boolean {
    return this.errors[name];
  }

  /**
   * It returns an object with two properties, ach and card.
   * @returns An object with two properties, ach and card.
   */
  getJson(): any {
    return {
      services: {
        ach: this.ach,
        card: this.card,
      },
    };
  }
}
export default ServicesStore;
