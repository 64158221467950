import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Transition } from 'semantic-ui-react';

import { OWNER_STATUS } from '../interfaces/IOwner';
import { renderInput } from '../utils/input-tools';

class Ownership extends React.Component<any, any> {
  ownerData: Array<any> = new Array<any>();

  ownerIndex: number = 0;

  ownerInfo: any;

  ownerBuilder: any;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    this.ownerData = props.ownerData;
    this.ownerBuilder = props.ownerBuilder;
    this.ownerIndex = props.ownerIndex;
    this.ownerInfo = props.ownerInfo;
    this.onCopyBusinessAddress = this.onCopyBusinessAddress.bind(this);
  }

  onCopyBusinessAddress(e: any, ownerId: number): void {
    this.props.boarding.copyAddressFromBusiness(ownerId);
  }

  componentDidMount(): void {
    this.setState(
      {
        visible: true,
      },
      () => {
        Object.entries(this.ownerBuilder || []).map(
          (owner: any, index: number): any => {
            const inputName = owner[0];
            const ownerItem = this.ownerData[0][inputName];
            const fieldMetadata = {
              name: `o_${this.ownerInfo.id}_${inputName}`,
              storeName: inputName,
              label: ownerItem?.label,
              toolTip: ownerItem?.toolTip,
              index: this.ownerInfo.id || index,
              required: ownerItem?.required,
              readonly: ownerItem?.readonly,
              restrictedDob: new Date(
                new Date().getFullYear() - 18,
                new Date().getMonth(),
                new Date().getDay()
              ),
              value:
                this.ownerInfo.status === OWNER_STATUS.new
                  ? this.ownerBuilder[inputName]?.value
                  : this.ownerInfo[inputName],
              boarding: this.props.boarding,
              identifyField: inputName[0],
              locationFields: [
                'address',
                'address1',
                'city',
                'state',
                'zip',
                'country',
              ],
              component: 'owners',
              metadata: { index: this.ownerIndex },
            };
            if (!owner[1]?.visible) {
              this.props.boarding[fieldMetadata.component].setProp(
                inputName,
                fieldMetadata.value,
                fieldMetadata.metadata
              );
            }
          }
        );
      }
    );
  }

  render(): React.ReactNode {
    return (
      <div key="metadiv-owners">
        <Transition
          key="trans-owners-div"
          duration={400}
          visible={this.state?.visible}
        >
          <Grid doubling columns={2}>
            {Object.entries(this.ownerBuilder || []).map(
              (owner: any, index: number): any => {
                const inputName = owner[0];
                const ownerItem = this.ownerData[0][inputName];
                if (
                  inputName === 'ownerpercent' &&
                  this.ownerBuilder[inputName]?.value === ''
                ) {
                  this.ownerBuilder[inputName].value = 0;
                }
                if (inputName === undefined || inputName === '') return null;
                if (ownerItem === undefined || ownerItem === '') return null;
                const fieldMetadata = {
                  name: `o_${this.ownerInfo.id}_${inputName}`,
                  storeName: inputName,
                  label: ownerItem?.label,
                  toolTip: ownerItem?.toolTip,
                  index: this.ownerInfo.id || index,
                  required: ownerItem?.required,
                  readonly: ownerItem?.readonly,
                  restrictedDob: new Date(
                    new Date().getFullYear() - 18,
                    new Date().getMonth(),
                    new Date().getDay()
                  ),
                  value:
                    this.ownerInfo.status === OWNER_STATUS.new
                      ? this.ownerBuilder[inputName]?.value
                      : this.ownerInfo[inputName],
                  boarding: this.props.boarding,
                  identifyField: inputName[0],
                  locationFields: [
                    'address',
                    'address1',
                    'city',
                    'state',
                    'zip',
                    'country',
                  ],
                  component: 'owners',
                  metadata: { index: this.ownerIndex },
                };
                if (inputName === 'oaddress') {
                  return (
                    owner[1]?.visible && (
                      <Grid.Column
                        key={`grid-oaddress-fileds${index}`}
                        floated={
                          index === 0 || index % 2 === 0 ? 'left' : 'right'
                        }
                        width={16}
                      >
                        {renderInput(ownerItem.type, fieldMetadata)}
                        {/* <CopyInfoInput copyText='Copy address from business details' copyHandlerClick={(e: any): void => this.onCopyBusinessAddress(e, this.ownerInfo.id)} /> */}
                      </Grid.Column>
                    )
                  );
                }
                return (
                  owner[1]?.visible && (
                    <Grid.Column
                      key={`grid-normal-fileds${index}`}
                      floated={
                        index === 0 || index % 2 === 0 ? 'left' : 'right'
                      }
                      width={8}
                    >
                      {renderInput(ownerItem.type, fieldMetadata)}
                    </Grid.Column>
                  )
                );
              }
            )}
          </Grid>
        </Transition>
      </div>
    );
  }
}

export default inject('boarding')(observer(Ownership));
