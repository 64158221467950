import type { IElement } from './IElement';

export enum STATUS {
  new,
  updated,
  pushed,
  deleted,
  toDelete,
}
export interface IAttachment extends IElement {
  id: number;
  ftype: string;
  filename: string;
  fileDescriptor: string;
  furl: string;
  fsize: number;
  fContent: string;
  errors: any;
  status: STATUS;
  zipName: string;
}
