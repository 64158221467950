import { inject, observer } from 'mobx-react';
import React from 'react';

import BoardingLinkView from './BoardingLinkView';

class MainCointainerLinkView extends React.Component<any, any> {
  render(): React.ReactNode {
    return (
      <div className="">
        <div className="main-container">
          <BoardingLinkView
            {...{
              ...this.props,
              match: this.props.match,
              edit: this.props.edit,
              mode: this.props.mode,
            }}
          />
        </div>
      </div>
    );
  }
}

export default inject('boarding')(observer(MainCointainerLinkView));
