import { inject, observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'semantic-ui-react';

class CopyInfoInput extends React.Component<any, any> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);
  }

  render(): React.ReactNode {
    return (
      <div
        className="copy-bank-info"
        onClick={this.props?.copyHandlerClick || ''}
      >
        <span>
          <Icon name="copy outline" />
          {this.props?.copyText || ''}
        </span>
      </div>
    );
  }
}

export default inject('boarding')(observer(CopyInfoInput));
