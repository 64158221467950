import { inject, observer } from 'mobx-react';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

class SignerSelection extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: '',
      name: '',
      component: '',
      error: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name: string, event: any, info: any): void {
    this.setState(
      {
        value: info.value,
      },
      (): void => {
        [
          'name',
          'ssn',
          'phone',
          'email',
          'address',
          'address1',
          'state',
          'country',
          'city',
          'zip',
        ].map((field: any) => {
          this.props.boarding.signer.setProp(field, '');
          this.props.boarding.clearSignerSelected();
        });
        this.props.boarding.signer.setProp('dob', null);
        if (info.value !== '') {
          const selectedSigner = JSON.parse(info.value);
          this.props.boarding.setSignerSelected({
            id: selectedSigner.id,
            type: selectedSigner.type,
          });
          if (selectedSigner.type === 'contact') {
            this.props.boarding.signer.setProp(
              'name',
              selectedSigner.contactName
            );
            this.props.boarding.signer.setProp(
              'email',
              selectedSigner.contactEmail
            );
            this.props.boarding.signer.setProp(
              'phone',
              selectedSigner.contactPhone
            );
          } else {
            this.props.boarding.signer.setProp(
              'name',
              selectedSigner.ownername
            );
            this.props.boarding.signer.setProp('ssn', selectedSigner.ownerssn);
            this.props.boarding.signer.setProp(
              'dob',
              new Date(selectedSigner.ownerdob)
            );
            this.props.boarding.signer.setProp(
              'phone',
              selectedSigner.ownerphone1
            );
            this.props.boarding.signer.setProp(
              'email',
              selectedSigner.owneremail
            );
            this.props.boarding.signer.setProp(
              'address',
              selectedSigner.oaddress
            );
            this.props.boarding.signer.setProp(
              'address1',
              selectedSigner.oaddress1
            );
            this.props.boarding.signer.setProp('state', selectedSigner.ostate);
            this.props.boarding.signer.setProp(
              'country',
              selectedSigner.ocountry
            );
            this.props.boarding.signer.setProp('city', selectedSigner.ocity);
            this.props.boarding.signer.setProp('zip', selectedSigner.ozip);
          }
        }
      }
    );
  }

  render(): React.ReactNode {
    let optionsSigners = [];
    if (this.props.owners && this.props.showOwners) {
      optionsSigners = this.props.boarding.owners.elementsList.map(
        (owner: any, index: number) => {
          owner.type = 'owner';
          return {
            key: `o${index}`,
            text: owner.ownername,
            value: JSON.stringify(owner),
          };
        }
      );
    }
    if (this.props.contacts) {
      optionsSigners = optionsSigners.concat(
        this.props.boarding.contacts.elementsList.map(
          (contact: any, index: number) => {
            contact.type = 'contact';
            return {
              key: `c${index}`,
              text: contact.contactName,
              value: JSON.stringify(contact),
            };
          }
        )
      );
    }
    let selectedValue = null;
    if (this.props.boarding.signerSelected.id !== -1) {
      selectedValue = optionsSigners.filter((option: any) => {
        const selected = JSON.parse(option.value);
        return (
          selected.id === this.props.boarding.signerSelected.id &&
          selected.type === this.props.boarding.signerSelected.type
        );
      });
    }
    if (selectedValue?.length) {
      selectedValue = selectedValue[0].value;
    }
    return (
      <>
        <div className="" style={{ marginBottom: '1rem' }}>
          <Dropdown
            placeholder="Select Signer"
            fluid
            selection
            clearable
            options={optionsSigners}
            onChange={(event: any, info: any): void =>
              this.handleChange(this.props.iName, event, info)
            }
            value={
              this.state.value
                ? String(this.state.value)
                : selectedValue !== null
                ? selectedValue
                : ''
            }
          />
        </div>
      </>
    );
  }
}

export default inject('boarding')(observer(SignerSelection));
