import { action, makeObservable, observable } from 'mobx';

import type { IBoardingData } from '../interfaces/IBoardingData';

class BusinessStore implements IBoardingData {
  dbaname: string = '';

  legalname: string = '';

  btype: string = '';

  website: string = '';

  ein: string = '';

  taxfillname: string = '';

  license: string = '';

  licstate: string = '';

  startdate: Date | null = null;

  phonenumber: string = '';

  faxnumber: string = '';

  baddress: string = '';

  baddress1: string = '';

  bcity: string = '';

  bstate: string = '';

  bzip: string = '';

  bcountry: string = '';

  maddress: string = '';

  maddress1: string = '';

  mcity: string = '';

  mstate: string = '';

  mzip: string = '';

  mcountry: string = '';

  sameBusinessAddress: boolean = true;

  errors: any = {};

  constructor() {
    makeObservable(this, {
      dbaname: observable,
      legalname: observable,
      btype: observable,
      website: observable,
      ein: observable,
      taxfillname: observable,
      license: observable,
      licstate: observable,
      startdate: observable,
      phonenumber: observable,
      faxnumber: observable,
      baddress: observable,
      baddress1: observable,
      bcity: observable,
      bstate: observable,
      bzip: observable,
      bcountry: observable,
      maddress: observable,
      maddress1: observable,
      mcity: observable,
      mstate: observable,
      mzip: observable,
      mcountry: observable,
      sameBusinessAddress: observable,
      errors: observable,
      setProp: action,
      setError: action,
    });
  }

  /**
   * The function above is called when the user clicks on the checkbox to indicate that the mailing
   * address is the same as the business address
   */
  sameAsBusinessAddress(): void {
    this.maddress = this.baddress;
    this.maddress1 = this.baddress1;
    this.mcity = this.bcity;
    this.mstate = this.bstate;
    this.mzip = this.bzip;
    this.mcountry = this.bcountry;
  }

  sameTaxFillNameAsLegalName(): void {
    this.taxfillname = this.legalname;
  }

  /**
   * It takes a key of type N, which is a key of the BusinessStore interface, and a value of any type.
   * It then sets the value of the key to the value passed in
   * @param {N} name - The name of the property to set.
   * @param {any} value - any - the value to set
   */
  setProp<N extends keyof BusinessStore>(name: N, value: any): void {
    const fields = [
      'baddress',
      'baddress1',
      'bcity',
      'bstate',
      'bzip',
      'bcountry',
    ];
    this[name] = value;
    if (this.sameBusinessAddress && fields.includes(name)) {
      const key: N = `m${name.slice(1)}` as N;
      this[key] = value;
    }
  }

  /**
   * If the sameBusinessAddress property is true, and the field parameter is one of the fields in the
   * fields array, then return the value of the property whose name is the value of the field parameter
   * with the first character replaced with an 'm'
   * @param {K} field - K - the field name
   * @returns The value of the field.
   */
  getValue<K extends keyof BusinessStore>(field: K): any {
    return this[field];
  }

  /**
   * The function takes a string and a boolean as arguments and sets the value of the string to the
   * boolean
   * @param {N} name - The name of the field to set the error on.
   * @param {boolean} value - The value of the field.
   */
  setError<N extends keyof BusinessStore>(name: N, value: boolean): void {
    const fields = [
      'baddress',
      'baddress1',
      'bcity',
      'bstate',
      'bzip',
      'bcountry',
    ];
    this.errors[name] = value;
    if (this.sameBusinessAddress && fields.includes(name)) {
      const key: N = `m${name.slice(1)}` as N;
      this.errors[key] = value;
    }
  }

  /**
   * It takes a string that is a key of the BusinessStore interface and returns a boolean
   * @param {N} name - The name of the error.
   * @returns A boolean value.
   */
  getError<N extends keyof BusinessStore>(name: N): boolean {
    return this.errors[name];
  }
}
export default BusinessStore;
