import type { IElement } from './IElement';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum OWNER_STATUS {
  new,
  posted,
}
export interface IOwner extends IElement {
  ownername: string;
  ownertitle: string;
  ownerpercent: number;
  ownerssn: string;
  ownerdob: Date | null;
  ownerphone1: string;
  ownerphone2: string;
  owneremail: string;
  ownerdriver: string;
  odriverstate: string;
  oaddress: string;
  oaddress1: string;
  ostate: string;
  ocountry: string;
  ocity: string;
  ozip: string;
  errors: any;
  status: OWNER_STATUS;
}
