/* eslint-disable no-debugger */
import { action, makeObservable, observable } from 'mobx';

import type { IAccountInfo } from '../interfaces/IAccountInfo';
import type { IBankError } from '../interfaces/IBankError';
import type { IBankInfo } from '../interfaces/IBankInfo';
import type { IBoardingData } from '../interfaces/IBoardingData';

class BankingStore implements IBoardingData {
  accountsInfo: IAccountInfo;

  constructor() {
    this.accountsInfo = {
      depositAccount: {
        bankName: '',
        routingAccount: '',
        accountNumber: '',
        typeAccount: '',
        errors: {
          bankName: false,
          routingAccount: false,
          accountNumber: false,
          typeAccount: false,
        } as IBankError,
      } as IBankInfo,
      withdrawalAccount: {
        bankName: '',
        routingAccount: '',
        accountNumber: '',
        typeAccount: '',
        errors: {
          bankName: false,
          routingAccount: false,
          accountNumber: false,
          typeAccount: false,
        } as IBankError,
      } as IBankInfo,
    };
    makeObservable(this.accountsInfo, {
      depositAccount: observable,
      withdrawalAccount: observable,
    });
    makeObservable(this, {
      setProp: action,
      setError: action,
    });
  }

  /**
   * It takes the accountsInfo object, which is a React state object, and returns a new object with the
   * same properties, but without the errors property
   * @returns The deposit and withdrawal accounts are being returned.
   */
  getJson(): any {
    const deposit = Object.fromEntries(
      Object.entries(this.accountsInfo.depositAccount || [])
    );
    delete deposit.errors;
    const withdrawal = Object.fromEntries(
      Object.entries(this.accountsInfo.withdrawalAccount || [])
    );
    delete withdrawal.errors;
    return {
      depositAccount: deposit,
      withdrawalAccount: withdrawal,
    };
  }

  /**
   * It takes a key of type K, which is a key of IBankInfo, and a metadata object, and returns the
   * value of the key K in the bankInfo object
   * @param {K} field - The field name of the bankInfo object.
   * @param metadata - The metadata object that was passed to the getValue function.
   * @returns The value of the field in the bankInfo object.
   */
  getValue<K extends keyof IBankInfo>(
    field: K,
    metadata: Record<string, string | Object>
  ): any {
    const typeBank = metadata.typeBank as keyof IAccountInfo;
    const bankInfo: IBankInfo = this.accountsInfo[typeBank];
    return bankInfo[field];
  }

  /**
   * This function copies the bank information from the deposit account to the withdrawal account
   */
  copyBankInfo(): void {
    this.accountsInfo.withdrawalAccount.bankName =
      this.accountsInfo.depositAccount.bankName;
    this.accountsInfo.withdrawalAccount.routingAccount =
      this.accountsInfo.depositAccount.routingAccount;
    this.accountsInfo.withdrawalAccount.accountNumber =
      this.accountsInfo.depositAccount.accountNumber;
    this.accountsInfo.withdrawalAccount.typeAccount =
      this.accountsInfo.depositAccount.typeAccount;
  }

  /**
   * It sets the property of the bankInfo object.
   * @param {N} name - N - The name of the property to set.
   * @param {V} value - V - the value of the property
   * @param metadata - Record<string, string | Object>
   */
  setProp<N extends keyof IBankInfo, V extends IBankInfo[N]>(
    name: N,
    value: V,
    metadata: Record<string, string | Object>
  ): void {
    const typeBank = metadata.typeBank as keyof IAccountInfo;
    const bankInfo: IBankInfo = this.accountsInfo[typeBank];
    bankInfo[name] = value;
  }

  /**
   * It sets the error for a bank account.
   * @param {N} name - The name of the error.
   * @param {any} value - The value of the field that failed validation.
   * @param metadata - {
   */
  setError<N extends keyof IBankError>(
    name: N,
    value: any,
    metadata: Record<string, string | Object>
  ): void {
    const typeBank = metadata.typeBank as keyof IAccountInfo;
    const bankInfo: IBankInfo = this.accountsInfo[typeBank];
    bankInfo.errors[name] = value;
  }

  /**
   * It returns a boolean value.
   * @param {N} name - The name of the error.
   * @param metadata - Record<string, string | Object>
   * @returns A boolean value.
   */
  getError<N extends keyof IBankError>(
    name: N,
    metadata: Record<string, string | Object>
  ): boolean {
    const typeBank = metadata.typeBank as keyof IAccountInfo;
    const bankInfo: IBankInfo = this.accountsInfo[typeBank];
    return bankInfo.errors[name] as boolean;
  }
}
export default BankingStore;
