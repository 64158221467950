import { action, computed, makeObservable, observable } from 'mobx';

import type { ITermsCondition } from '../interfaces/ITermsCondition';
import AbstractContainerStore from './AbstractContainerStore';

class TermsConditionStore extends AbstractContainerStore<ITermsCondition> {
  signature: any;

  constructor() {
    super();
    this.signature = null;
    makeObservable(this, {
      addTerm: action,
      acceptTerm: action,
      signTerms: action,
      completeAccepted: computed,
      signature: observable,
    });
  }

  /**
   * If the elementsList array has at least one element and every element in the array has an accepted
   * property that is true, then return true
   * @returns A boolean value.
   */
  get completeAccepted(): boolean {
    return (
      this.elementsList.length > 0 &&
      this.elementsList.every((term) => term.accepted === true)
    );
  }

  /**
   * It takes a label, value, and accepted boolean and returns a void
   * @param {string} label - The label of the term.
   * @param {string} value - string - The value of the term.
   * @param {boolean} accepted - boolean - This is the default value for the checkbox.
   */
  addTerm(label: string, value: string, accepted: boolean): void {
    const term: ITermsCondition = {
      id: this.getNextId(),
      label,
      value,
      accepted,
    };
    this.add(term);
  }

  /**
   * If the elementsList array has an element with an id that matches the id passed in, set the
   * accepted property of that element to true
   * @param {number} id - number - the id of the element to be accepted
   */
  acceptTerm(id: number): void {
    if (this.elementsList.length >= id) {
      this.elementsList[+id].accepted = true;
    }
  }

  /**
   * The function takes a signature as an argument and assigns it to the signature property
   * @param {any} sign - The signature object returned by the signature pad.
   */
  signTerms(sign: any): void {
    this.signature = sign;
  }

  /**
   * The function takes two parameters, a sign and a value, and sets the signature property to the
   * value
   * @param {any} sign - The sign object that is being edited.
   * @param {string} value - The value of the signature.
   */
  setProp(sign: any, value: string): void {
    this.signature = value;
  }

  /**
   * The function clears the signature from the signature pad
   */
  clearTerms(): void {
    this.signature = null;
  }

  /**
   * It returns an object with two properties: termsCondition and completeAccepted.
   * The termsCondition property is an array of objects. Each object has two properties: id and
   * accepted.
   * The completeAccepted property is a boolean.
   * @returns An object with the termsCondition and completeAccepted properties.
   */
  getJson(): any {
    return {
      termsCondition: this.elementsList,
      completeAccepted: this.completeAccepted,
    };
  }
}
export default TermsConditionStore;
