export const pdfHtml = `
<body>

<div class="div">
  <div class="div-2">
    <div class="builder-columns div-3">
      <div class="builder-column column">
        <div class="ironwood-mpa-2020-dec-payabli">
          <div class="builder-image-sizer image-sizer"></div>
        </div>
      </div>
      <div class="builder-column column-2">
        <div class="div-4">
          <div class="merchant-application-fee-sch">
            Merchant Application & Fee Schedule
          </div>
          <div class="please-carefully-complete-the">
            Please carefully complete the Merchant Application and read the
            Terms and Conditions and other additional forms, as applicable to
            you, which together make up the Merchant Processing Agreement
            (“Agreement”). The Terms and Conditions can be viewed at
            https://teamironwood.com/documents. Please retain the Merchant
            Application as well as the website to review the Terms and
            Conditions for your records. Ironwood Strategic, LLC (“ISO”) and
            Member Bank’s acceptance of the Merchant Application will be made in
            a manner authorized in the Agreement. (Merchant Application and/or
            Terms and Conditions).
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-5">
    <div class="div">
      <div class="div-6">
        <div class="div-7">Business Details</div>
        <div class="you-have-an-awesome-business">
          You have an awesome business, tell us a little bit about it!
        </div>
      </div>
      <div class="div-8">
        <div class="builder-columns div-9">
          <div class="builder-column column-3">
            <div class="div-10">
              <div class="div-11">
                <div class="div-12">
                  <div class="div-13">Business DBA Name</div>
                  <div class="div-14">Punch List Remodeling</div>
                </div>
                <div class="div-15">
                  <div class="div-16">Business LEGAL Name</div>
                  <div class="div-17">Punch List LLC</div>
                </div>
                <div class="div-18">
                  <div class="div-19">business type</div>
                  <div class="div-20">Limited Liability Company</div>
                </div>
                <div class="div-21">
                  <div class="div-22">business EIN</div>
                  <div class="div-23">****************</div>
                </div>
                <div class="div-24">
                  <div class="div-25">website</div>
                  <div class="div-26">www.yourpunchlist.com</div>
                </div>
                <div class="div-27">
                  <div class="div-28">Business start date</div>
                  <div class="div-29">May 15, 2019</div>
                </div>
              </div>
              <div class="div-30">
                <div class="div-31">
                  <div class="div-32">mailing line address 1</div>
                  <div class="div-33">6240 Oakton Street</div>
                </div>
                <div class="div-34">
                  <div class="div-35">mailing city</div>
                  <div class="div-36">Morton Grove</div>
                </div>
                <div class="div-37">
                  <div class="div-38">Mailing state</div>
                  <div class="div-39">IL</div>
                </div>
              </div>
            </div>
          </div>
          <div class="builder-column column-4">
            <div class="div-40">
              <div class="div-41">
                <div class="div-42">
                  <div class="div-43">phone number</div>
                  <div class="div-44">7739357727</div>
                </div>
                <div class="div-45">
                  <div class="div-46">address line 1</div>
                  <div class="div-47">6240 Oakton Street</div>
                </div>
                <div class="div-48">
                  <div class="div-49">city</div>
                  <div class="div-50">Morton Grove</div>
                </div>
                <div class="div-51">
                  <div class="div-52">state</div>
                  <div class="div-53">IL</div>
                </div>
                <div class="div-54">
                  <div class="div-55">zip</div>
                  <div class="div-56">60053</div>
                </div>
                <div class="div-57">
                  <div class="div-58">country</div>
                  <div class="div-59">US</div>
                </div>
              </div>
              <div class="div-60">
                <div class="div-61">
                  <div class="div-62">mailing zip</div>
                  <div class="div-63">60053</div>
                </div>
                <div class="div-64">
                  <div class="div-65">website</div>
                  <div class="div-66">www.yourpunchlist.com</div>
                </div>
                <div class="div-67">
                  <div class="div-68">mailing country</div>
                  <div class="div-69">US</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-70">
      <div class="div-71">
        <div class="div-72">Ownership and Contacts</div>
        <div class="you-have-an-awesome-business">
          Federal Law requires us to collect some information on the owners, the
          business, and whoever will be signing the Merchants Agreement.
        </div>
      </div>
      <div class="div-73">
        <div class="builder-columns div-74">
          <div class="builder-column column-5">
            <div class="div-75">
              <div class="div-76">
                <div class="div-77">
                  <div class="div-78">owner name</div>
                  <div class="div-79">Jo</div>
                </div>
                <div class="div-80">
                  <div class="div-81">Owner title</div>
                  <div class="div-82">Owner</div>
                </div>
                <div class="div-83">
                  <div class="div-84">Ownership</div>
                  <div class="div-85">50%</div>
                </div>
                <div class="div-86">
                  <div class="div-87">owner ssn</div>
                  <div class="div-88">****************</div>
                </div>
                <div class="div-89">
                  <div class="div-90">date of birth</div>
                  <div class="div-91">March 01, 1995</div>
                </div>
                <div class="div-92">
                  <div class="div-93">phone number</div>
                  <div class="div-94">3057200696</div>
                </div>
                <div class="div-95">
                  <div class="div-96">email address</div>
                  <div class="div-97">jo@payabli.com</div>
                </div>
                <div class="div-98">
                  <div class="div-99">owner address</div>
                  <div class="div-100">9473 Southwest 170th Path</div>
                </div>
                <div class="div-101">
                  <div class="div-102">city</div>
                  <div class="div-103">Miami</div>
                </div>
                <div class="div-104">
                  <div class="div-105">State</div>
                  <div class="div-106">FL</div>
                </div>
                <div class="div-107">
                  <div class="div-108">zip</div>
                  <div class="div-109">33196</div>
                </div>
                <div class="div-110">
                  <div class="div-111">country</div>
                  <div class="div-112">US</div>
                </div>
              </div>
            </div>
          </div>
          <div class="builder-column column-6">
            <div class="div-113">
              <div class="div-114">
                <div class="div-115">
                  <div class="div-116">
                    <div class="div-117">owner name</div>
                    <div class="div-118">William</div>
                  </div>
                  <div class="div-119">
                    <div class="div-120">Owner title</div>
                    <div class="div-121">Owner</div>
                  </div>
                  <div class="div-122">
                    <div class="div-123">Ownership</div>
                    <div class="div-124">50%</div>
                  </div>
                  <div class="div-125">
                    <div class="div-126">owner ssn</div>
                    <div class="div-127">****************</div>
                  </div>
                  <div class="div-128">
                    <div class="div-129">date of birth</div>
                    <div class="div-130">March 23, 2022</div>
                  </div>
                  <div class="div-131">
                    <div class="div-132">phone number</div>
                    <div class="div-133">2139059031</div>
                  </div>
                  <div class="div-134">
                    <div class="div-135">email address</div>
                    <div class="div-136">jo@payabli.com</div>
                  </div>
                  <div class="div-137">
                    <div class="div-138">owner address</div>
                    <div class="div-139">
                      9473 Southwest 170th Path 19415 Collier St.
                    </div>
                  </div>
                  <div class="div-140">
                    <div class="div-141">city</div>
                    <div class="div-142">Miamirzana</div>
                  </div>
                  <div class="div-143">
                    <div class="div-144">State</div>
                    <div class="div-145">FL</div>
                  </div>
                  <div class="div-146">
                    <div class="div-147">zip</div>
                    <div class="div-148">91356</div>
                  </div>
                  <div class="div-149">
                    <div class="div-150">country</div>
                    <div class="div-151">US</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-152">
      <div class="div-153">
        <div class="div-154">Processing Information</div>
        <div class="you-have-an-awesome-business">
          We are so proud to power your payment processing, share with us your
          needs.
        </div>
      </div>
      <div class="div-155">
        <div class="builder-columns div-156">
          <div class="builder-column column-7">
            <div class="div-157">
              <div class="div-158">
                <div class="div-159">
                  <div class="div-160">industry (merchant category code)</div>
                  <div class="div-161">1711 - Heating, Plumbing, A/C</div>
                </div>
                <div class="div-162">
                  <div class="div-163">percent of payments in person</div>
                  <div class="div-164">50</div>
                </div>
                <div class="div-165">
                  <div class="div-166">percent of payments by phone</div>
                  <div class="div-167">20</div>
                </div>
                <div class="div-168">
                  <div class="div-169">percent of payments online</div>
                  <div class="div-170">30</div>
                </div>
                <div class="div-171">
                  <div class="div-172">average monthly volume</div>
                  <div class="div-173">$678,888.00</div>
                </div>
                <div class="div-174">
                  <div class="div-175">average ticket amount</div>
                  <div class="div-176">$788.00</div>
                </div>
              </div>
            </div>
          </div>
          <div class="builder-column column-8">
            <div class="div-177">
              <div class="div-178">
                <div class="div-179">
                  <div class="div-180">
                    <div class="div-181">high ticket amount</div>
                    <div class="div-182">$8,999.00</div>
                  </div>
                  <div class="div-183">
                    <div class="div-184">when is payment charged</div>
                    <div class="div-185">When Service Provided</div>
                  </div>
                  <div class="div-186">
                    <div class="div-187">service generally provided in</div>
                    <div class="div-188">30 Days or Less</div>
                  </div>
                  <div class="div-189">
                    <div class="div-190">Products/services delivered in</div>
                    <div class="div-191">0-7 Days</div>
                  </div>
                  <div class="div-192">
                    <div class="div-193">refund policy</div>
                    <div class="div-194">30 Days or Less</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-195">
      <div class="div-196">
        <div class="div-197">Services and Pricing</div>
        <div class="you-have-an-awesome-business">
          Below you will find the rates associated with your Payment Processing
          Account.
        </div>
      </div>
      <div class="div-198">
        <div class="div">
          <div class="div-199">
            <div class="div-200">Card Acceptance</div>
            <div class="integrated-per-transaction-fee">
              Integrated per transaction fee for card acceptance.
            </div>
          </div>
          <div class="div-201">
            <div class="builder-columns div-202">
              <div class="builder-column column-9">
                <div class="div-203">
                  <div class="builder-columns div-204">
                    <div class="builder-column column-10">
                      <div class="div-205">
                        <div class="builder-columns div-206">
                          <div class="builder-column column-11">
                            <div class="div-207">
                              <div class="div-208">
                                <div class="builder-columns div-209">
                                  <div class="builder-column column-12">
                                    <div class="div-210">
                                      <div class="div-211">
                                        <div class="builder-columns div-212">
                                          <div class="builder-column column-13">
                                            <div class="div-213">card</div>
                                          </div>
                                          <div class="builder-column column-14">
                                            <div class="div-214">
                                              % per Auth
                                            </div>
                                          </div>
                                          <div class="builder-column column-15">
                                            <div class="div-215">
                                              $ per Auth
                                            </div>
                                          </div>
                                          <div class="builder-column column-16">
                                            <div class="div-216">
                                              low pay range
                                            </div>
                                          </div>
                                          <div class="builder-column column-17">
                                            <div class="div-217">
                                              high pay range
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="div-218">
                                        <div class="builder-columns div-219">
                                          <div class="builder-column column-18">
                                            <div class="div-220">
                                              <div class="div-221">
                                                <div class="div-222">
                                                  <picture>
                                                    <source
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?format=webp&width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?format=webp&width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?format=webp&width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?format=webp&width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?format=webp&width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?format=webp&width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?format=webp&width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a
                                                      "
                                                      type="image/webp"
                                                    />
                                                    <img
                                                      loading="lazy"
                                                      src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a"
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a?width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F57db6d603b7a446ca3d9061ba9f43f6a
                                                      "
                                                      class="image-2"
                                                    />
                                                  </picture>
                                                  <div
                                                    class="
                                                      builder-image-sizer
                                                      image-sizer-2
                                                    "
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="builder-column column-19">
                                            <div class="div-223">00</div>
                                          </div>
                                          <div class="builder-column column-20">
                                            <div class="div-224">00</div>
                                          </div>
                                          <div class="builder-column column-21">
                                            <div class="div-225">00</div>
                                          </div>
                                          <div class="builder-column column-22">
                                            <div class="div-226">00</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="div-227">
                                        <div class="builder-columns div-228">
                                          <div class="builder-column column-23">
                                            <div class="div-229">
                                              <div class="div-230">
                                                <div class="div-231">
                                                  <picture>
                                                    <source
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?format=webp&width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?format=webp&width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?format=webp&width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?format=webp&width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?format=webp&width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?format=webp&width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?format=webp&width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e
                                                      "
                                                      type="image/webp"
                                                    />
                                                    <img
                                                      loading="lazy"
                                                      src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e"
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e?width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8052cf8d3178426791541148cd1a881e
                                                      "
                                                      class="image"
                                                    />
                                                  </picture>
                                                  <div
                                                    class="
                                                      builder-image-sizer
                                                      image-sizer-3
                                                    "
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="builder-column column-24">
                                            <div class="div-232">00</div>
                                          </div>
                                          <div class="builder-column column-25">
                                            <div class="div-233">00</div>
                                          </div>
                                          <div class="builder-column column-26">
                                            <div class="div-234">00</div>
                                          </div>
                                          <div class="builder-column column-27">
                                            <div class="div-235">00</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="div-236">
                                        <div class="builder-columns div-237">
                                          <div class="builder-column column-28">
                                            <div class="div-238">
                                              <div class="div-239">
                                                <div class="div-240">
                                                  <picture>
                                                    <source
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?format=webp&width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?format=webp&width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?format=webp&width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?format=webp&width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?format=webp&width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?format=webp&width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?format=webp&width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3
                                                      "
                                                      type="image/webp"
                                                    />
                                                    <img
                                                      loading="lazy"
                                                      src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3"
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3?width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F8a45c3a023d9447a9cfa47f1ea2237a3
                                                      "
                                                      class="image-3"
                                                    />
                                                  </picture>
                                                  <div
                                                    class="
                                                      builder-image-sizer
                                                      image-sizer-4
                                                    "
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="builder-column column-29">
                                            <div class="div-241">00</div>
                                          </div>
                                          <div class="builder-column column-30">
                                            <div class="div-242">00</div>
                                          </div>
                                          <div class="builder-column column-31">
                                            <div class="div-243">00</div>
                                          </div>
                                          <div class="builder-column column-32">
                                            <div class="div-244">00</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="div-245">
                                        <div class="builder-columns div-246">
                                          <div class="builder-column column-33">
                                            <div class="div-247">
                                              <div class="div-248">
                                                <div class="div-249">
                                                  <picture>
                                                    <source
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?format=webp&width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?format=webp&width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?format=webp&width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?format=webp&width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?format=webp&width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?format=webp&width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?format=webp&width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51
                                                      "
                                                      type="image/webp"
                                                    />
                                                    <img
                                                      loading="lazy"
                                                      src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51"
                                                      srcset="
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?width=100   100w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?width=200   200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?width=400   400w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?width=800   800w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?width=1200 1200w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?width=1600 1600w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51?width=2000 2000w,
                                                        https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F81a2fd6d175244b28b8f0af400f83b51
                                                      "
                                                      class="image-4"
                                                    />
                                                  </picture>
                                                  <div
                                                    class="
                                                      builder-image-sizer
                                                      image-sizer-5
                                                    "
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="builder-column column-34">
                                            <div class="div-250">00</div>
                                          </div>
                                          <div class="builder-column column-35">
                                            <div class="div-251">00</div>
                                          </div>
                                          <div class="builder-column column-36">
                                            <div class="div-252">00</div>
                                          </div>
                                          <div class="builder-column column-37">
                                            <div class="div-253">00</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="builder-column column-38">
                <div class="div-254">
                  <div class="div-255">
                    <div class="div-256">
                      <div class="div-257">FEE</div>
                      <div class="div-258">AMOUNT</div>
                    </div>
                  </div>
                  <div class="div-259">
                    <div class="div-260">
                      <div class="div-261">per Chargeback</div>
                      <div class="div-262">15.00</div>
                    </div>
                  </div>
                  <div class="div-263">
                    <div class="div-264">
                      <div class="div-265">Minimum Processing</div>
                      <div class="div-266">25.00</div>
                    </div>
                  </div>
                  <div class="div-267">
                    <div class="div-268">
                      <div class="div-269">per DDA Reject</div>
                      <div class="div-270">25.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="div-271">
          <div class="div-272">
            <div class="div-273">Bank Transfers</div>
            <div class="integrated-per-transaction-fee-2">
              Integrated per transaction fee for ACH debits and credits.
            </div>
          </div>
          <div class="div-274">
            <div class="builder-columns div-275">
              <div class="builder-column column-39">
                <div class="div-276">
                  <div class="div-277">
                    <div class="builder-columns div-278">
                      <div class="builder-column column-40">
                        <div class="div-279">ACH</div>
                      </div>
                      <div class="builder-column column-41">
                        <div class="div-280">% per Auth</div>
                      </div>
                      <div class="builder-column column-42">
                        <div class="div-281">$ per Auth</div>
                      </div>
                      <div class="builder-column column-43">
                        <div class="div-282">low pay range</div>
                      </div>
                      <div class="builder-column column-44">
                        <div class="div-283">high pay range</div>
                      </div>
                    </div>
                  </div>
                  <div class="div-284">
                    <div class="builder-columns div-285">
                      <div class="builder-column column-45">
                        <div class="div-286">
                          <div class="div-287">
                            <div class="div-288">
                              <picture>
                                <source
                                  srcset="
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?format=webp&width=100   100w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?format=webp&width=200   200w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?format=webp&width=400   400w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?format=webp&width=800   800w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?format=webp&width=1200 1200w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?format=webp&width=1600 1600w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?format=webp&width=2000 2000w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753
                                  "
                                  type="image/webp"
                                />
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753"
                                  srcset="
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?width=100   100w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?width=200   200w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?width=400   400w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?width=800   800w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?width=1200 1200w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?width=1600 1600w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753?width=2000 2000w,
                                    https://cdn.builder.io/api/v1/image/assets%2FTEMP%2F0dc23653f29243f7905ad1dd417ec753
                                  "
                                  class="image"
                                />
                              </picture>
                              <div
                                class="builder-image-sizer image-sizer-6"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="builder-column column-46">
                        <div class="div-289">00</div>
                      </div>
                      <div class="builder-column column-47">
                        <div class="div-290">0.25</div>
                      </div>
                      <div class="builder-column column-48">
                        <div class="div-291">00</div>
                      </div>
                      <div class="builder-column column-49">
                        <div class="div-292">00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="builder-column column-50">
                <div class="div-293">
                  <div class="div-294">
                    <div class="div-295">
                      <div class="div-296">FEE</div>
                      <div class="div-297">AMOUNT</div>
                    </div>
                  </div>
                  <div class="div-298">
                    <div class="div-299">
                      <div class="div-300">per Returned</div>
                      <div class="div-301">10.00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-302">
      <div class="div-303">
        <div class="div-304">Banking Documents</div>
        <div class="you-have-an-awesome-business">
          We are almost done! Please provide us with some important supporting
          documents and where you want us to send your funds.
        </div>
      </div>
      <div class="div-305">
        <div class="div">
          <div class="div-306">
            <div class="builder-columns div-307">
              <div class="builder-column column-51">
                <div class="div-308">
                  <div class="div-309">
                    <div class="div-310">
                      <div class="div-311">deposit account</div>
                      <div class="div-312"><div _text=""></div></div>
                    </div>
                    <div class="div-313">
                      <div class="div-314">bank name</div>
                      <div class="div-315">Chase</div>
                    </div>
                    <div class="div-316">
                      <div class="div-317">routing number</div>
                      <div class="div-318">888888888</div>
                    </div>
                    <div class="div-319">
                      <div class="div-320">account number</div>
                      <div class="div-321">****************</div>
                    </div>
                    <div class="div-322">
                      <div class="div-323">account type</div>
                      <div class="div-324">Checking</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="builder-column column-52">
                <div class="div-325">
                  <div class="div-326">
                    <div class="div-327">
                      <div class="div-328">
                        <div class="div-329">withdrawal account</div>
                        <div class="div-330"><div _text=""></div></div>
                      </div>
                      <div class="div-331">
                        <div class="div-332">bank name</div>
                        <div class="div-333">Chase</div>
                      </div>
                      <div class="div-334">
                        <div class="div-335">routing number</div>
                        <div class="div-336">888888888</div>
                      </div>
                      <div class="div-337">
                        <div class="div-338">account number</div>
                        <div class="div-339">****************</div>
                      </div>
                      <div class="div-340">
                        <div class="div-341">account type</div>
                        <div class="div-342">Checking</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="div-343">
            <div class="div-344">documents stored in</div>
            <div class="div-345">ba_388.zip</div>
          </div>
        </div>
        <div class="div-346">
          <div class="div">
            <div class="div-347">
              <div class="div-348">Signer</div>
              <div class="div-349">Signer Information</div>
            </div>
          </div>
          <div class="div-350">
            <div class="div-351">
              <div class="builder-columns div-352">
                <div class="builder-column column-53">
                  <div class="div-353">
                    <div class="div-354">
                      <div class="div-355">
                        <div class="div-356">name</div>
                        <div class="div-357">William</div>
                      </div>
                      <div class="div-358">
                        <div class="div-359">Date of birth</div>
                        <div class="div-360">June 30, 2003</div>
                      </div>
                      <div class="div-361">
                        <div class="div-362">social security number</div>
                        <div class="div-363">****************</div>
                      </div>
                      <div class="div-364">
                        <div class="div-365">email</div>
                        <div class="div-366">jo@payabli.com</div>
                      </div>
                      <div class="div-367">
                        <div class="div-368">phone</div>
                        <div class="div-369">2139059031</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="builder-column column-54">
                  <div class="div-370">
                    <div class="div-371">
                      <div class="div-372">
                        <div class="div-373">
                          <div class="div-374">address</div>
                          <div class="div-375">
                            9473 Southwest 170th Path 19415 Collier St.
                          </div>
                        </div>
                        <div class="div-376">
                          <div class="div-377">zip</div>
                          <div class="div-378">91356</div>
                        </div>
                        <div class="div-379">
                          <div class="div-380">city</div>
                          <div class="div-381">Miamirzana</div>
                        </div>
                        <div class="div-382">
                          <div class="div-383">state</div>
                          <div class="div-384">Fl</div>
                        </div>
                        <div class="div-385">
                          <div class="div-386">Country</div>
                          <div class="div-387">US</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-388">
      <div class="div-389">Disclosures</div>
      <div class="div-390">
        <div class="div">
          <div class="div-391">
            <div class="div-392">Member Bank Info</div>
            <div class="merrick-bank-135-crossways-pa">
              Merrick Bank, 135 Crossways Park North, Woodbury, NY 11797 Phone
              (800) 328-9155
            </div>
          </div>
        </div>
        <div class="div-393">
          <div class="div-394">
            <div class="builder-columns div-395">
              <div class="builder-column column-55">
                <div class="div-396">
                  <div class="merrick-bank-is-the-only-entit">
                    Merrick Bank is the only entity approved to extend
                    acceptance of Visa and Mastercard products directly to a
                    Merchant. Merrick Bank is responsible for educating
                    merchants on pertinent Visa and Mastercard Network Rules
                    with which Merchants must comply. Merrick Bank must hold,
                    administer, and control all reserve funds derived from
                    settlement. Merrick Bank must hold, administer, and control
                    settlement funds for Merchant. Merrick Bank must be a party
                    to the Merchant Processing Agreement. Important Merchant
                    Responsibilities Comply with cardholder data security and
                    storage requirements. Maintain fraud and chargebacks below
                    established threshold. Review and understand the terms of
                    the Merchant Processing Agreement. Comply with the Network
                    Rules. Merchant Information: Refer to this Merchant
                    Application.
                  </div>
                </div>
              </div>
              <div class="builder-column column-56">
                <div class="div-397">
                  <div class="the-responsibilities-listed-ab">
                    The responsibilities listed above do not supersede terms of
                    the Merchant Processing Agreement and are provided to ensure
                    Merchant understands some important obligations of each
                    party that Merrick Bank, as the Member Bank, is the ultimate
                    authority should Merchant have any problems. Debit Network
                    Interchange, sponsorship, switch and gateway fees, and any
                    miscellaneous fees will be assessed or allocated to Merchant
                    at the then current rate. Interchange Merchants Only – Card
                    Brand Interchange fees, assessments, and other fees will be
                    assessed to the Merchant at the then current rate The
                    initial term of the Merchant Processing Agreement is 3 years
                    and automatically renews for additional 1-year periods.
                    Merchant may be charged a PCI Non-Compliance fee of $19.95
                    per month per MID. Please refer to Section 2.15 of the Terms
                    and Conditions.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="patriot-act-and-background-inf">
            Patriot Act and Background Information To help the government fight
            the funding of terrorism and money laundering activities, the USA
            Patriot Act requires all financial institutions to obtain, verify
            and record information that identifies each person (including
            business entities) who opens an account. What this means for you:
            When you open an account, we will ask for your name, physical
            address, date of birth, taxpayer identification number and other
            information that will allow us to identify you. We may also ask to
            see your driver’s license or other identifying documents. The
            undersigned entity(ies) and individuals hereby unconditionally
            authorize ISO and Member Bank or its agents to (i) investigate the
            information and references contained herein, and to obtain
            additional information about the Merchant and such individual(s) by
            pulling credit bureau and criminal background checks on the Merchant
            and its principals, including obtaining reports from consumer
            reporting agencies on individuals signing below as an owner or
            general partner of Merchant, or providing their Social Security
            Number on the Merchant Application (if such individual asks ISO or
            Member Bank whether or not a consumer report was requested, ISO
            and/or Member Bank will tell such individual and, if ISO and/or
            Member Bank received a report, ISO and/or Member Bank will give the
            individual the name and address of the agency that furnished it) and
            (ii) update such information periodically throughout the terms of
            service of the Merchant Processing Agreement. By providing your SSN
            and signing the Merchant Application, you, in your individual
            capacity, unconditionally authorize ISO and Member Bank to obtain
            your consumer credit report.
          </div>
          <div class="merchant-acknowledgements-and">
            Merchant Acknowledgements and Signature Merchant agrees to and
            accepts the terms and conditions set forth in this Merchant
            Application and the Terms and Conditions made available via a
            separate link which are incorporated herein by reference as if fully
            set forth herein (collectively, the “Merchant Processing Agreement”
            or “Agreement”) and acknowledges receipt of all parts of the
            Agreement. If signed electronically, Merchant agrees that such
            electronic signatures on the Merchant Application or other related
            agreements will have the same legal effect as if signed in ink.
            Merchant also agrees that an electronic or duplicate copy of such
            signatures may be used as evidence of execution of such agreements.
            Merchant represents, warrants and certifies to ISO and Member Bank
            that it has reviewed all pages of this Merchant Application, that
            all information provided herein is true, correct and complete and
            that ISO and Member Bank may rely on the information contained in
            this Merchant Application, without further investigation, for all
            purposes. Merchant acknowledges and agrees that ISO and Member Bank
            are in no way responsible or liable for the actions, inactions,
            performance or lack of performance of any third party provider or
            independent sales representative. Merchant represents that it has
            chosen for itself any services, equipment or third party selected in
            connection with the Merchant Application, and it has not relied on
            any promises, representations, warranties, or covenants of the sales
            representative, ISO or others. Merchant acknowledges and agrees that
            the Agreement shall not be altered by any prior, contemporaneous or
            subsequent oral representations made by any party. Merchant further
            authorizes the release of Merchant information in accordance with
            the provisions of Section 5.23 of the Terms and Conditions. If
            Merchant does not want to participate in the American Express
            Program, the applicable Opt Out Box has been marked. Merchant
            certifies that all of the information furnished with regard to
            information for each individual, if any, who directly or indirectly,
            through any contract, arrangement, understanding, relationship, or
            otherwise, owns 25 percent or more of the equity interests of the
            legal entity listed above is complete and accurate. IN WITNESS
            WHEREOF Merchant has caused this Agreement to be executed by its
            duly authorized representative effective in accordance with the
            terms of the Terms and Conditions. The Merchant Processing Agreement
            shall be binding upon Merchant upon the earlier of Merchant’s
            execution below or Merchant’s first processed electronic
            transaction.
          </div>
          <div class="div-398">
            <div class="div-399">
              <div class="builder-columns div-400">
                <div class="builder-column column-57">
                  <div class="input-field-signature-name-dat">
                    <div class="div-401">merchant signature</div>
                    <div class="div-402"><div _text=""></div></div>
                  </div>
                </div>
                <div class="builder-column column-58">
                  <div class="input-field-signature-name-dat">
                    <div class="div-403">name</div>
                    <div class="div-404"><div _text=""></div></div>
                  </div>
                </div>
                <div class="builder-column column-59">
                  <div class="input-field-signature-name-dat">
                    <div class="div-405">date</div>
                    <div class="div-406"><div _text=""></div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-407">
              <div class="builder-columns div-408">
                <div class="builder-column column-60">
                  <div class="input-field-signature-name-dat">
                    <div class="div-409">member bank signature</div>
                    <div class="div-410"><div _text=""></div></div>
                  </div>
                </div>
                <div class="builder-column column-61">
                  <div class="input-field-signature-name-dat">
                    <div class="div-411">name/title</div>
                    <div class="div-412"><div _text=""></div></div>
                  </div>
                </div>
                <div class="builder-column column-62">
                  <div class="input-field-signature-name-dat">
                    <div class="div-413">date</div>
                    <div class="div-414"><div _text=""></div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="div-415">
              <div class="builder-columns div-416">
                <div class="builder-column column-63">
                  <div class="input-field-signature-name-dat">
                    <div class="div-417">iso signature</div>
                    <div class="div-418"><div _text=""></div></div>
                  </div>
                </div>
                <div class="builder-column column-64">
                  <div class="input-field-signature-name-dat">
                    <div class="div-419">name/title</div>
                    <div class="div-420">John Lewis/ Member Manager</div>
                  </div>
                </div>
                <div class="builder-column column-65">
                  <div class="input-field-signature-name-dat">
                    <div class="div-421">date</div>
                    <div class="div-422"><div _text=""></div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="div-423">
      <div class="div-424">
        <div class="div-425">Site Inspection</div>
        <div class="you-have-an-awesome-business">
          (to be completed by the Sales Advisor/Representative)
        </div>
      </div>
      <div class="i-represented-and-warrant-that">
        I represented and warrant that the information set forth in the Merchant
        Application is true and accurate to the best of my knowledge. In
        addition, I hereby certify that (check all the applies): I have not
        physically inspected the bussines premises of the Merchant but have
        verified the validity of the bussiness using sources and confirmed the
        identity of the person listed under the Control Owner/Officer
        Information Section. Merchant´s Physical inventory is consistent with
        the bussiness signage.
      </div>
      <div class="div-426">
        <div class="builder-columns div-427">
          <div class="builder-column column-66">
            <div class="input-field-signature-sales-ad">
              <div class="sales-advisor-representative-s">
                sales advisor/representative signature
              </div>
              <div class="div-428"><div _text=""></div></div>
            </div>
          </div>
          <div class="builder-column column-67">
            <div class="input-field-signature-sales-ad">
              <div class="sales-advisor-representative-s">date</div>
              <div class="div-429"><div _text=""></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<body>

`;
