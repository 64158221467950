import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IMaskInput } from 'react-imask';
import { Popup } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

interface PhoneInputProps {
  iValue?: string;
  iComponent: string;
  iStoreName: string;
  iMetadata?: any;
  iRequired?: boolean;
  iReadonly?: boolean;
  iTitle: string;
  iName: string;
  iType?: string;
  iToolTip?: string;
  boarding?: any;
  iMask?: string;
  identifyBy?: string;
  iValidator?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  iValue = '',
  iComponent,
  iStoreName,
  iMetadata,
  iRequired = false,
  iReadonly = false,
  iTitle,
  iName,
  iType = 'text',
  iToolTip,
  boarding,
}) => {
  const [value, setValue] = useState<string>(iValue);
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [isNewScreen, setIsNewScreen] = useState<boolean>(true);
  const [iconLoading, setIconLoading] = useState<boolean>(false);
  const isUserInput = useRef(false);

  useEffect(() => {
    if (!isUserInput.current) {
      const validationError =
        iRequired &&
        (validators.isEmpty(iValue) ||
          (iValue.length > 0 && iValue.length < 14));
      const newValue = iValue || '';
      setValue(newValue);
      setError(validationError);
      setErrorText('');
      boarding[iComponent].setProp(iStoreName, newValue, iMetadata);
      boarding[iComponent].setError(iStoreName, validationError, iMetadata);

      if (iValue) {
        setIconLoading(false);
        setErrorText('');
        setError(false);
        boarding[iComponent].setProp(iStoreName, newValue, iMetadata);
        boarding[iComponent].setError(iStoreName, validationError, iMetadata);
      }
    }
    isUserInput.current = false;
  }, [iValue]);

  const handleBlur = (): void => {
    setIconLoading(false);
    setErrorText('');
    setError(false);
    boarding[iComponent].setError(iStoreName, false, iMetadata);
  };

  useEffect(() => {
    if (value?.length === 14) {
      handleBlur();
    }
  }, [value]);

  const handleChange = (name: string, event: any, valueChange: any): void => {
    setIsNewScreen(false);
    const newValue = valueChange?.value || null;
    const validationError =
      iRequired &&
      (validators.isEmpty(valueChange.value) ||
        (valueChange.value.length > 0 && valueChange.value.length < 14));
    if (newValue !== value) {
      setValue(newValue);
    }
    setError(validationError);
    setErrorText('');

    const field = document.getElementsByClassName(iName)[0];
    if (valueChange.value) {
      field.classList.add('field--not-empty');
    } else {
      field.classList.remove('field--not-empty');
    }
    boarding[iComponent].setProp(name, valueChange.value, iMetadata);
    boarding[iComponent].setError(name, validationError, iMetadata);
  };

  const builderInput = (): any => {
    const validationError =
      boarding[iComponent].getError(iStoreName, iMetadata) === true;
    const { nextClicked } = boarding;
    return (
      <div
        className={
          (validationError && !isNewScreen) || (error && nextClicked)
            ? 'ui fluid input error loading'
            : 'ui fluid input loading'
        }
      >
        {iToolTip && (
          <Popup
            trigger={
              <BiInfoCircle
                color={errorText !== '' ? 'red' : 'black'}
                className="info-icon masked-in-input"
              />
            }
          >
            {errorText !== '' ? errorText : iToolTip}
          </Popup>
        )}
        <IMaskInput
          mask={'(000) 000-0000'}
          name={iName}
          value={value ? String(value) : ''}
          autoComplete="off"
          defaultValue=""
          type={iType}
          unmask={true}
          onAccept={(event: any, valueAccept: any): void =>
            handleChange(iStoreName, event, valueAccept)
          }
          placeholder={iTitle + (iRequired === true ? ' *' : '')}
          className="field__input"
          readOnly={iReadonly}
          id={iName}
        />
        {iconLoading && (
          <div className="icon-container">
            <i className="loader"></i>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={`field ${iName}`}>
        <label className="field__label" htmlFor={iName}>
          {iTitle} {iRequired === true ? ' *' : ''}
        </label>
        {builderInput()}
      </div>
    </>
  );
};

export default inject('boarding')(observer(PhoneInput));
