import { inject, observer } from 'mobx-react';
import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  Popup,
  Transition,
} from 'semantic-ui-react';

import { STATUS } from '../interfaces/IAttachment';
import { renderInput } from '../utils/input-tools';
import { getOptionsType } from '../utils/optionsTypes';
import CopyInfoInput from './inputs/CopyInfoInput';
import FileUploadInput from './inputs/FileUploadInput';

class Banking extends React.Component<any, any> {
  bankingData: any;

  bankingIndex: number = 0;

  minimumDocuments: number = 1;

  uploadDocuments: boolean = true;

  depositVisible: boolean = true;

  withdrawalVisible: boolean = true;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    this.bankingData = props.bankingData;
    this.minimumDocuments = props.minimumDocuments;
    this.uploadDocuments = props.uploadDocuments;
    this.depositVisible = props.depositVisible;
    this.withdrawalVisible = props.withdrawalVisible;
    this.onCopyBankInfo = this.onCopyBankInfo.bind(this);
  }

  onCopyBankInfo(): void {
    this.props.boarding.banking.copyBankInfo();
  }

  componentDidMount(): void {
    this.setState({
      visible: true,
    });
  }

  errorAttachement(): boolean {
    let callError = false;
    this.props.boarding.setErrorAttachment(false);
    if (
      this.minimumDocuments >
      this.props.boarding.attachments.elementsList.length
    ) {
      callError = true;
    }
    if (!callError) {
      this.props.boarding.attachments.elementsList.forEach((element: any) => {
        if (element.filename === '' && element.fsize === 0) {
          callError = true;
          this.props.boarding.setErrorAttachment(true);
        }
      });
    }
    return callError;
  }

  render(): React.ReactNode {
    const depositAccount = this.bankingData?.depositAccount;
    const withdrawalAccount = this.bankingData?.withdrawalAccount;
    const { nextClicked } = this.props.boarding;

    return (
      <div key="banking-div-tab">
        <Transition
          key="banking-trans-row"
          duration={400}
          visible={this.state?.visible}
        >
          <Container key="banking-container-row">
            <Grid doubling key="banking-grid-grid" columns={2}>
              <Grid.Row key="banking-grdi-row" columns={2}>
                {
                  <Grid.Column
                    key="banking-grdi-column-deposit"
                    style={{
                      display:
                        (depositAccount === null ||
                          depositAccount === undefined ||
                          !this.depositVisible) &&
                        'none',
                    }}
                  >
                    <h3 className="mb-4" key="banking-grdi-h3">
                      Deposit Account
                    </h3>
                    {Object.entries(depositAccount || []).map(
                      (deposit: any, index: number): any => {
                        const inputName = deposit[0];
                        const bankingItem = depositAccount[inputName];
                        const depositStore =
                          this.props.boarding.banking?.accountsInfo
                            ?.depositAccount;
                        const storeValue =
                          depositStore && depositStore[inputName]
                            ? depositStore[inputName]
                            : '';
                        if (inputName === undefined || inputName === '')
                          return <></>;
                        if (bankingItem === undefined || bankingItem === '')
                          return <></>;
                        const fieldMetadata = {
                          name: `deposit${inputName}`,
                          storeName: inputName,
                          key: index,
                          label: bankingItem?.label,
                          required: bankingItem?.required,
                          toolTip: bankingItem?.toolTip,
                          value: bankingItem?.value || storeValue,
                          boarding: this.props.boarding,
                          metadata: {
                            index: 'deposit',
                            typeBank: 'depositAccount',
                            options: getOptionsType(bankingItem?.stype),
                          },
                          component: 'banking',
                        };
                        return deposit[1]?.visible ? (
                          <Grid.Row
                            key={`banking-grdi-row-field-deposit${index}`}
                            style={{
                              marginBottom: '1em',
                            }}
                          >
                            {renderInput(bankingItem.type, fieldMetadata)}
                          </Grid.Row>
                        ) : (
                          this.props.boarding[fieldMetadata.component].setProp(
                            inputName,
                            fieldMetadata.value,
                            fieldMetadata.metadata
                          )
                        );
                      }
                    )}
                  </Grid.Column>
                }
                {
                  <Grid.Column
                    key="banking-grdi-column-Withdrawal"
                    floated={'right'}
                    width={8}
                    style={{
                      display:
                        (withdrawalAccount === null ||
                          withdrawalAccount === undefined ||
                          !this.withdrawalVisible) &&
                        'none',
                    }}
                  >
                    <h3 className="mb-4" key="banking-grdi-h3-with">
                      Withdrawal Account
                    </h3>
                    {Object.entries(withdrawalAccount || []).map(
                      (withdraw: any, index: number): any => {
                        const inputName = withdraw[0];
                        const bankingItem = withdrawalAccount[inputName];
                        const withdrawStore =
                          this.props.boarding.banking?.accountsInfo
                            ?.withdrawalAccount;
                        const storeValue =
                          withdrawStore && withdrawStore[inputName]
                            ? withdrawStore[inputName]
                            : '';
                        if (inputName === undefined || inputName === '')
                          return <></>;
                        if (bankingItem === undefined || bankingItem === '')
                          return <></>;
                        const fieldMetadata = {
                          name: `withdrawal${inputName}`,
                          storeName: inputName,
                          key: index,
                          label: bankingItem?.label,
                          toolTip: bankingItem?.toolTip,
                          required: bankingItem?.required,
                          value: bankingItem?.value || storeValue,
                          boarding: this.props.boarding,
                          metadata: {
                            index: 'withdrawal',
                            typeBank: 'withdrawalAccount',
                            options: getOptionsType(bankingItem?.stype),
                          },
                          component: 'banking',
                        };
                        if (inputName === 'typeAccount') {
                          return (
                            <Grid.Row
                              key={`banking-grdi-row-field-withdraw${index}`}
                              style={{
                                marginBottom: '1em',
                              }}
                            >
                              {withdraw[1]?.visible
                                ? renderInput(bankingItem.type, fieldMetadata)
                                : inputName !== 'visible' &&
                                  this.props.boarding[
                                    fieldMetadata.component
                                  ].setProp(
                                    inputName,
                                    fieldMetadata.value,
                                    fieldMetadata.metadata
                                  )}
                              <CopyInfoInput
                                key={`banking-grdi-copy${index}`}
                                copyText="Copy deposit account details"
                                copyHandlerClick={this.onCopyBankInfo}
                              />
                            </Grid.Row>
                          );
                        }
                        return withdraw[1]?.visible ? (
                          <Grid.Row
                            key={`banking-grdi-row-field-withdrawal${index}`}
                            style={{
                              marginBottom: '1em',
                            }}
                          >
                            {renderInput(bankingItem.type, fieldMetadata)}
                          </Grid.Row>
                        ) : (
                          this.props.boarding[fieldMetadata.component].setProp(
                            inputName,
                            fieldMetadata.value,
                            fieldMetadata.metadata
                          )
                        );
                      }
                    )}
                  </Grid.Column>
                }
              </Grid.Row>
            </Grid>
            {(this.uploadDocuments || this.props.zipFile) && (
              <Divider key="banking-divider-row" />
            )}
            <div style={{ marginTop: '2rem' }} key="banking-attestation">
              <div
                style={{ width: '40%', marginBottom: '1rem' }}
                key="banking-attestation-1"
              >
                {this.props.zipFile && (
                  <b>{`Documents stored in: ${this.props.zipFile}`}</b>
                )}
              </div>
              {this.uploadDocuments && (
                <>
                  <div
                    style={
                      nextClicked && this.errorAttachement()
                        ? {
                            border: '2px solid #e52727',
                            borderRadius: '5px',
                            padding: '10px',
                          }
                        : {}
                    }
                  >
                    <span className="small" key="banking-attestation-2">
                      <Popup
                        trigger={
                          <BiInfoCircle
                            style={{
                              position: 'relative',
                              top: '0.2em',
                            }}
                            className="info-icon"
                          />
                        }
                      >
                        To help our Underwriting Team get your merchant account
                        ready the more information the better. Some recommended
                        docs are: Current merchant services statements, Drivers
                        License, Business license, Voided Check
                      </Popup>
                      Please upload supporting documents below; at least{' '}
                      {this.minimumDocuments}{' '}
                      {this.minimumDocuments > 1
                        ? 'files are expected'
                        : 'file is expected'}
                      . Upload max is 29MB of any combination of .pdf .jpg or
                      .png files.
                    </span>
                  </div>
                  {this.props.boarding.attachments.elementsList
                    .filter((element: any) => {
                      const { fileDescriptor } = element;
                      return (
                        JSON.parse(
                          fileDescriptor === '' ? '{}' : fileDescriptor
                        )?.type !== 'signature' &&
                        element.status !== STATUS.deleted &&
                        element.status !== STATUS.toDelete
                      );
                    })
                    .map((attachment: any, attachmentIndex: number) => {
                      return (
                        <div key={`banking-attachments-3${attachmentIndex}`}>
                          <Divider
                            key={`banking-attachments-3-1${attachmentIndex}`}
                          />
                          <div
                            key={`banking-attachments-3-2${attachmentIndex}`}
                            className="owner-header-div"
                          >
                            <div className="owner-header-info">
                              <h6> Attachment Information</h6>
                              <p>Attachment #{attachmentIndex + 1}</p>
                            </div>
                            {attachmentIndex === 0 && (
                              <div className="owner-header-info">
                                <strong>
                                  Required Documents: “Voided Check or Bank
                                  Letter and Owner ID”
                                </strong>
                              </div>
                            )}
                            {attachmentIndex > 0 && (
                              <div className="owner-header-icon">
                                <Button
                                  color="red"
                                  size="tiny"
                                  icon
                                  labelPosition="right"
                                  onClick={(): void => {
                                    this.props.boarding.attachments.removeAttachment(
                                      attachment.id
                                    );
                                  }}
                                >
                                  Delete Attachment
                                  <Icon name="trash" />
                                </Button>
                              </div>
                            )}
                            <br />
                          </div>
                          <FileUploadInput
                            key={`att${attachment.id}`}
                            attachmentInfo={attachment}
                            attachmentIndex={attachmentIndex}
                            iMetadata={{
                              index: attachment.id || attachmentIndex,
                            }}
                          />
                          <br />
                        </div>
                      );
                    })}
                  <br />
                  <button
                    key="addDocument"
                    style={{ width: '100%' }}
                    className="btn bordered no-margin"
                    onClick={(): void => {
                      this.props.boarding.attachments.addAttachment();
                    }}
                  >
                    + Add more documents
                  </button>
                </>
              )}
            </div>
          </Container>
        </Transition>
      </div>
    );
  }
}

export default inject('boarding')(observer(Banking));
