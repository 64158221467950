// import utf8 from 'utf8'
import * as Sentry from '@sentry/react';

class ToolsService {
  public async validatePhone(
    token: string = '',
    values: Object = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const myHeaders = new Headers();
      myHeaders.append('requestToken', token);
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify(`+1${values}`);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
      };
      let result = {};
      fetch(
        `${process.env.REACT_APP_URL_API}Tools/validatePhone`,
        requestOptions
      )
        .then((response) => response.text())
        .then((res) => {
          result = JSON.parse(res);
          resolve(JSON.parse(res));
        })
        .catch((error) => {
          result = {
            status: 400,
            data: error.message,
          };
          Sentry.captureEvent(error);
          reject(error);
        });
      // eslint-disable-next-line no-promise-executor-return
      return result;
    });
  }
}
export default ToolsService;
