import { inject, observer } from 'mobx-react';
import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
// import { BsFillCheckCircleFill } from 'react-icons/bs';
import { IMaskInput } from 'react-imask';
import { Popup } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

class AccountNumberInput extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: '',
      name: '',
      component: '',
      error: false,
      errorText: '',
      isSuccess: false,
      isNewScreen: true,
      mouseIn: false,
      iconLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.builderInput = this.builderInput.bind(this);
    this.focus = this.focus.bind(this);
    this.mouseIn = this.mouseIn.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }

  mouseIn(): void {
    this.setState({
      mouseIn: true,
    });
  }

  mouseOut(): void {
    this.setState({
      mouseIn: false,
    });
  }

  focus(e: any): void {
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  componentDidMount(): void {
    const error = this.props.iRequired && validators.isEmpty(this.props.iValue);
    this.setState(
      {
        component: this.props.iComponent,
        value: this.props.iValue,
        name: this.props.iStoreName,
        errorText: '',
        isSuccess: false,
      },
      (): void => {
        this.props.boarding[this.props.iComponent].setProp(
          this.state.name,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          this.state.name,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
    if (this.props.iValue) {
      this.setState({
        iconLoading: true,
        errorText: '',
        isSuccess: false,
      });

      const { routingAccount } =
        this.props.boarding[this.props.iComponent].accountsInfo[
          this.props.iMetadata.typeBank
        ];

        if(!routingAccount){
          this.setState({iconLoading: false, error: false, errorText: '', isSuccess: false});
          return;
        }
      validators
        .validateRouting(routingAccount, this.props.iValue)
        .then((result: any) => {
          const response = JSON.parse(result);
          this.setState(
            {
              error:
                error ||
                response?.responseData?.verificationResponse === 'Declined' ||
                response?.responseData?.verificationResponse === null,
              errorText:
                response?.responseData?.verificationResponse === 'Declined'
                  ? response?.responseData?.accountVerificationResult
                      ?.responseDescription
                  : response?.responseData?.verificationResponse === null &&
                    response?.responseData?.errorMessages.length > 0
                  ? response?.responseData?.errorMessages[0]
                  : '',
              isSuccess:
                response?.responseData?.verificationResponse !== 'Declined' &&
                response?.responseData?.verificationResponse !== null,
              iconLoading: false,
            },
            (): void => {
              if (
                response?.responseData?.accountVerificationResult?.bankName !==
                null
              ) {
                this.props.boarding[this.props.iComponent].setProp(
                  'bankName',
                  response?.responseData?.accountVerificationResult?.bankName,
                  this.props.iMetadata
                );
                this.props.boarding[this.props.iComponent].setError(
                  'bankName',
                  false,
                  this.props.iMetadata
                );
              }
              this.props.boarding[this.props.iComponent].setProp(
                this.state.name,
                this.state.value,
                this.props.iMetadata
              );
              this.props.boarding[this.props.iComponent].setError(
                this.state.name,
                this.state.error,
                this.props.iMetadata
              );
            }
          );
        });
    }
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    if (prevProps.iValue !== this.props.iValue) {
      this.setState({ value: this.props.iValue }, () => {
        const { routingAccount } =
        this.props.boarding[this.props.iComponent].accountsInfo[
          this.props.iMetadata.typeBank
        ];
        if (this.props.iValue.length >= 4 && routingAccount) this.handleBlur();
      });
    }
  }

  handleChange(name: string, event: any, value: any): void {
    const error =
      validators.isEmpty(value.value) ||
      (value.value.length < 4);
    this.setState(
      {
        value: value.value,
        isNewScreen: false,
        errorText: '',
        isSuccess: false,
        error,
      },
      (): void => {
        const field = document.getElementsByClassName(this.props.iName)[0];
        if (this.state.value) {
          field.classList.add('field--not-empty');
        } else {
          field.classList.remove('field--not-empty');
        }
        this.props.boarding[this.props.iComponent].setProp(
          name,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          name,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  }

  handleBlur(): void {
    this.setState({ iconLoading: true, errorText: '', isSuccess: false });
    const { deposit, withdrawal } = this.props.banking;
    const { routingAccount } =
      this.props.boarding[this.props.iComponent].accountsInfo[
        this.props.iMetadata.typeBank
      ];
    validators
      .validateRouting(routingAccount, this.state.value)
      .then((result: any) => {
        const response = JSON.parse(result);
        this.setState(
          {
            error:
              response?.responseData?.verificationResponse === 'Declined' ||
              response?.responseData?.verificationResponse === null,
            errorText:
              response?.responseData?.verificationResponse === 'Declined'
                ? response?.responseData?.accountVerificationResult
                    ?.responseDescription
                : response?.responseData?.verificationResponse === null &&
                  response?.responseData?.errorMessages.length > 0
                ? response?.responseData?.errorMessages[0]
                : '',
            isSuccess:
              response?.responseData?.verificationResponse !== 'Declined' &&
              response?.responseData?.verificationResponse !== null,
            iconLoading: false,
          },
          (): void => {
            if (
              response?.responseData?.accountVerificationResult?.bankName !==
              null
            ) {
              this.props.boarding[this.props.iComponent].setProp(
                'bankName',
                response?.responseData?.accountVerificationResult?.bankName,
                this.props.iMetadata
              );
              this.props.boarding[this.props.iComponent].setError(
                'bankName',
                false,
                this.props.iMetadata
              );
            }
            if (response?.responseData?.errorMessages.length === 0) {
              this.props.boarding[this.props.iComponent].setError(
                'routingAccount',
                this.state.error,
                this.props.iMetadata
              );
            }
            if (this.props.iMetadata.index === 'deposit') {
              deposit.setError(this.state.error);
            } else {
              withdrawal.setError(this.state.error);
            }
            this.props.boarding[this.props.iComponent].setError(
              this.state.name,
              this.state.error,
              this.props.iMetadata
            );
          }
        );
      });
  }

  builderInput(): React.ReactElement {
    const error =
      this.props.boarding[this.props.iComponent].getError(
        this.props.iStoreName,
        this.props.iMetadata
      ) === true;
    const { nextClicked } = this.props.boarding;
    const { deposit, withdrawal } = this.props.banking;
    const validationError =
      this.props.iMetadata.index === 'deposit'
        ? deposit.getError()
        : withdrawal.getError();
    return (
      <div
        className={
          (error && !this.state.isNewScreen) ||
          (this.state.error && nextClicked)
            ? 'ui fluid input error loading'
            : 'ui fluid input loading'
        }
      >
        {(this.props.iToolTip || this.state.errorText !== '') &&
          validationError && (
            <Popup
              trigger={
                <BiInfoCircle
                  color="red"
                  className="info-icon masked-in-input"
                />
              }
            >
              {this.state.errorText !== ''
                ? this.state.errorText
                : this.props.iToolTip}
            </Popup>
          )}
        {/* {(this.state.errorText === '' && this.state.isSuccess) || !validationError && (
          <BsFillCheckCircleFill
            color="limegreen"
            className="info-icon masked-in-input"
          />
        )} */}
        <IMaskInput
          mask={/^[0-9]+$/i}
          name={this.props.iName}
          value={this.state.value ? String(this.state.value) : ''}
          autoComplete="off"
          defaultValue=""
          type={this.props.iType ? this.props.iType : 'text'}
          unmask={true}
          onAccept={(event: any, value: any): void =>
            this.handleChange(this.props.iStoreName, event, value)
          }
          placeholder={
            this.props.iTitle + (this.props.iRequired === true ? ' *' : '')
          }
          className="field__input"
          readOnly={this.props.iReadonly}
          id={this.props.iName}
          onMouseEnter={this.mouseIn}
          onMouseLeave={this.mouseOut}
        />
        {this.state.iconLoading && (
          <div className="icon-container">
            <i className="loader"></i>
          </div>
        )}
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <>
        <div className={`field ${this.props.iName}`}>
          <label className="field__label" htmlFor={this.props.iName}>
            {this.props.iTitle} {this.props.iRequired === true ? ' *' : ''}
          </label>
          {this.builderInput()}
        </div>
      </>
    );
  }
}

export default inject('boarding', 'banking')(observer(AccountNumberInput));
