import 'semantic-ui-css/semantic.min.css';
import './assets/css/icheck-bootstrap.min.css';
import './App.css';
import './assets/css/floating-label.css';
import './assets/css/boarding.css';

import { Provider } from 'mobx-react';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import BoardingStore from './store/BoardingStore';
import { ErrorBoundary } from './utils/ErrorBoundary';
import BoardingLinkView from './views/BoardingLinkView';
import MainCointainerLinkView from './views/MainContainerLinkView';
import PageNotFound from './views/PageNotFound';
import BankingContextStore from './store/BankingContextStore';

class App extends React.Component {
  render(): React.ReactNode {
    const WrapperApplicationLink = (props: any): React.ReactElement => {
      const params = useParams();
      const [searchParams] = useSearchParams();
      const boardingMode = searchParams.get('mode');
      const boardingConfig = searchParams.get('config');
      return (
        <ErrorBoundary>
          <MainCointainerLinkView
            {...{
              ...props,
              match: { params },
              edit: false,
              mode: boardingMode,
              config: boardingConfig,
            }}
          />
        </ErrorBoundary>
      );
    };

    const WrapperApplicationLinkEdit = (props: any): React.ReactElement => {
      const params = useParams();
      const [searchParams] = useSearchParams();
      const boardingMode = searchParams.get('mode');
      const boardingConfig = searchParams.get('config');
      const email = searchParams.get('email');
      const referenceId = searchParams.get('referenceId');
      let noLoginEdit = false;
      if (email && referenceId) {
        noLoginEdit = true;
      }
      return (
        <ErrorBoundary>
          <MainCointainerLinkView
            {...{
              ...props,
              match: { params },
              edit: true,
              noLoginEdit,
              email,
              referenceId,
              mode: boardingMode,
              config: boardingConfig,
            }}
          />
        </ErrorBoundary>
      );
    };
    const WrapperEmbededApplicationLink = (props: any): React.ReactElement => {
      const params = useParams();
      const [searchParams] = useSearchParams();
      const boardingMode = searchParams.get('mode');
      const boardingConfig = searchParams.get('config');
      return (
        <ErrorBoundary>
          <BoardingLinkView
            {...{
              ...props,
              match: { params },
              edit: false,
              mode: boardingMode,
              config: boardingConfig,
            }}
          />
        </ErrorBoundary>
      );
    };

    return (
      <Provider boarding={BoardingStore} banking={BankingContextStore}>
        <Router>
          <Routes>
            <Route
              path="/boarding/externalapp/load/:id"
              element={<WrapperApplicationLinkEdit />}
            />
            <Route
              path="/boarding/app/:id"
              element={<WrapperApplicationLink />}
            />
            <Route
              path="/boarding/embeded/app/:id"
              element={<WrapperEmbededApplicationLink />}
            />
            <Route
              path="/boarding/externalapp/new/:id"
              element={<WrapperApplicationLink />}
            />
            <Route path="*" element={<PageNotFound />} />
            <Route
              path="/boarding/app/page-not-found"
              element={<PageNotFound />}
            />
          </Routes>
        </Router>
      </Provider>
    );
  }
}

export default App;
