export const getMCC = (): Array<any> => {
  return [
    {
      mcc: '0742',
      edited_description: 'Veterinary Services',
      combined_description: 'Veterinary Services',
      usda_description: 'Veterinary Services',
      irs_description: 'Veterinary Services',
      irs_reportable: 'Yes',
      id: 0,
    },
    {
      mcc: '0763',
      edited_description: 'Agricultural Co-operatives',
      combined_description: 'Agricultural Co-operatives',
      usda_description: 'Agricultural Co-operatives',
      irs_description: 'Agricultural Cooperative',
      irs_reportable: 'Yes',
      id: 1,
    },
    {
      mcc: '0780',
      edited_description: 'Horticultural Services, Landscaping Services',
      combined_description: 'Horticultural Services, Landscaping Services',
      usda_description: 'Horticultural Services',
      irs_description: 'Landscaping Services',
      irs_reportable: 'Yes',
      id: 2,
    },
    {
      mcc: '1520',
      edited_description: 'General Contractors-Residential and Commercial',
      combined_description: 'General Contractors-Residential and Commercial',
      usda_description: 'General Contractors-Residential and Commercial',
      irs_description: 'General Contractors',
      irs_reportable: 'Yes',
      id: 3,
    },
    {
      mcc: '1711',
      edited_description:
        "Air Conditioning Contractors ' Sales and Installation, Heating Contractors ' Sales, Service, Installation",
      combined_description:
        "Air Conditioning Contractors ' Sales and Installation, Heating Contractors ' Sales, Service, Installation",
      usda_description: "Air Conditioning Contractors ' Sales and Installation",
      irs_description: 'Heating, Plumbing, A/C',
      irs_reportable: 'Yes',
      id: 4,
    },
    {
      mcc: '1731',
      edited_description: 'Electrical Contractors',
      combined_description: 'Electrical Contractors',
      usda_description: 'Electrical Contractors',
      irs_description: 'Electrical Contractors',
      irs_reportable: 'Yes',
      id: 5,
    },
    {
      mcc: '1740',
      edited_description:
        "Insulation ' Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors",
      combined_description:
        "Insulation ' Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors",
      usda_description: "Insulation ' Contractors",
      irs_description: 'Masonry, Stonework, and Plaster',
      irs_reportable: 'Yes',
      id: 6,
    },
    {
      mcc: '1750',
      edited_description: 'Carpentry Contractors',
      combined_description: 'Carpentry Contractors',
      usda_description: 'Carpentry Contractors',
      irs_description: 'Carpentry Contractors',
      irs_reportable: 'Yes',
      id: 7,
    },
    {
      mcc: '1761',
      edited_description:
        "Roofing ' Contractors, Sheet Metal Work ' Contractors, Siding ' Contractors",
      combined_description:
        "Roofing ' Contractors, Sheet Metal Work ' Contractors, Siding ' Contractors",
      usda_description: 'Roofing - Contractors',
      irs_description: 'Roofing/Siding, Sheet Metal',
      irs_reportable: 'Yes',
      id: 8,
    },
    {
      mcc: '1771',
      edited_description: "Contractors ' Concrete Work",
      combined_description: "Contractors ' Concrete Work",
      usda_description: "Contractors ' Concrete Work",
      irs_description: 'Concrete Work Contractors',
      irs_reportable: 'Yes',
      id: 9,
    },
    {
      mcc: '1799',
      edited_description:
        "Contractors ' Special Trade, Not Elsewhere Classified",
      combined_description:
        "Contractors ' Special Trade, Not Elsewhere Classified",
      usda_description: "Contractors ' Special Trade, Not Elsewhere Classified",
      irs_description: 'Special Trade Contractors',
      irs_reportable: 'Yes',
      id: 10,
    },
    {
      mcc: '2741',
      edited_description: 'Miscellaneous Publishing and Printing',
      combined_description: 'Miscellaneous Publishing and Printing',
      usda_description: 'Miscellaneous Publishing and Printing',
      irs_description: 'Miscellaneous Publishing and Printing',
      irs_reportable: 'Yes',
      id: 11,
    },
    {
      mcc: '2791',
      edited_description: 'Typesetting, Plate Making, & Related Services',
      combined_description: 'Typesetting, Plate Making, & Related Services',
      usda_description: 'Typesetting, Plate Making, & Related Services',
      irs_description: 'Typesetting, Plate Making, and Related Services',
      irs_reportable: 'Yes',
      id: 12,
    },
    {
      mcc: '2842',
      edited_description:
        'Specialty Cleaning, Polishing, and Sanitation Preparations',
      combined_description:
        'Specialty Cleaning, Polishing, and Sanitation Preparations',
      usda_description:
        'Specialty Cleaning, Polishing, and Sanitation Preparations',
      irs_description: 'Specialty Cleaning',
      irs_reportable: 'Yes',
      id: 13,
    },
    {
      mcc: '3003',
      edited_description: 'Airlines',
      combined_description: 'Airlines',
      usda_description: 'Airlines',
      irs_description: 'Airlines',
      irs_reportable: 'Yes',
      id: 17,
    },
    {
      mcc: '4011',
      edited_description: 'Railroads',
      combined_description: 'Railroads',
      usda_description: 'Railroads',
      irs_description: 'Railroads',
      irs_reportable: 'No1.6041-3(c)',
      id: 697,
    },
    {
      mcc: '4111',
      edited_description:
        "Local/Suburban Commuter Passenger Transportation ' Railroads, Feries, Local Water Transportation.",
      combined_description:
        "Local/Suburban Commuter Passenger Transportation ' Railroads, Feries, Local Water Transportation.",
      usda_description:
        "Local/Suburban Commuter Passenger Transportation ' Railroads, Feries, Local Water Transportation.",
      irs_description: 'Commuter Transport, Ferries',
      irs_reportable: 'Yes',
      id: 698,
    },
    {
      mcc: '4112',
      edited_description: 'Passenger Railways',
      combined_description: 'Passenger Railways',
      usda_description: 'Passenger Railways',
      irs_description: 'Passenger Railways',
      irs_reportable: 'Yes',
      id: 699,
    },
    {
      mcc: '4119',
      edited_description: 'Ambulance Services',
      combined_description: 'Ambulance Services',
      usda_description: 'Ambulance Services',
      irs_description: 'Ambulance Services',
      irs_reportable: 'Yes',
      id: 700,
    },
    {
      mcc: '4121',
      edited_description: 'Taxicabs and Limousines',
      combined_description: 'Taxicabs and Limousines',
      usda_description: 'Taxicabs and Limousines',
      irs_description: 'Taxicabs/Limousines',
      irs_reportable: 'Yes',
      id: 701,
    },
    {
      mcc: '4131',
      edited_description: 'Bus Lines, Including Charters, Tour Buses',
      combined_description: 'Bus Lines, Including Charters, Tour Buses',
      usda_description: 'Bus Lines, Including Charters, Tour Buses',
      irs_description: 'Bus Lines',
      irs_reportable: 'Yes',
      id: 702,
    },
    {
      mcc: '4214',
      edited_description:
        "Motor Freight Carriers, Moving and Storage Companies, Trucking ' Local/Long Distance, Delivery Services ' Local",
      combined_description:
        "Motor Freight Carriers, Moving and Storage Companies, Trucking ' Local/Long Distance, Delivery Services ' Local",
      usda_description: 'Motor Freight Carriers',
      irs_description:
        'Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services ',
      irs_reportable: 'No1.6041-3(c)',
      id: 703,
    },
    {
      mcc: '4215',
      edited_description:
        "Courier Services ' Air or Ground, Freight forwarders",
      combined_description:
        "Courier Services ' Air or Ground, Freight forwarders",
      usda_description: "Courier Services ' Air or Ground",
      irs_description: 'Courier Services ',
      irs_reportable: 'Yes',
      id: 704,
    },
    {
      mcc: '4225',
      edited_description: 'Public warehousing, Storage',
      combined_description: 'Public warehousing, Storage',
      usda_description: 'Warehousing, Public',
      irs_description:
        'Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage ',
      irs_reportable: 'No1.6041-3(c)',
      id: 705,
    },
    {
      mcc: '4411',
      edited_description: 'Cruise and Steamship Lines',
      combined_description: 'Cruise and Steamship Lines',
      usda_description: 'Cruise Lines',
      irs_description: 'Cruise Lines',
      irs_reportable: 'Yes',
      id: 706,
    },
    {
      mcc: '4457',
      edited_description: 'Boat Rentals and Leases',
      combined_description: 'Boat Rentals and Leases',
      usda_description: 'Boat Rentals and Leases',
      irs_description: 'Boat Rentals and Leases',
      irs_reportable: 'Yes',
      id: 707,
    },
    {
      mcc: '4468',
      edited_description: 'Marinas, Marine Service, and Supplies',
      combined_description: 'Marinas, Marine Service, and Supplies',
      usda_description: 'Marinas, Marine Service, and Supplies',
      irs_description: 'Marinas, Service and Supplies',
      irs_reportable: 'Yes',
      id: 708,
    },
    {
      mcc: '4511',
      edited_description: 'Airlines, Air Carriers ( not listed elsewhere)',
      combined_description: 'Airlines, Air Carriers ( not listed elsewhere)',
      usda_description: 'Airlines, Air Carriers ( not listed elsewhere)',
      irs_description: 'Airlines, Air Carriers',
      irs_reportable: 'Yes',
      id: 709,
    },
    {
      mcc: '4582',
      edited_description: 'Airports, Airport Terminals, Flying Fields',
      combined_description: 'Airports, Airport Terminals, Flying Fields',
      usda_description: 'Airports, Airport Terminals',
      irs_description: 'Airports, Flying Fields',
      irs_reportable: 'Yes',
      id: 710,
    },
    {
      mcc: '4722',
      edited_description: 'Travel Agencies and Tour Operations',
      combined_description: 'Travel Agencies and Tour Operations',
      usda_description: 'Travel Agencies and Tour Operations',
      irs_description: 'Travel Agencies, Tour Operators',
      irs_reportable: 'Yes',
      id: 711,
    },
    {
      mcc: '4784',
      edited_description: 'Toll and Bridge Fees',
      combined_description: 'Toll and Bridge Fees',
      usda_description: 'Toll and Bridge Fees',
      irs_description: 'Tolls/Bridge Fees',
      irs_reportable: 'No1.6041-3(c)',
      id: 713,
    },
    {
      mcc: '4789',
      edited_description: 'Transportation Services, Not elsewhere classified)',
      combined_description:
        'Transportation Services, Not elsewhere classified)',
      usda_description: 'Transportation Services, Not elsewhere classified)',
      irs_description: 'Transportation Services (Not Elsewhere Classified)',
      irs_reportable: 'Yes',
      id: 714,
    },
    {
      mcc: '4812',
      edited_description:
        'Telecommunications Equipment including telephone sales',
      combined_description:
        'Telecommunications Equipment including telephone sales',
      usda_description:
        'Telecommunications Equipment including telephone sales',
      irs_description: 'Telecommunication Equipment and Telephone Sales',
      irs_reportable: 'No1.6041-3(c)',
      id: 715,
    },
    {
      mcc: '4814',
      edited_description: 'Fax services, Telecommunication Services',
      combined_description: 'Fax services, Telecommunication Services',
      usda_description: 'Fax services',
      irs_description: 'Telecommunication Services',
      irs_reportable: 'No1.6041-3(c)',
      id: 716,
    },
    {
      mcc: '4816',
      edited_description: 'Computer Network Services',
      combined_description: 'Computer Network Services',
      usda_description: 'Computer Network Services',
      irs_description: 'Computer Network Services',
      irs_reportable: 'No1.6041-3(c)',
      id: 718,
    },
    {
      mcc: '4821',
      edited_description: 'Telegraph services',
      combined_description: 'Telegraph services',
      usda_description: 'Telegraph services',
      irs_description: 'Telegraph Services',
      irs_reportable: 'No1.6041-3(c)',
      id: 719,
    },
    {
      mcc: '4829',
      edited_description: "Money Orders ' Wire Transfer",
      combined_description: "Money Orders ' Wire Transfer",
      usda_description: "Money Orders ' Wire Transfer",
      irs_description: 'Wires, Money Orders',
      irs_reportable: 'No1.6041-3(c)',
      id: 720,
    },
    {
      mcc: '4899',
      edited_description:
        'Cable and other pay television (previously Cable Services)',
      combined_description:
        'Cable and other pay television (previously Cable Services)',
      usda_description:
        'Cable and other pay television (previously Cable Services)',
      irs_description: 'Cable, Satellite, and Other Pay Television and Radio',
      irs_reportable: 'No1.6041-3(c)',
      id: 721,
    },
    {
      mcc: '4900',
      edited_description: 'Electric, Gas, Sanitary and Water Utilities',
      combined_description: 'Electric, Gas, Sanitary and Water Utilities',
      usda_description: 'Electric, Gas, Sanitary and Water Utilities',
      irs_description: 'Utilities ',
      irs_reportable: 'No1.6041-3(c)',
      id: 722,
    },
    {
      mcc: '5013',
      edited_description: 'Motor vehicle supplies and new parts',
      combined_description: 'Motor vehicle supplies and new parts',
      usda_description: 'Motor vehicle supplies and new parts',
      irs_description: 'Motor Vehicle Supplies and New Parts',
      irs_reportable: 'No1.6041-3(c)',
      id: 723,
    },
    {
      mcc: '5021',
      edited_description: 'Office and Commercial Furniture',
      combined_description: 'Office and Commercial Furniture',
      usda_description: 'Office and Commercial Furniture',
      irs_description: 'Office and Commercial Furniture',
      irs_reportable: 'No1.6041-3(c)',
      id: 724,
    },
    {
      mcc: '5039',
      edited_description: 'Construction Materials, Not Elsewhere Classified',
      combined_description: 'Construction Materials, Not Elsewhere Classified',
      usda_description: 'Construction Materials, Not Elsewhere Classified',
      irs_description: 'Construction Materials (Not Elsewhere Classified)',
      irs_reportable: 'No1.6041-3(c)',
      id: 725,
    },
    {
      mcc: '5044',
      edited_description:
        'Office, Photographic, Photocopy, and Microfilm Equipment',
      combined_description:
        'Office, Photographic, Photocopy, and Microfilm Equipment',
      usda_description:
        'Office, Photographic, Photocopy, and Microfilm Equipment',
      irs_description:
        'Photographic, Photocopy, Microfilm Equipment, and Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 726,
    },
    {
      mcc: '5045',
      edited_description: 'Computers, Computer Peripheral Equipment, Software',
      combined_description:
        'Computers, Computer Peripheral Equipment, Software',
      usda_description: 'Computers, Computer Peripheral Equipment, Software',
      irs_description: 'Computers, Peripherals, and Software',
      irs_reportable: 'No1.6041-3(c)',
      id: 727,
    },
    {
      mcc: '5046',
      edited_description: 'Commercial Equipment, Not Elsewhere Classified',
      combined_description: 'Commercial Equipment, Not Elsewhere Classified',
      usda_description: 'Commercial Equipment, Not Elsewhere Classified',
      irs_description: 'Commercial Equipment (Not Elsewhere Classified)',
      irs_reportable: 'No1.6041-3(c)',
      id: 728,
    },
    {
      mcc: '5047',
      edited_description:
        'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
      combined_description:
        'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
      usda_description:
        'Medical, Dental Ophthalmic, Hospital Equipment and Supplies',
      irs_description:
        'Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 729,
    },
    {
      mcc: '5051',
      edited_description: 'Metal Service Centers and Offices',
      combined_description: 'Metal Service Centers and Offices',
      usda_description: 'Metal Service Centers and Offices',
      irs_description: 'Metal Service Centers',
      irs_reportable: 'No1.6041-3(c)',
      id: 730,
    },
    {
      mcc: '5065',
      edited_description: 'Electrical Parts and Equipment',
      combined_description: 'Electrical Parts and Equipment',
      usda_description: 'Electrical Parts and Equipment',
      irs_description: 'Electrical Parts and Equipment',
      irs_reportable: 'No1.6041-3(c)',
      id: 731,
    },
    {
      mcc: '5072',
      edited_description: 'Hardware Equipment and Supplies',
      combined_description: 'Hardware Equipment and Supplies',
      usda_description: 'Hardware Equipment and Supplies',
      irs_description: 'Hardware, Equipment, and Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 732,
    },
    {
      mcc: '5074',
      edited_description: 'Plumbing and Heating Equipment and Supplies',
      combined_description: 'Plumbing and Heating Equipment and Supplies',
      usda_description: 'Plumbing and Heating Equipment and Supplies',
      irs_description: 'Plumbing, Heating Equipment, and Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 733,
    },
    {
      mcc: '5085',
      edited_description: 'Industrial Supplies, Not Elsewhere Classified',
      combined_description: 'Industrial Supplies, Not Elsewhere Classified',
      usda_description: 'Industrial Supplies, Not Elsewhere Classified',
      irs_description: 'Industrial Supplies (Not Elsewhere Classified)',
      irs_reportable: 'No1.6041-3(c)',
      id: 734,
    },
    {
      mcc: '5094',
      edited_description: 'Precious Stones and Metals, Watches and Jewelry',
      combined_description: 'Precious Stones and Metals, Watches and Jewelry',
      usda_description: 'Precious Stones and Metals, Watches and Jewelry',
      irs_description: 'Precious Stones and Metals, Watches and Jewelry',
      irs_reportable: 'No1.6041-3(c)',
      id: 735,
    },
    {
      mcc: '5099',
      edited_description: 'Durable Goods, Not Elsewhere Classified',
      combined_description: 'Durable Goods, Not Elsewhere Classified',
      usda_description: 'Durable Goods, Not Elsewhere Classified',
      irs_description: 'Durable Goods (Not Elsewhere Classified)',
      irs_reportable: 'No1.6041-3(c)',
      id: 736,
    },
    {
      mcc: '5111',
      edited_description:
        'Stationery, Office Supplies, Printing, and Writing Paper',
      combined_description:
        'Stationery, Office Supplies, Printing, and Writing Paper',
      usda_description:
        'Stationery, Office Supplies, Printing, and Writing Paper',
      irs_description:
        'Stationary, Office Supplies, Printing and Writing Paper',
      irs_reportable: 'No1.6041-3(c)',
      id: 737,
    },
    {
      mcc: '5122',
      edited_description: "Drugs, Drug Proprietors, and Druggist ' sSundries",
      combined_description: "Drugs, Drug Proprietors, and Druggist ' sSundries",
      usda_description: "Drugs, Drug Proprietors, and Druggist ' sSundries",
      irs_description: 'Drugs, Drug Proprietaries, and Druggist Sundries',
      irs_reportable: 'No1.6041-3(c)',
      id: 738,
    },
    {
      mcc: '5131',
      edited_description: 'Piece Goods, Notions, and Other Dry Goods',
      combined_description: 'Piece Goods, Notions, and Other Dry Goods',
      usda_description: 'Piece Goods, Notions, and Other Dry Goods',
      irs_description: 'Piece Goods, Notions, and Other Dry Goods',
      irs_reportable: 'No1.6041-3(c)',
      id: 739,
    },
    {
      mcc: '5137',
      edited_description:
        "Men ' sWomen ' sand Children ' sUniforms and Commercial Clothing",
      combined_description:
        "Men ' sWomen ' sand Children ' sUniforms and Commercial Clothing",
      usda_description:
        "Men ' sWomen ' sand Children ' sUniforms and Commercial Clothing",
      irs_description: 'Uniforms, Commercial Clothing',
      irs_reportable: 'No1.6041-3(c)',
      id: 740,
    },
    {
      mcc: '5139',
      edited_description: 'Commercial Footwear',
      combined_description: 'Commercial Footwear',
      usda_description: 'Commercial Footwear',
      irs_description: 'Commercial Footwear',
      irs_reportable: 'No1.6041-3(c)',
      id: 741,
    },
    {
      mcc: '5169',
      edited_description:
        'Chemicals and Allied Products, Not Elsewhere Classified',
      combined_description:
        'Chemicals and Allied Products, Not Elsewhere Classified',
      usda_description:
        'Chemicals and Allied Products, Not Elsewhere Classified',
      irs_description:
        'Chemicals and Allied Products (Not Elsewhere Classified)',
      irs_reportable: 'No1.6041-3(c)',
      id: 742,
    },
    {
      mcc: '5172',
      edited_description: 'Petroleum and Petroleum Products',
      combined_description: 'Petroleum and Petroleum Products',
      usda_description: 'Petroleum and Petroleum Products',
      irs_description: 'Petroleum and Petroleum Products',
      irs_reportable: 'No1.6041-3(c)',
      id: 743,
    },
    {
      mcc: '5192',
      edited_description: 'Books, Periodicals, and Newspapers',
      combined_description: 'Books, Periodicals, and Newspapers',
      usda_description: 'Books, Periodicals, and Newspapers',
      irs_description: 'Books, Periodicals, and Newspapers',
      irs_reportable: 'No1.6041-3(c)',
      id: 744,
    },
    {
      mcc: '5193',
      edited_description: "Florists' Supplies, Nursery Stock and Flowers",
      combined_description: "Florists' Supplies, Nursery Stock and Flowers",
      usda_description: "Florists' Supplies, Nursery Stock and Flowers",
      irs_description: 'Florists Supplies, Nursery Stock, and Flowers',
      irs_reportable: 'No1.6041-3(c)',
      id: 745,
    },
    {
      mcc: '5198',
      edited_description: 'Paints, Varnishes, and Supplies',
      combined_description: 'Paints, Varnishes, and Supplies',
      usda_description: 'Paints, Varnishes, and Supplies',
      irs_description: 'Paints, Varnishes, and Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 746,
    },
    {
      mcc: '5199',
      edited_description: 'Non-durable Goods, Not Elsewhere Classified',
      combined_description: 'Non-durable Goods, Not Elsewhere Classified',
      usda_description: 'Non-durable Goods, Not Elsewhere Classified',
      irs_description: 'Nondurable Goods (Not Elsewhere Classified)',
      irs_reportable: 'No1.6041-3(c)',
      id: 747,
    },
    {
      mcc: '5200',
      edited_description: 'Home Supply Warehouse Stores',
      combined_description: 'Home Supply Warehouse Stores',
      usda_description: 'Home Supply Warehouse Stores',
      irs_description: 'Home Supply Warehouse Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 748,
    },
    {
      mcc: '5211',
      edited_description: 'Lumber and Building Materials Stores',
      combined_description: 'Lumber and Building Materials Stores',
      usda_description: 'Lumber and Building Materials Stores',
      irs_description: 'Lumber, Building Materials Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 749,
    },
    {
      mcc: '5231',
      edited_description: 'Glass, Paint, and Wallpaper Stores',
      combined_description: 'Glass, Paint, and Wallpaper Stores',
      usda_description: 'Wallpaper Stores',
      irs_description: 'Glass, Paint, and Wallpaper Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 750,
    },
    {
      mcc: '5251',
      edited_description: 'Hardware Stores',
      combined_description: 'Hardware Stores',
      usda_description: 'Hardware Stores',
      irs_description: 'Hardware Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 751,
    },
    {
      mcc: '5261',
      edited_description: "Nurseries ' Lawn and Garden Supply Store",
      combined_description: "Nurseries ' Lawn and Garden Supply Store",
      usda_description: "Nurseries ' Lawn and Garden Supply Store",
      irs_description: 'Nurseries, Lawn and Garden Supply Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 752,
    },
    {
      mcc: '5271',
      edited_description: 'Mobile Home Dealers',
      combined_description: 'Mobile Home Dealers',
      usda_description: 'Mobile Home Dealers',
      irs_description: 'Mobile Home Dealers',
      irs_reportable: 'No1.6041-3(c)',
      id: 753,
    },
    {
      mcc: '5300',
      edited_description: 'Wholesale Clubs',
      combined_description: 'Wholesale Clubs',
      usda_description: 'Wholesale Clubs',
      irs_description: 'Wholesale Clubs',
      irs_reportable: 'No1.6041-3(c)',
      id: 754,
    },
    {
      mcc: '5309',
      edited_description: 'Duty Free Store',
      combined_description: 'Duty Free Store',
      usda_description: 'Duty Free Store',
      irs_description: 'Duty Free Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 755,
    },
    {
      mcc: '5310',
      edited_description: 'Discount Stores',
      combined_description: 'Discount Stores',
      usda_description: 'Discount Stores',
      irs_description: 'Discount Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 756,
    },
    {
      mcc: '5311',
      edited_description: 'Department Stores',
      combined_description: 'Department Stores',
      usda_description: 'Department Stores',
      irs_description: 'Department Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 757,
    },
    {
      mcc: '5331',
      edited_description: 'Variety Stores',
      combined_description: 'Variety Stores',
      usda_description: 'Variety Stores',
      irs_description: 'Variety Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 758,
    },
    {
      mcc: '5399',
      edited_description: 'Misc. General Merchandise',
      combined_description: 'Misc. General Merchandise',
      usda_description: 'Misc. General Merchandise',
      irs_description: 'Miscellaneous General Merchandise',
      irs_reportable: 'No1.6041-3(c)',
      id: 759,
    },
    {
      mcc: '5411',
      edited_description: 'Grocery Stores, Supermarkets',
      combined_description: 'Grocery Stores, Supermarkets',
      usda_description: 'Grocery Stores',
      irs_description: 'Grocery Stores, Supermarkets',
      irs_reportable: 'No1.6041-3(c)',
      id: 760,
    },
    {
      mcc: '5422',
      edited_description: "Meat Provisioners ' Freezer and Locker",
      combined_description: "Meat Provisioners ' Freezer and Locker",
      usda_description: "Meat Provisioners ' Freezer and Locker",
      irs_description: 'Freezer and Locker Meat Provisioners',
      irs_reportable: 'No1.6041-3(c)',
      id: 761,
    },
    {
      mcc: '5441',
      edited_description: 'Candy, Nut, and Confectionery Stores',
      combined_description: 'Candy, Nut, and Confectionery Stores',
      usda_description: 'Candy Stores',
      irs_description: 'Candy, Nut, and Confectionery Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 762,
    },
    {
      mcc: '5451',
      edited_description: 'Dairy Products Stores',
      combined_description: 'Dairy Products Stores',
      usda_description: 'Dairy Products Stores',
      irs_description: 'Dairy Products Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 763,
    },
    {
      mcc: '5462',
      edited_description: 'Bakeries',
      combined_description: 'Bakeries',
      usda_description: 'Bakeries',
      irs_description: 'Bakeries',
      irs_reportable: 'No1.6041-3(c)',
      id: 764,
    },
    {
      mcc: '5499',
      edited_description:
        "Misc. Food Stores ' Convenience Stores and Specialty Markets",
      combined_description:
        "Misc. Food Stores ' Convenience Stores and Specialty Markets",
      usda_description:
        "Misc. Food Stores ' Convenience Stores and Specialty Markets",
      irs_description:
        'Miscellaneous Food Stores - Convenience Stores and Specialty Markets',
      irs_reportable: 'No1.6041-3(c)',
      id: 765,
    },
    {
      mcc: '5511',
      edited_description:
        'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
      combined_description:
        'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
      usda_description:
        'Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing',
      irs_description:
        'Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing ',
      irs_reportable: 'No1.6041-3(c)',
      id: 766,
    },
    {
      mcc: '5521',
      edited_description: 'Automobile and Truck Dealers (Used Only)',
      combined_description: 'Automobile and Truck Dealers (Used Only)',
      usda_description: 'Automobile and Truck Dealers (Used Only)',
      irs_description:
        'Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing ',
      irs_reportable: 'No1.6041-3(c)',
      id: 767,
    },
    {
      mcc: '5531',
      edited_description: 'Automobile Supply Stores',
      combined_description: 'Automobile Supply Stores',
      usda_description: 'Automobile Supply Stores',
      irs_description: 'Auto and Home Supply Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 768,
    },
    {
      mcc: '5532',
      edited_description: 'Automotive Tire Stores',
      combined_description: 'Automotive Tire Stores',
      usda_description: 'Automotive Tire Stores',
      irs_description: 'Automotive Tire Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 769,
    },
    {
      mcc: '5533',
      edited_description: 'Automotive Parts, Accessories Stores',
      combined_description: 'Automotive Parts, Accessories Stores',
      usda_description: 'Automotive Parts, Accessories Stores',
      irs_description: 'Automotive Parts and Accessories Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 770,
    },
    {
      mcc: '5541',
      edited_description:
        'Service Stations ( with or without ancillary services)',
      combined_description:
        'Service Stations ( with or without ancillary services)',
      usda_description:
        'Service Stations ( with or without ancillary services)',
      irs_description: 'Service Stations ',
      irs_reportable: 'No1.6041-3(c)',
      id: 771,
    },
    {
      mcc: '5542',
      edited_description: 'Automated Fuel Dispensers',
      combined_description: 'Automated Fuel Dispensers',
      usda_description: 'Automated Fuel Dispensers',
      irs_description: 'Automated Fuel Dispensers',
      irs_reportable: 'No1.6041-3(c)',
      id: 772,
    },
    {
      mcc: '5551',
      edited_description: 'Boat Dealers',
      combined_description: 'Boat Dealers',
      usda_description: 'Boat Dealers',
      irs_description: 'Boat Dealers',
      irs_reportable: 'No1.6041-3(c)',
      id: 773,
    },
    {
      mcc: '5561',
      edited_description: 'Recreational and Utility Trailers, Camp Dealers',
      combined_description: 'Recreational and Utility Trailers, Camp Dealers',
      usda_description: 'Recreational and Utility Trailers, Camp Dealers',
      irs_description: 'Motorcycle Shops, Dealers',
      irs_reportable: 'No1.6041-3(c)',
      id: 774,
    },
    {
      mcc: '5571',
      edited_description: 'Motorcycle Dealers',
      combined_description: 'Motorcycle Dealers',
      usda_description: 'Motorcycle Dealers',
      irs_description: 'Motorcycle Shops and Dealers',
      irs_reportable: 'No1.6041-3(c)',
      id: 775,
    },
    {
      mcc: '5592',
      edited_description: 'Motor Home Dealers',
      combined_description: 'Motor Home Dealers',
      usda_description: 'Motor Home Dealers',
      irs_description: 'Motor Homes Dealers',
      irs_reportable: 'No1.6041-3(c)',
      id: 776,
    },
    {
      mcc: '5598',
      edited_description: 'Snowmobile Dealers',
      combined_description: 'Snowmobile Dealers',
      usda_description: 'Snowmobile Dealers',
      irs_description: 'Snowmobile Dealers',
      irs_reportable: 'No1.6041-3(c)',
      id: 777,
    },
    {
      mcc: '5599',
      edited_description: 'Miscellaneous Auto Dealers ',
      combined_description: 'Miscellaneous Auto Dealers ',
      usda_description: 'Miscellaneous Auto Dealers',
      irs_description: 'Miscellaneous Auto Dealers ',
      irs_reportable: 'No1.6041-3(c)',
      id: 778,
    },
    {
      mcc: '5611',
      edited_description: "Men ' sand Boy ' sClothing and Accessories Stores",
      combined_description: "Men ' sand Boy ' sClothing and Accessories Stores",
      usda_description: "Men ' sand Boy ' sClothing and Accessories Stores",
      irs_description: "Men ' sand Boy ' sClothing and Accessories Stores",
      irs_reportable: 'No1.6041-3(c)',
      id: 779,
    },
    {
      mcc: '5621',
      edited_description: "Women ' sReady-to-Wear Stores",
      combined_description: "Women ' sReady-to-Wear Stores",
      usda_description: "Women ' sReady-to-Wear Stores",
      irs_description: "Women ' sReady-To-Wear Stores",
      irs_reportable: 'No1.6041-3(c)',
      id: 780,
    },
    {
      mcc: '5631',
      edited_description: "Women ' sAccessory and Specialty Shops",
      combined_description: "Women ' sAccessory and Specialty Shops",
      usda_description: "Women ' sAccessory and Specialty Shops",
      irs_description: "Women ' sAccessory and Specialty Shops",
      irs_reportable: 'No1.6041-3(c)',
      id: 781,
    },
    {
      mcc: '5641',
      edited_description: "Children ' sand Infant ' sWear Stores",
      combined_description: "Children ' sand Infant ' sWear Stores",
      usda_description: "Children ' sand Infant ' sWear Stores",
      irs_description: "Children ' sand Infant ' sWear Stores",
      irs_reportable: 'No1.6041-3(c)',
      id: 782,
    },
    {
      mcc: '5651',
      edited_description: 'Family Clothing Stores',
      combined_description: 'Family Clothing Stores',
      usda_description: 'Family Clothing Stores',
      irs_description: 'Family Clothing Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 783,
    },
    {
      mcc: '5655',
      edited_description: 'Sports Apparel, Riding Apparel Stores',
      combined_description: 'Sports Apparel, Riding Apparel Stores',
      usda_description: 'Sports Apparel, Riding Apparel Stores',
      irs_description: 'Sports and Riding Apparel Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 784,
    },
    {
      mcc: '5661',
      edited_description: 'Shoe Stores',
      combined_description: 'Shoe Stores',
      usda_description: 'Shoe Stores',
      irs_description: 'Shoe Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 785,
    },
    {
      mcc: '5681',
      edited_description: 'Furriers and Fur Shops',
      combined_description: 'Furriers and Fur Shops',
      usda_description: 'Furriers and Fur Shops',
      irs_description: 'Furriers and Fur Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 786,
    },
    {
      mcc: '5691',
      edited_description: "Men ' sand Women ' sClothing Stores",
      combined_description: "Men ' sand Women ' sClothing Stores",
      usda_description: "Men ' sand Women ' sClothing Stores",
      irs_description: "Men's, Women ' sClothing Stores",
      irs_reportable: 'No1.6041-3(c)',
      id: 787,
    },
    {
      mcc: '5697',
      edited_description: 'Tailors, Seamstress, Mending, and Alterations',
      combined_description: 'Tailors, Seamstress, Mending, and Alterations',
      usda_description: 'Tailors, Seamstress, Mending, and Alterations',
      irs_description: 'Tailors, Alterations',
      irs_reportable: 'Yes',
      id: 788,
    },
    {
      mcc: '5698',
      edited_description: 'Wig and Toupee Stores',
      combined_description: 'Wig and Toupee Stores',
      usda_description: 'Wig and Toupee Stores',
      irs_description: 'Wig and Toupee Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 789,
    },
    {
      mcc: '5699',
      edited_description: 'Miscellaneous Apparel and Accessory Shops',
      combined_description: 'Miscellaneous Apparel and Accessory Shops',
      usda_description: 'Miscellaneous Apparel and Accessory Shops',
      irs_description: 'Miscellaneous Apparel and Accessory Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 790,
    },
    {
      mcc: '5712',
      edited_description:
        'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
      combined_description:
        'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
      usda_description:
        'Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances',
      irs_description:
        'Furniture, Home Furnishings, and Equipment Stores, Except Appliances',
      irs_reportable: 'No1.6041-3(c)',
      id: 791,
    },
    {
      mcc: '5713',
      edited_description: 'Floor Covering Stores',
      combined_description: 'Floor Covering Stores',
      usda_description: 'Floor Covering Stores',
      irs_description: 'Floor Covering Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 792,
    },
    {
      mcc: '5714',
      edited_description: 'Drapery, Window Covering and Upholstery Stores',
      combined_description: 'Drapery, Window Covering and Upholstery Stores',
      usda_description: 'Drapery, Window Covering and Upholstery Stores',
      irs_description: 'Drapery, Window Covering, and Upholstery Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 793,
    },
    {
      mcc: '5718',
      edited_description:
        'Fireplace, Fireplace Screens, and Accessories Stores',
      combined_description:
        'Fireplace, Fireplace Screens, and Accessories Stores',
      usda_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
      irs_description: 'Fireplace, Fireplace Screens, and Accessories Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 794,
    },
    {
      mcc: '5719',
      edited_description: 'Miscellaneous Home Furnishing Specialty Stores',
      combined_description: 'Miscellaneous Home Furnishing Specialty Stores',
      usda_description: 'Miscellaneous Home Furnishing Specialty Stores',
      irs_description: 'Miscellaneous Home Furnishing Specialty Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 795,
    },
    {
      mcc: '5722',
      edited_description: 'Household Appliance Stores',
      combined_description: 'Household Appliance Stores',
      usda_description: 'Household Appliance Stores',
      irs_description: 'Household Appliance Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 796,
    },
    {
      mcc: '5732',
      edited_description: 'Electronic Sales',
      combined_description: 'Electronic Sales',
      usda_description: 'Electronic Sales',
      irs_description: 'Electronics Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 797,
    },
    {
      mcc: '5733',
      edited_description:
        'Music Stores, Musical Instruments, Piano Sheet Music',
      combined_description:
        'Music Stores, Musical Instruments, Piano Sheet Music',
      usda_description: 'Music Stores, Musical Instruments, Piano Sheet Music',
      irs_description:
        'Music Stores-Musical Instruments, Pianos, and Sheet Music',
      irs_reportable: 'No1.6041-3(c)',
      id: 798,
    },
    {
      mcc: '5734',
      edited_description: 'Computer Software Stores',
      combined_description: 'Computer Software Stores',
      usda_description: 'Computer Software Stores',
      irs_description: 'Computer Software Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 799,
    },
    {
      mcc: '5735',
      edited_description: 'Record Shops',
      combined_description: 'Record Shops',
      usda_description: 'Record Shops',
      irs_description: 'Record Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 800,
    },
    {
      mcc: '5811',
      edited_description: 'Caterers',
      combined_description: 'Caterers',
      usda_description: 'Caterers',
      irs_description: 'Caterers',
      irs_reportable: 'Yes',
      id: 801,
    },
    {
      mcc: '5812',
      edited_description: 'Eating places and Restaurants',
      combined_description: 'Eating places and Restaurants',
      usda_description: 'Eating places and Restaurants',
      irs_description: 'Eating Places, Restaurants',
      irs_reportable: 'No1.6041-3(c)',
      id: 802,
    },
    {
      mcc: '5813',
      edited_description:
        'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
      combined_description:
        'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
      usda_description:
        'Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques',
      irs_description: 'Drinking Places',
      irs_reportable: 'No1.6041-3(c)',
      id: 803,
    },
    {
      mcc: '5814',
      edited_description: 'Fast Food Restaurants',
      combined_description: 'Fast Food Restaurants',
      usda_description: 'Fast Food Restaurants',
      irs_description: 'Fast Food Restaurants',
      irs_reportable: 'No1.6041-3(c)',
      id: 804,
    },
    {
      mcc: '5815',
      edited_description: 'Digital Goods: Media, Books, Movies, Music',
      combined_description: 'Digital Goods: Media, Books, Movies, Music',
      usda_description: 'Digital Goods: Media, Books, Movies, Music',
      irs_description: '',
      irs_reportable: '',
      id: 805,
    },
    {
      mcc: '5816',
      edited_description: 'Digital Goods: Games',
      combined_description: 'Digital Goods: Games',
      usda_description: 'Digital Goods: Games',
      irs_description: '',
      irs_reportable: '',
      id: 806,
    },
    {
      mcc: '5817',
      edited_description: 'Digital Goods: Applications (Excludes Games)',
      combined_description: 'Digital Goods: Applications (Excludes Games)',
      usda_description: 'Digital Goods: Applications (Excludes Games)',
      irs_description: '',
      irs_reportable: '',
      id: 807,
    },
    {
      mcc: '5818',
      edited_description: 'Digital Goods: Large Digital Goods Merchant',
      combined_description: 'Digital Goods: Large Digital Goods Merchant',
      usda_description: 'Digital Goods: Large Digital Goods Merchant',
      irs_description: '',
      irs_reportable: '',
      id: 808,
    },
    {
      mcc: '5832',
      edited_description:
        "Antique Shops ' Sales, Repairs, and Restoration Services",
      combined_description:
        "Antique Shops ' Sales, Repairs, and Restoration Services",
      usda_description:
        "Antique Shops ' Sales, Repairs, and Restoration Services",
      irs_description: '',
      irs_reportable: '',
      id: 809,
    },
    {
      mcc: '5912',
      edited_description: 'Drug Stores and Pharmacies',
      combined_description: 'Drug Stores and Pharmacies',
      usda_description: 'Drug Stores and Pharmacies',
      irs_description: 'Drug Stores and Pharmacies',
      irs_reportable: 'No1.6041-3(c)',
      id: 810,
    },
    {
      mcc: '5921',
      edited_description: "Package Stores ' Beer, Wine, and Liquor",
      combined_description: "Package Stores ' Beer, Wine, and Liquor",
      usda_description: "Package Stores ' Beer, Wine, and Liquor",
      irs_description: 'Package Stores-Beer, Wine, and Liquor',
      irs_reportable: 'No1.6041-3(c)',
      id: 811,
    },
    {
      mcc: '5931',
      edited_description: 'Used Merchandise and Secondhand Stores',
      combined_description: 'Used Merchandise and Secondhand Stores',
      usda_description: 'Used Merchandise and Secondhand Stores',
      irs_description: 'Used Merchandise and Secondhand Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 812,
    },
    {
      mcc: '5932',
      edited_description: 'Antique Shops',
      combined_description: 'Antique Shops',
      usda_description: 'Antique Shops',
      irs_description: 'Antique Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 813,
    },
    {
      mcc: '5933',
      edited_description: 'Pawn Shops and Salvage Yards',
      combined_description: 'Pawn Shops and Salvage Yards',
      usda_description: 'Pawn Shops and Salvage Yards',
      irs_description: 'Pawn Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 814,
    },
    {
      mcc: '5935',
      edited_description: 'Wrecking and Salvage Yards',
      combined_description: 'Wrecking and Salvage Yards',
      usda_description: 'Wrecking and Salvage Yards',
      irs_description: 'Wrecking and Salvage Yards',
      irs_reportable: 'Yes',
      id: 815,
    },
    {
      mcc: '5937',
      edited_description: 'Antique Reproductions',
      combined_description: 'Antique Reproductions',
      usda_description: 'Antique Reproductions',
      irs_description: 'Antique Reproductions',
      irs_reportable: 'No1.6041-3(c)',
      id: 816,
    },
    {
      mcc: '5940',
      edited_description: "Bicycle Shops ' Sales and Service",
      combined_description: "Bicycle Shops ' Sales and Service",
      usda_description: "Bicycle Shops ' Sales and Service",
      irs_description: 'Bicycle Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 817,
    },
    {
      mcc: '5941',
      edited_description: 'Sporting Goods Stores',
      combined_description: 'Sporting Goods Stores',
      usda_description: 'Sporting Goods Stores',
      irs_description: 'Sporting Goods Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 818,
    },
    {
      mcc: '5942',
      edited_description: 'Book Stores',
      combined_description: 'Book Stores',
      usda_description: 'Book Stores',
      irs_description: 'Book Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 819,
    },
    {
      mcc: '5943',
      edited_description: 'Stationery Stores, Office and School Supply Stores',
      combined_description:
        'Stationery Stores, Office and School Supply Stores',
      usda_description: 'Stationery Stores, Office and School Supply Stores',
      irs_description: 'Stationery Stores, Office, and School Supply Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 820,
    },
    {
      mcc: '5944',
      edited_description: 'Watch, Clock, Jewelry, and Silverware Stores',
      combined_description: 'Watch, Clock, Jewelry, and Silverware Stores',
      usda_description: 'Watch, Clock, Jewelry, and Silverware Stores',
      irs_description: 'Jewelry Stores, Watches, Clocks, and Silverware Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 821,
    },
    {
      mcc: '5945',
      edited_description: 'Hobby, Toy, and Game Shops',
      combined_description: 'Hobby, Toy, and Game Shops',
      usda_description: 'Hobby, Toy, and Game Shops',
      irs_description: 'Hobby, Toy, and Game Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 822,
    },
    {
      mcc: '5946',
      edited_description: 'Camera and Photographic Supply Stores',
      combined_description: 'Camera and Photographic Supply Stores',
      usda_description: 'Camera and Photographic Supply Stores',
      irs_description: 'Camera and Photographic Supply Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 823,
    },
    {
      mcc: '5947',
      edited_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
      combined_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
      usda_description: 'Card Shops, Gift, Novelty, and Souvenir Shops',
      irs_description: 'Gift, Card, Novelty, and Souvenir Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 824,
    },
    {
      mcc: '5948',
      edited_description: 'Leather Goods Stores',
      combined_description: 'Leather Goods Stores',
      usda_description: 'Leather Goods Stores',
      irs_description: 'Luggage and Leather Goods Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 825,
    },
    {
      mcc: '5949',
      edited_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
      combined_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
      usda_description: 'Sewing, Needle, Fabric, and Price Goods Stores',
      irs_description: 'Sewing, Needlework, Fabric, and Piece Goods Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 826,
    },
    {
      mcc: '5950',
      edited_description: 'Glassware/Crystal Stores',
      combined_description: 'Glassware/Crystal Stores',
      usda_description: 'Glassware/Crystal Stores',
      irs_description: 'Glassware, Crystal Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 827,
    },
    {
      mcc: '5960',
      edited_description: 'Direct Marketing- Insurance Service',
      combined_description: 'Direct Marketing- Insurance Service',
      usda_description: 'Direct Marketing- Insurance Service',
      irs_description: 'Direct Marketing - Insurance Services',
      irs_reportable: 'Yes',
      id: 828,
    },
    {
      mcc: '5962',
      edited_description:
        "Direct Marketing ' Travel Related Arrangements Services",
      combined_description:
        "Direct Marketing ' Travel Related Arrangements Services",
      usda_description:
        "Direct Marketing ' Travel Related Arrangements Services",
      irs_description: 'Direct Marketing - Travel',
      irs_reportable: 'Yes',
      id: 830,
    },
    {
      mcc: '5963',
      edited_description: 'Door-to-Door Sales',
      combined_description: 'Door-to-Door Sales',
      usda_description: 'Door-to-Door Sales',
      irs_description: 'Door-To-Door Sales',
      irs_reportable: 'No1.6041-3(c)',
      id: 831,
    },
    {
      mcc: '5964',
      edited_description: "Direct Marketing ' Catalog Merchant",
      combined_description: "Direct Marketing ' Catalog Merchant",
      usda_description: "Direct Marketing ' Catalog Merchant",
      irs_description: 'Direct Marketing - Catalog Merchant',
      irs_reportable: 'No1.6041-3(c)',
      id: 832,
    },
    {
      mcc: '5965',
      edited_description:
        "Direct Marketing ' Catalog and Catalog and Retail Merchant",
      combined_description:
        "Direct Marketing ' Catalog and Catalog and Retail Merchant",
      usda_description:
        "Direct Marketing ' Catalog and Catalog and Retail Merchant",
      irs_description:
        'Direct Marketing - Combination Catalog and Retail Merchant',
      irs_reportable: 'No1.6041-3(c)',
      id: 833,
    },
    {
      mcc: '5966',
      edited_description: 'Direct Marketing- Outbound Telemarketing Merchant',
      combined_description: 'Direct Marketing- Outbound Telemarketing Merchant',
      usda_description: 'Direct Marketing- Outbound Telemarketing Merchant',
      irs_description: 'Direct Marketing - Outbound Tele',
      irs_reportable: 'No1.6041-3(c)',
      id: 834,
    },
    {
      mcc: '5967',
      edited_description: "Direct Marketing ' Inbound Teleservices Merchant",
      combined_description: "Direct Marketing ' Inbound Teleservices Merchant",
      usda_description: "Direct Marketing ' Inbound Teleservices Merchant",
      irs_description: 'Direct Marketing - Inbound Tele',
      irs_reportable: 'No1.6041-3(c)',
      id: 835,
    },
    {
      mcc: '5968',
      edited_description: "Direct Marketing ' Continuity/Subscription Merchant",
      combined_description:
        "Direct Marketing ' Continuity/Subscription Merchant",
      usda_description: "Direct Marketing ' Continuity/Subscription Merchant",
      irs_description: 'Direct Marketing - Subscription',
      irs_reportable: 'No1.6041-3(c)',
      id: 836,
    },
    {
      mcc: '5969',
      edited_description: "Direct Marketing ' Not Elsewhere Classified",
      combined_description: "Direct Marketing ' Not Elsewhere Classified",
      usda_description: "Direct Marketing ' Not Elsewhere Classified",
      irs_description: 'Direct Marketing - Other ',
      irs_reportable: 'No1.6041-3(c)',
      id: 837,
    },
    {
      mcc: '5970',
      edited_description: "Artist ' sSupply and Craft Shops",
      combined_description: "Artist ' sSupply and Craft Shops",
      usda_description: "Artist ' sSupply and Craft Shops",
      irs_description: "Artist ' sSupply and Craft Shops",
      irs_reportable: 'No1.6041-3(c)',
      id: 838,
    },
    {
      mcc: '5971',
      edited_description: 'Art Dealers and Galleries',
      combined_description: 'Art Dealers and Galleries',
      usda_description: 'Art Dealers and Galleries',
      irs_description: 'Art Dealers and Galleries',
      irs_reportable: 'No1.6041-3(c)',
      id: 839,
    },
    {
      mcc: '5972',
      edited_description:
        "Stamp and Coin Stores ' Philatelic and Numismatic Supplies",
      combined_description:
        "Stamp and Coin Stores ' Philatelic and Numismatic Supplies",
      usda_description:
        "Stamp and Coin Stores ' Philatelic and Numismatic Supplies",
      irs_description: 'Stamp and Coin Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 840,
    },
    {
      mcc: '5973',
      edited_description: 'Religious Goods Stores',
      combined_description: 'Religious Goods Stores',
      usda_description: 'Religious Goods Stores',
      irs_description: 'Religious Goods Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 841,
    },
    {
      mcc: '5975',
      edited_description: "Hearing Aids ' Sales, Service, and Supply Stores",
      combined_description: "Hearing Aids ' Sales, Service, and Supply Stores",
      usda_description: "Hearing Aids ' Sales, Service, and Supply Stores",
      irs_description: 'Hearing Aids Sales and Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 842,
    },
    {
      mcc: '5976',
      edited_description: 'Orthopedic Goods Prosthetic Devices',
      combined_description: 'Orthopedic Goods Prosthetic Devices',
      usda_description: 'Orthopedic Goods Prosthetic Devices',
      irs_description: 'Orthopedic Goods - Prosthetic Devices',
      irs_reportable: 'No1.6041-3(c)',
      id: 843,
    },
    {
      mcc: '5977',
      edited_description: 'Cosmetic Stores',
      combined_description: 'Cosmetic Stores',
      usda_description: 'Cosmetic Stores',
      irs_description: 'Cosmetic Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 844,
    },
    {
      mcc: '5978',
      edited_description: "Typewriter Stores ' Sales, Rental, Service",
      combined_description: "Typewriter Stores ' Sales, Rental, Service",
      usda_description: "Typewriter Stores ' Sales, Rental, Service",
      irs_description: 'Typewriter Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 845,
    },
    {
      mcc: '5983',
      edited_description: "Fuel ' Fuel Oil, Wood, Coal, Liquefied Petroleum",
      combined_description: "Fuel ' Fuel Oil, Wood, Coal, Liquefied Petroleum",
      usda_description: "Fuel ' Fuel Oil, Wood, Coal, Liquefied Petroleum",
      irs_description: 'Fuel Dealers (Non Automotive)',
      irs_reportable: 'No1.6041-3(c)',
      id: 846,
    },
    {
      mcc: '5992',
      edited_description: 'Florists',
      combined_description: 'Florists',
      usda_description: 'Florists',
      irs_description: 'Florists',
      irs_reportable: 'No1.6041-3(c)',
      id: 847,
    },
    {
      mcc: '5993',
      edited_description: 'Cigar Stores and Stands',
      combined_description: 'Cigar Stores and Stands',
      usda_description: 'Cigar Stores and Stands',
      irs_description: 'Cigar Stores and Stands',
      irs_reportable: 'No1.6041-3(c)',
      id: 848,
    },
    {
      mcc: '5994',
      edited_description: 'News Dealers and Newsstands',
      combined_description: 'News Dealers and Newsstands',
      usda_description: 'News Dealers and Newsstands',
      irs_description: 'News Dealers and Newsstands',
      irs_reportable: 'No1.6041-3(c)',
      id: 849,
    },
    {
      mcc: '5995',
      edited_description: 'Pet Shops, Pet Foods, and Supplies Stores',
      combined_description: 'Pet Shops, Pet Foods, and Supplies Stores',
      usda_description: 'Pet Shops, Pet Foods, and Supplies Stores',
      irs_description: 'Pet Shops, Pet Food, and Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 850,
    },
    {
      mcc: '5996',
      edited_description: "Swimming Pools ' Sales, Service, and Supplies",
      combined_description: "Swimming Pools ' Sales, Service, and Supplies",
      usda_description: "Swimming Pools ' Sales, Service, and Supplies",
      irs_description: 'Swimming Pools Sales',
      irs_reportable: 'No1.6041-3(c)',
      id: 851,
    },
    {
      mcc: '5997',
      edited_description: "Electric Razor Stores ' Sales and Service",
      combined_description: "Electric Razor Stores ' Sales and Service",
      usda_description: "Electric Razor Stores ' Sales and Service",
      irs_description: 'Electric Razor Stores',
      irs_reportable: 'No1.6041-3(c)',
      id: 852,
    },
    {
      mcc: '5998',
      edited_description: 'Tent and Awning Shops',
      combined_description: 'Tent and Awning Shops',
      usda_description: 'Tent and Awning Shops',
      irs_description: 'Tent and Awning Shops',
      irs_reportable: 'No1.6041-3(c)',
      id: 853,
    },
    {
      mcc: '5999',
      edited_description: 'Miscellaneous and Specialty Retail Stores',
      combined_description: 'Miscellaneous and Specialty Retail Stores',
      usda_description: 'Miscellaneous and Specialty Retail Stores',
      irs_description: 'Miscellaneous Specialty Retail',
      irs_reportable: 'No1.6041-3(c)',
      id: 854,
    },
    {
      mcc: '6010',
      edited_description: "Financial Institutions ' Manual Cash Disbursements",
      combined_description:
        "Financial Institutions ' Manual Cash Disbursements",
      usda_description: "Financial Institutions ' Manual Cash Disbursements",
      irs_description: 'Manual Cash Disburse',
      irs_reportable: 'No1.6041-3(c)',
      id: 855,
    },
    {
      mcc: '6011',
      edited_description: "Financial Institutions ' Manual Cash Disbursements",
      combined_description:
        "Financial Institutions ' Manual Cash Disbursements",
      usda_description: "Financial Institutions ' Manual Cash Disbursements",
      irs_description: 'Automated Cash Disburse',
      irs_reportable: 'No1.6041-3(c)',
      id: 856,
    },
    {
      mcc: '6012',
      edited_description: "Financial Institutions ' Merchandise and Services",
      combined_description: "Financial Institutions ' Merchandise and Services",
      usda_description: "Financial Institutions ' Merchandise and Services",
      irs_description: 'Financial Institutions',
      irs_reportable: 'Yes',
      id: 857,
    },
    {
      mcc: '6051',
      edited_description:
        "Non-Financial Institutions ' Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques",
      combined_description:
        "Non-Financial Institutions ' Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques",
      usda_description:
        "Non-Financial Institutions ' Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques",
      irs_description: 'Non-FI, Money Orders',
      irs_reportable: 'No1.6041-3(c)',
      id: 858,
    },
    {
      mcc: '6211',
      edited_description: 'Security Brokers/Dealers',
      combined_description: 'Security Brokers/Dealers',
      usda_description: 'Security Brokers/Dealers',
      irs_description: 'Security Brokers/Dealers',
      irs_reportable: 'Yes',
      id: 859,
    },
    {
      mcc: '6300',
      edited_description: 'Insurance Sales, Underwriting, and Premiums',
      combined_description: 'Insurance Sales, Underwriting, and Premiums',
      usda_description: 'Insurance Sales, Underwriting, and Premiums',
      irs_description: 'Insurance Underwriting, Premiums',
      irs_reportable: 'No1.6041-3(c)',
      id: 860,
    },
    {
      mcc: '6399',
      edited_description:
        'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
      combined_description:
        'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
      usda_description:
        'Insurance, Not Elsewhere Classified ( no longer valid forfirst presentment work)',
      irs_description: 'Insurance - Default',
      irs_reportable: 'No1.6041-3(c)',
      id: 862,
    },
    {
      mcc: '6513',
      edited_description: 'Real Estate Agents and Managers - Rentals',
      combined_description: 'Real Estate Agents and Managers - Rentals',
      usda_description: 'Real Estate Agents and Managers - Rentals',
      irs_description: 'Real Estate Agents and Managers - Rentals',
      irs_reportable: 'Yes',
      id: 863,
    },
    {
      mcc: '7011',
      edited_description:
        "Lodging ' Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)",
      combined_description:
        "Lodging ' Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)",
      usda_description:
        "Lodging ' Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)",
      irs_description: 'Hotels, Motels, and Resorts',
      irs_reportable: 'Yes',
      id: 864,
    },
    {
      mcc: '7012',
      edited_description: 'Timeshares',
      combined_description: 'Timeshares',
      usda_description: 'Timeshares',
      irs_description: 'Timeshares',
      irs_reportable: 'Yes',
      id: 865,
    },
    {
      mcc: '7032',
      edited_description: 'Sporting and Recreational Camps',
      combined_description: 'Sporting and Recreational Camps',
      usda_description: 'Sporting and Recreational Camps',
      irs_description: 'Sporting/Recreation Camps',
      irs_reportable: 'Yes',
      id: 866,
    },
    {
      mcc: '7033',
      edited_description: 'Trailer Parks and Camp Grounds',
      combined_description: 'Trailer Parks and Camp Grounds',
      usda_description: 'Trailer Parks and Camp Grounds',
      irs_description: 'Trailer Parks, Campgrounds',
      irs_reportable: 'Yes',
      id: 867,
    },
    {
      mcc: '7210',
      edited_description: 'Laundry, Cleaning, and Garment Services',
      combined_description: 'Laundry, Cleaning, and Garment Services',
      usda_description: 'Laundry, Cleaning, and Garment Services',
      irs_description: 'Laundry, Cleaning Services',
      irs_reportable: 'Yes',
      id: 868,
    },
    {
      mcc: '7211',
      edited_description: "Laundry ' Family and Commercial",
      combined_description: "Laundry ' Family and Commercial",
      usda_description: "Laundry ' Family and Commercial",
      irs_description: 'Laundries ',
      irs_reportable: 'Yes',
      id: 869,
    },
    {
      mcc: '7216',
      edited_description: 'Dry Cleaners',
      combined_description: 'Dry Cleaners',
      usda_description: 'Dry Cleaners',
      irs_description: 'Dry Cleaners',
      irs_reportable: 'Yes',
      id: 870,
    },
    {
      mcc: '7217',
      edited_description: 'Carpet and Upholstery Cleaning',
      combined_description: 'Carpet and Upholstery Cleaning',
      usda_description: 'Carpet and Upholstery Cleaning',
      irs_description: 'Carpet/Upholstery Cleaning',
      irs_reportable: 'Yes',
      id: 871,
    },
    {
      mcc: '7221',
      edited_description: 'Photographic Studios',
      combined_description: 'Photographic Studios',
      usda_description: 'Photographic Studios',
      irs_description: 'Photographic Studios',
      irs_reportable: 'Yes',
      id: 872,
    },
    {
      mcc: '7230',
      edited_description: 'Barber and Beauty Shops',
      combined_description: 'Barber and Beauty Shops',
      usda_description: 'Barber and Beauty Shops',
      irs_description: 'Barber and Beauty Shops',
      irs_reportable: 'Yes',
      id: 873,
    },
    {
      mcc: '7251',
      edited_description:
        'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
      combined_description:
        'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
      usda_description:
        'Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops',
      irs_description: 'Shoe Repair/Hat Cleaning',
      irs_reportable: 'Yes',
      id: 874,
    },
    {
      mcc: '7261',
      edited_description: 'Funeral Service and Crematories',
      combined_description: 'Funeral Service and Crematories',
      usda_description: 'Funeral Service and Crematories',
      irs_description: 'Funeral Services, Crematories',
      irs_reportable: 'Yes',
      id: 875,
    },
    {
      mcc: '7273',
      edited_description: 'Dating and Escort Services',
      combined_description: 'Dating and Escort Services',
      usda_description: 'Dating and Escort Services',
      irs_description: 'Dating/Escort Services',
      irs_reportable: 'Yes',
      id: 876,
    },
    {
      mcc: '7276',
      edited_description: 'Tax Preparation Service',
      combined_description: 'Tax Preparation Service',
      usda_description: 'Tax Preparation Service',
      irs_description: 'Tax Preparation Services',
      irs_reportable: 'Yes',
      id: 877,
    },
    {
      mcc: '7277',
      edited_description: "Counseling Service ' Debt, Marriage, Personal",
      combined_description: "Counseling Service ' Debt, Marriage, Personal",
      usda_description: "Counseling Service ' Debt, Marriage, Personal",
      irs_description: 'Counseling Services',
      irs_reportable: 'Yes',
      id: 878,
    },
    {
      mcc: '7278',
      edited_description: 'Buying/Shopping Services, Clubs',
      combined_description: 'Buying/Shopping Services, Clubs',
      usda_description: 'Buying/Shopping Services, Clubs',
      irs_description: 'Buying/Shopping Services',
      irs_reportable: 'Yes',
      id: 879,
    },
    {
      mcc: '7296',
      edited_description: "Clothing Rental ' Costumes, Formal Wear, Uniforms",
      combined_description: "Clothing Rental ' Costumes, Formal Wear, Uniforms",
      usda_description: "Clothing Rental ' Costumes, Formal Wear, Uniforms",
      irs_description: 'Clothing Rental ',
      irs_reportable: 'Yes',
      id: 880,
    },
    {
      mcc: '7297',
      edited_description: 'Massage Parlors',
      combined_description: 'Massage Parlors',
      usda_description: 'Massage Parlors',
      irs_description: 'Massage Parlors',
      irs_reportable: 'Yes',
      id: 881,
    },
    {
      mcc: '7298',
      edited_description: 'Health and Beauty Shops',
      combined_description: 'Health and Beauty Shops',
      usda_description: 'Health and Beauty Shops',
      irs_description: 'Health and Beauty Spas',
      irs_reportable: 'Yes',
      id: 882,
    },
    {
      mcc: '7299',
      edited_description:
        'Miscellaneous Personal Services ( not elsewhere classifies)',
      combined_description:
        'Miscellaneous Personal Services ( not elsewhere classifies)',
      usda_description:
        'Miscellaneous Personal Services ( not elsewhere classifies)',
      irs_description: 'Miscellaneous General Services',
      irs_reportable: 'Yes',
      id: 883,
    },
    {
      mcc: '7311',
      edited_description: 'Advertising Services',
      combined_description: 'Advertising Services',
      usda_description: 'Advertising Services',
      irs_description: 'Advertising Services',
      irs_reportable: 'Yes',
      id: 884,
    },
    {
      mcc: '7321',
      edited_description: 'Consumer Credit Reporting Agencies',
      combined_description: 'Consumer Credit Reporting Agencies',
      usda_description: 'Consumer Credit Reporting Agencies',
      irs_description: 'Credit Reporting Agencies',
      irs_reportable: 'Yes',
      id: 885,
    },
    {
      mcc: '7332',
      edited_description: 'Blueprinting and Photocopying Services',
      combined_description: 'Blueprinting and Photocopying Services',
      usda_description: 'Blueprinting and Photocopying Services',
      irs_description: '',
      irs_reportable: '',
      id: 886,
    },
    {
      mcc: '7333',
      edited_description: 'Commercial Photography, Art and Graphics',
      combined_description: 'Commercial Photography, Art and Graphics',
      usda_description: 'Commercial Photography, Art and Graphics',
      irs_description: 'Commercial Photography, Art and Graphics',
      irs_reportable: 'Yes',
      id: 887,
    },
    {
      mcc: '7338',
      edited_description: 'Quick Copy, Reproduction and Blueprinting Services',
      combined_description:
        'Quick Copy, Reproduction and Blueprinting Services',
      usda_description: 'Quick Copy, Reproduction and Blueprinting Services',
      irs_description: 'Quick Copy, Repro, and Blueprint',
      irs_reportable: 'Yes',
      id: 888,
    },
    {
      mcc: '7339',
      edited_description: 'Stenographic and Secretarial Support Services',
      combined_description: 'Stenographic and Secretarial Support Services',
      usda_description: 'Stenographic and Secretarial Support Services',
      irs_description: 'Secretarial Support Services',
      irs_reportable: 'Yes',
      id: 889,
    },
    {
      mcc: '7342',
      edited_description: 'Exterminating and Disinfecting Services',
      combined_description: 'Exterminating and Disinfecting Services',
      usda_description: 'Exterminating and Disinfecting Services',
      irs_description: 'Exterminating Services',
      irs_reportable: 'Yes',
      id: 890,
    },
    {
      mcc: '7349',
      edited_description: 'Cleaning and Maintenance, Janitorial Services',
      combined_description: 'Cleaning and Maintenance, Janitorial Services',
      usda_description: 'Cleaning and Maintenance, Janitorial Services',
      irs_description: 'Cleaning and Maintenance',
      irs_reportable: 'Yes',
      id: 891,
    },
    {
      mcc: '7361',
      edited_description: 'Employment Agencies, Temporary Help Services',
      combined_description: 'Employment Agencies, Temporary Help Services',
      usda_description: 'Employment Agencies, Temporary Help Services',
      irs_description: 'Employment/Temp Agencies',
      irs_reportable: 'Yes',
      id: 892,
    },
    {
      mcc: '7372',
      edited_description:
        'Computer Programming, Integrated Systems Design and Data Processing Services',
      combined_description:
        'Computer Programming, Integrated Systems Design and Data Processing Services',
      usda_description:
        'Computer Programming, Integrated Systems Design and Data Processing Services',
      irs_description: 'Computer Programming',
      irs_reportable: 'Yes',
      id: 893,
    },
    {
      mcc: '7375',
      edited_description: 'Information Retrieval Services',
      combined_description: 'Information Retrieval Services',
      usda_description: 'Information Retrieval Services',
      irs_description: 'Information Retrieval Services',
      irs_reportable: 'Yes',
      id: 894,
    },
    {
      mcc: '7379',
      edited_description:
        'Computer Maintenance and Repair Services, Not Elsewhere Classified',
      combined_description:
        'Computer Maintenance and Repair Services, Not Elsewhere Classified',
      usda_description:
        'Computer Maintenance and Repair Services, Not Elsewhere Classified',
      irs_description: 'Computer Repair',
      irs_reportable: 'Yes',
      id: 895,
    },
    {
      mcc: '7392',
      edited_description:
        'Management, Consulting, and Public Relations Services',
      combined_description:
        'Management, Consulting, and Public Relations Services',
      usda_description: 'Management, Consulting, and Public Relations Services',
      irs_description: 'Consulting, Public Relations',
      irs_reportable: 'Yes',
      id: 896,
    },
    {
      mcc: '7393',
      edited_description:
        "Protective and Security Services ' Including Armored Carsand Guard Dogs",
      combined_description:
        "Protective and Security Services ' Including Armored Carsand Guard Dogs",
      usda_description:
        "Protective and Security Services ' Including Armored Carsand Guard Dogs",
      irs_description: 'Detective Agencies',
      irs_reportable: 'Yes',
      id: 897,
    },
    {
      mcc: '7394',
      edited_description:
        'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
      combined_description:
        'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
      usda_description:
        'Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental',
      irs_description: 'Equipment Rental ',
      irs_reportable: 'Yes',
      id: 898,
    },
    {
      mcc: '7395',
      edited_description: 'Photofinishing Laboratories, Photo Developing',
      combined_description: 'Photofinishing Laboratories, Photo Developing',
      usda_description: 'Photofinishing Laboratories, Photo Developing',
      irs_description: 'Photo Developing',
      irs_reportable: 'Yes',
      id: 899,
    },
    {
      mcc: '7399',
      edited_description: 'Business Services, Not Elsewhere Classified',
      combined_description: 'Business Services, Not Elsewhere Classified',
      usda_description: 'Business Services, Not Elsewhere Classified',
      irs_description: 'Miscellaneous Business Services ',
      irs_reportable: 'Yes',
      id: 900,
    },
    {
      mcc: '7511',
      edited_description: 'Truck Stop',
      combined_description: 'Truck Stop',
      usda_description: 'Truck Stop',
      irs_description: 'Truck Stop',
      irs_reportable: 'Yes',
      id: 901,
    },
    {
      mcc: '7512',
      edited_description: 'Car Rental Companies ( Not Listed Below)',
      combined_description: 'Car Rental Companies ( Not Listed Below)',
      usda_description: 'Car Rental Companies ( Not Listed Below)',
      irs_description: 'Car Rental Agencies',
      irs_reportable: 'Yes',
      id: 902,
    },
    {
      mcc: '7513',
      edited_description: 'Truck and Utility Trailer Rentals',
      combined_description: 'Truck and Utility Trailer Rentals',
      usda_description: 'Truck and Utility Trailer Rentals',
      irs_description: 'Truck/Utility Trailer Rentals',
      irs_reportable: 'Yes',
      id: 903,
    },
    {
      mcc: '7519',
      edited_description: 'Motor Home and Recreational Vehicle Rentals',
      combined_description: 'Motor Home and Recreational Vehicle Rentals',
      usda_description: 'Motor Home and Recreational Vehicle Rentals',
      irs_description: 'Recreational Vehicle Rentals',
      irs_reportable: 'Yes',
      id: 904,
    },
    {
      mcc: '7523',
      edited_description: 'Automobile Parking Lots and Garages',
      combined_description: 'Automobile Parking Lots and Garages',
      usda_description: 'Automobile Parking Lots and Garages',
      irs_description: 'Parking Lots, Garages',
      irs_reportable: 'Yes',
      id: 905,
    },
    {
      mcc: '7531',
      edited_description: 'Automotive Body Repair Shops',
      combined_description: 'Automotive Body Repair Shops',
      usda_description: 'Automotive Body Repair Shops',
      irs_description: 'Auto Body Repair Shops',
      irs_reportable: 'Yes',
      id: 906,
    },
    {
      mcc: '7534',
      edited_description: 'Tire Re-treading and Repair Shops',
      combined_description: 'Tire Re-treading and Repair Shops',
      usda_description: 'Tire Re-treading and Repair Shops',
      irs_description: 'Tire Retreading and Repair',
      irs_reportable: 'Yes',
      id: 907,
    },
    {
      mcc: '7535',
      edited_description: "Paint Shops ' Automotive",
      combined_description: "Paint Shops ' Automotive",
      usda_description: "Paint Shops ' Automotive",
      irs_description: 'Auto Paint Shops',
      irs_reportable: 'Yes',
      id: 908,
    },
    {
      mcc: '7538',
      edited_description: 'Automotive Service Shops',
      combined_description: 'Automotive Service Shops',
      usda_description: 'Automotive Service Shops',
      irs_description: 'Auto Service Shops',
      irs_reportable: 'Yes',
      id: 909,
    },
    {
      mcc: '7542',
      edited_description: 'Car Washes',
      combined_description: 'Car Washes',
      usda_description: 'Car Washes',
      irs_description: 'Car Washes',
      irs_reportable: 'Yes',
      id: 910,
    },
    {
      mcc: '7549',
      edited_description: 'Towing Services',
      combined_description: 'Towing Services',
      usda_description: 'Towing Services',
      irs_description: 'Towing Services',
      irs_reportable: 'Yes',
      id: 911,
    },
    {
      mcc: '7622',
      edited_description: 'Radio Repair Shops',
      combined_description: 'Radio Repair Shops',
      usda_description: 'Radio Repair Shops',
      irs_description: 'Electronics Repair Shops',
      irs_reportable: 'Yes',
      id: 912,
    },
    {
      mcc: '7623',
      edited_description: 'Air Conditioning and Refrigeration Repair Shops',
      combined_description: 'Air Conditioning and Refrigeration Repair Shops',
      usda_description: 'Air Conditioning and Refrigeration Repair Shops',
      irs_description: 'A/C, Refrigeration Repair',
      irs_reportable: 'Yes',
      id: 913,
    },
    {
      mcc: '7629',
      edited_description: 'Electrical And Small Appliance Repair Shops',
      combined_description: 'Electrical And Small Appliance Repair Shops',
      usda_description: 'Electrical And Small Appliance Repair Shops',
      irs_description: 'Small Appliance Repair',
      irs_reportable: 'Yes',
      id: 914,
    },
    {
      mcc: '7631',
      edited_description: 'Watch, Clock, and Jewelry Repair',
      combined_description: 'Watch, Clock, and Jewelry Repair',
      usda_description: 'Watch, Clock, and Jewelry Repair',
      irs_description: 'Watch/Jewelry Repair',
      irs_reportable: 'Yes',
      id: 915,
    },
    {
      mcc: '7641',
      edited_description:
        'Furniture, Furniture Repair, and Furniture Refinishing',
      combined_description:
        'Furniture, Furniture Repair, and Furniture Refinishing',
      usda_description:
        'Furniture, Furniture Repair, and Furniture Refinishing',
      irs_description: 'Furniture Repair, Refinishing',
      irs_reportable: 'Yes',
      id: 916,
    },
    {
      mcc: '7692',
      edited_description: 'Welding Repair',
      combined_description: 'Welding Repair',
      usda_description: 'Welding Repair',
      irs_description: 'Welding Repair',
      irs_reportable: 'Yes',
      id: 917,
    },
    {
      mcc: '7699',
      edited_description: "Repair Shops and Related Services 'Miscellaneous",
      combined_description: "Repair Shops and Related Services 'Miscellaneous",
      usda_description: "Repair Shops and Related Services 'Miscellaneous",
      irs_description: 'Miscellaneous Repair Shops',
      irs_reportable: 'Yes',
      id: 918,
    },
    {
      mcc: '7829',
      edited_description:
        'Motion Pictures and Video Tape Production and Distribution',
      combined_description:
        'Motion Pictures and Video Tape Production and Distribution',
      usda_description:
        'Motion Pictures and Video Tape Production and Distribution',
      irs_description: 'Picture/Video Production',
      irs_reportable: 'Yes',
      id: 922,
    },
    {
      mcc: '7832',
      edited_description: 'Motion Picture Theaters',
      combined_description: 'Motion Picture Theaters',
      usda_description: 'Motion Picture Theaters',
      irs_description: 'Motion Picture Theaters',
      irs_reportable: 'Yes',
      id: 923,
    },
    {
      mcc: '7841',
      edited_description: 'Video Tape Rental Stores',
      combined_description: 'Video Tape Rental Stores',
      usda_description: 'Video Tape Rental Stores',
      irs_description: 'Video Tape Rental Stores',
      irs_reportable: 'Yes',
      id: 924,
    },
    {
      mcc: '7911',
      edited_description: 'Dance Halls, Studios and Schools',
      combined_description: 'Dance Halls, Studios and Schools',
      usda_description: 'Dance Halls, Studios and Schools',
      irs_description: 'Dance Hall, Studios, Schools',
      irs_reportable: 'Yes',
      id: 925,
    },
    {
      mcc: '7922',
      edited_description:
        'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
      combined_description:
        'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
      usda_description:
        'Theatrical Producers (Except Motion Pictures), Ticket Agencies',
      irs_description: 'Theatrical Ticket Agencies',
      irs_reportable: 'Yes',
      id: 926,
    },
    {
      mcc: '7929',
      edited_description:
        'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
      combined_description:
        'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
      usda_description:
        'Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)',
      irs_description: 'Bands, Orchestras',
      irs_reportable: 'Yes',
      id: 927,
    },
    {
      mcc: '7932',
      edited_description: 'Billiard and Pool Establishments',
      combined_description: 'Billiard and Pool Establishments',
      usda_description: 'Billiard and Pool Establishments',
      irs_description: 'Billiard/Pool Establishments',
      irs_reportable: 'Yes',
      id: 928,
    },
    {
      mcc: '7933',
      edited_description: 'Bowling Alleys',
      combined_description: 'Bowling Alleys',
      usda_description: 'Bowling Alleys',
      irs_description: 'Bowling Alleys',
      irs_reportable: 'Yes',
      id: 929,
    },
    {
      mcc: '7941',
      edited_description:
        'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
      combined_description:
        'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
      usda_description:
        'Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters',
      irs_description: 'Sports Clubs/Fields',
      irs_reportable: 'Yes',
      id: 930,
    },
    {
      mcc: '7991',
      edited_description: 'Tourist Attractions and Exhibits',
      combined_description: 'Tourist Attractions and Exhibits',
      usda_description: 'Tourist Attractions and Exhibits',
      irs_description: 'Tourist Attractions and Exhibits',
      irs_reportable: 'Yes',
      id: 931,
    },
    {
      mcc: '7992',
      edited_description: "Golf Courses ' Public",
      combined_description: "Golf Courses ' Public",
      usda_description: "Golf Courses ' Public",
      irs_description: 'Golf Courses - Public',
      irs_reportable: 'Yes',
      id: 932,
    },
    {
      mcc: '7993',
      edited_description: 'Video Amusement Game Supplies',
      combined_description: 'Video Amusement Game Supplies',
      usda_description: 'Video Amusement Game Supplies',
      irs_description: 'Video Amusement Game Supplies',
      irs_reportable: 'No1.6041-3(c)',
      id: 933,
    },
    {
      mcc: '7994',
      edited_description: 'Video Game Arcades/Establishments',
      combined_description: 'Video Game Arcades/Establishments',
      usda_description: 'Video Game Arcades/Establishments',
      irs_description: 'Video Game Arcades',
      irs_reportable: 'Yes',
      id: 934,
    },
    {
      mcc: '7995',
      edited_description:
        'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
      combined_description:
        'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
      usda_description:
        'Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)',
      irs_description: 'Betting/Casino Gambling',
      irs_reportable: 'Yes',
      id: 935,
    },
    {
      mcc: '7996',
      edited_description:
        'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
      combined_description:
        'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
      usda_description: 'Amusement Parks, Carnivals, Circuses, Fortune Tellers',
      irs_description: 'Amusement Parks/Carnivals',
      irs_reportable: 'Yes',
      id: 936,
    },
    {
      mcc: '7997',
      edited_description:
        'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
      combined_description:
        'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
      usda_description:
        'Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses',
      irs_description: 'Country Clubs',
      irs_reportable: 'Yes',
      id: 937,
    },
    {
      mcc: '7998',
      edited_description: 'Aquariums, Sea-aquariums, Dolphinariums',
      combined_description: 'Aquariums, Sea-aquariums, Dolphinariums',
      usda_description: 'Aquariums, Sea-aquariums, Dolphinariums',
      irs_description: 'Aquariums',
      irs_reportable: 'Yes',
      id: 938,
    },
    {
      mcc: '7999',
      edited_description: 'Recreation Services (Not Elsewhere Classified)',
      combined_description: 'Recreation Services (Not Elsewhere Classified)',
      usda_description: 'Recreation Services (Not Elsewhere Classified)',
      irs_description: 'Miscellaneous Recreation Services',
      irs_reportable: 'Yes',
      id: 939,
    },
    {
      mcc: '8011',
      edited_description: 'Doctors and Physicians (Not Elsewhere Classified)',
      combined_description: 'Doctors and Physicians (Not Elsewhere Classified)',
      usda_description: 'Doctors and Physicians (Not Elsewhere Classified)',
      irs_description: 'Doctors',
      irs_reportable: 'Yes',
      id: 940,
    },
    {
      mcc: '8021',
      edited_description: 'Dentists and Orthodontists',
      combined_description: 'Dentists and Orthodontists',
      usda_description: 'Dentists and Orthodontists',
      irs_description: 'Dentists, Orthodontists',
      irs_reportable: 'Yes',
      id: 941,
    },
    {
      mcc: '8031',
      edited_description: 'Osteopaths',
      combined_description: 'Osteopaths',
      usda_description: 'Osteopaths',
      irs_description: 'Osteopaths',
      irs_reportable: 'Yes',
      id: 942,
    },
    {
      mcc: '8041',
      edited_description: 'Chiropractors',
      combined_description: 'Chiropractors',
      usda_description: 'Chiropractors',
      irs_description: 'Chiropractors',
      irs_reportable: 'Yes',
      id: 943,
    },
    {
      mcc: '8042',
      edited_description: 'Optometrists and Ophthalmologists',
      combined_description: 'Optometrists and Ophthalmologists',
      usda_description: 'Optometrists and Ophthalmologists',
      irs_description: 'Optometrists, Ophthalmologist',
      irs_reportable: 'Yes',
      id: 944,
    },
    {
      mcc: '8043',
      edited_description: 'Opticians, Opticians Goods and Eyeglasses',
      combined_description: 'Opticians, Opticians Goods and Eyeglasses',
      usda_description: 'Opticians, Opticians Goods and Eyeglasses',
      irs_description: 'Opticians, Eyeglasses',
      irs_reportable: 'Yes',
      id: 945,
    },
    {
      mcc: '8044',
      edited_description:
        'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
      combined_description:
        'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
      usda_description:
        'Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)',
      irs_description: '',
      irs_reportable: '',
      id: 946,
    },
    {
      mcc: '8049',
      edited_description: 'Podiatrists and Chiropodists',
      combined_description: 'Podiatrists and Chiropodists',
      usda_description: 'Podiatrists and Chiropodists',
      irs_description: 'Chiropodists, Podiatrists',
      irs_reportable: 'Yes',
      id: 947,
    },
    {
      mcc: '8050',
      edited_description: 'Nursing and Personal Care Facilities',
      combined_description: 'Nursing and Personal Care Facilities',
      usda_description: 'Nursing and Personal Care Facilities',
      irs_description: 'Nursing/Personal Care ',
      irs_reportable: 'Yes',
      id: 948,
    },
    {
      mcc: '8062',
      edited_description: 'Hospitals',
      combined_description: 'Hospitals',
      usda_description: 'Hospitals',
      irs_description: 'Hospitals',
      irs_reportable: 'Yes',
      id: 949,
    },
    {
      mcc: '8071',
      edited_description: 'Medical and Dental Laboratories',
      combined_description: 'Medical and Dental Laboratories',
      usda_description: 'Medical and Dental Laboratories',
      irs_description: 'Medical and Dental Labs',
      irs_reportable: 'Yes',
      id: 950,
    },
    {
      mcc: '8099',
      edited_description:
        'Medical Services and Health Practitioners (Not Elsewhere Classified)',
      combined_description:
        'Medical Services and Health Practitioners (Not Elsewhere Classified)',
      usda_description:
        'Medical Services and Health Practitioners (Not Elsewhere Classified)',
      irs_description: 'Medical Services ',
      irs_reportable: 'Yes',
      id: 951,
    },
    {
      mcc: '8111',
      edited_description: 'Legal Services and Attorneys',
      combined_description: 'Legal Services and Attorneys',
      usda_description: 'Legal Services and Attorneys',
      irs_description: 'Legal Services, Attorneys',
      irs_reportable: 'Yes',
      id: 952,
    },
    {
      mcc: '8211',
      edited_description: 'Elementary and Secondary Schools',
      combined_description: 'Elementary and Secondary Schools',
      usda_description: 'Elementary and Secondary Schools',
      irs_description: 'Elementary, Secondary Schools',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 953,
    },
    {
      mcc: '8220',
      edited_description:
        'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
      combined_description:
        'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
      usda_description:
        'Colleges, Junior Colleges, Universities, and ProfessionalSchools',
      irs_description: 'Colleges, Universities',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 954,
    },
    {
      mcc: '8241',
      edited_description: 'Correspondence Schools',
      combined_description: 'Correspondence Schools',
      usda_description: 'Correspondence Schools',
      irs_description: 'Correspondence Schools',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 955,
    },
    {
      mcc: '8244',
      edited_description: 'Business and Secretarial Schools',
      combined_description: 'Business and Secretarial Schools',
      usda_description: 'Business and Secretarial Schools',
      irs_description: 'Business/Secretarial Schools',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 956,
    },
    {
      mcc: '8249',
      edited_description: 'Vocational Schools and Trade Schools',
      combined_description: 'Vocational Schools and Trade Schools',
      usda_description: 'Vocational Schools and Trade Schools',
      irs_description: 'Vocational/Trade Schools',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 957,
    },
    {
      mcc: '8299',
      edited_description:
        'Schools and Educational Services ( Not Elsewhere Classified)',
      combined_description:
        'Schools and Educational Services ( Not Elsewhere Classified)',
      usda_description:
        'Schools and Educational Services ( Not Elsewhere Classified)',
      irs_description: 'Educational Services ',
      irs_reportable: 'Yes',
      id: 958,
    },
    {
      mcc: '8351',
      edited_description: 'Child Care Services',
      combined_description: 'Child Care Services',
      usda_description: 'Child Care Services',
      irs_description: 'Child Care Services',
      irs_reportable: 'Yes',
      id: 959,
    },
    {
      mcc: '8398',
      edited_description: 'Charitable and Social Service Organizations',
      combined_description: 'Charitable and Social Service Organizations',
      usda_description: 'Charitable and Social Service Organizations',
      irs_description:
        'Charitable and Social Service Organizations - Fundraising',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 960,
    },
    {
      mcc: '8641',
      edited_description: 'Civic, Fraternal, and Social Associations',
      combined_description: 'Civic, Fraternal, and Social Associations',
      usda_description: 'Civic, Fraternal, and Social Associations',
      irs_description: 'Civic, Social, Fraternal Associations',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 961,
    },
    {
      mcc: '8651',
      edited_description: 'Political Organizations',
      combined_description: 'Political Organizations',
      usda_description: 'Political Organizations',
      irs_description: 'Political Organizations',
      irs_reportable: 'Yes',
      id: 962,
    },
    {
      mcc: '8661',
      edited_description: 'Religious Organizations',
      combined_description: 'Religious Organizations',
      usda_description: 'Religious Organizations',
      irs_description: 'Religious Organizations',
      irs_reportable: 'No1.6041-3(p)(2)',
      id: 963,
    },
    {
      mcc: '8675',
      edited_description: 'Automobile Associations',
      combined_description: 'Automobile Associations',
      usda_description: 'Automobile Associations',
      irs_description: 'Automobile Associations',
      irs_reportable: 'Yes',
      id: 964,
    },
    {
      mcc: '8699',
      edited_description:
        'Membership Organizations ( Not Elsewhere Classified)',
      combined_description:
        'Membership Organizations ( Not Elsewhere Classified)',
      usda_description: 'Membership Organizations ( Not Elsewhere Classified)',
      irs_description: 'Membership Organizations',
      irs_reportable: 'Yes',
      id: 965,
    },
    {
      mcc: '8734',
      edited_description: 'Testing Laboratories ( non-medical)',
      combined_description: 'Testing Laboratories ( non-medical)',
      usda_description: 'Testing Laboratories ( non-medical)',
      irs_description: 'Testing Laboratories',
      irs_reportable: 'Yes',
      id: 966,
    },
    {
      mcc: '8911',
      edited_description: "Architectural ' Engineering and Surveying Services",
      combined_description:
        "Architectural ' Engineering and Surveying Services",
      usda_description: "Architectural ' Engineering and Surveying Services",
      irs_description: 'Architectural/Surveying Services',
      irs_reportable: 'Yes',
      id: 967,
    },
    {
      mcc: '8931',
      edited_description: 'Accounting, Auditing, and Bookkeeping Services',
      combined_description: 'Accounting, Auditing, and Bookkeeping Services',
      usda_description: 'Accounting, Auditing, and Bookkeeping Services',
      irs_description: 'Accounting/Bookkeeping Services',
      irs_reportable: 'Yes',
      id: 968,
    },
    {
      mcc: '8999',
      edited_description: 'Professional Services ( Not Elsewhere Defined)',
      combined_description: 'Professional Services ( Not Elsewhere Defined)',
      usda_description: 'Professional Services ( Not Elsewhere Defined)',
      irs_description: 'Professional Services',
      irs_reportable: 'Yes',
      id: 969,
    },
    {
      mcc: '9211',
      edited_description: 'Court Costs, including Alimony and Child Support',
      combined_description: 'Court Costs, including Alimony and Child Support',
      usda_description: 'Court Costs, including Alimony and Child Support',
      irs_description:
        'Court Costs, Including Alimony and Child Support - Courts of Law',
      irs_reportable: 'No1.6041-3(p)(4)',
      id: 970,
    },
    {
      mcc: '9222',
      edited_description: 'Fines',
      combined_description: 'Fines',
      usda_description: 'Fines',
      irs_description: 'Fines - Government Administrative Entities',
      irs_reportable: 'No1.6041-3(p)(4)',
      id: 971,
    },
    {
      mcc: '9223',
      edited_description: 'Bail and Bond Payments',
      combined_description: 'Bail and Bond Payments',
      usda_description: 'Bail and Bond Payments',
      irs_description:
        'Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency) ',
      irs_reportable: 'Yes',
      id: 972,
    },
    {
      mcc: '9311',
      edited_description: 'Tax Payments',
      combined_description: 'Tax Payments',
      usda_description: 'Tax Payments',
      irs_description: 'Tax Payments - Government Agencies',
      irs_reportable: 'No1.6041-3(p)(4)',
      id: 973,
    },
    {
      mcc: '9399',
      edited_description: 'Government Services ( Not Elsewhere Classified)',
      combined_description: 'Government Services ( Not Elsewhere Classified)',
      usda_description: 'Government Services ( Not Elsewhere Classified)',
      irs_description: 'Government Services (Not Elsewhere Classified)',
      irs_reportable: 'No1.6041-3(p)(4)',
      id: 974,
    },
    {
      mcc: '9402',
      edited_description: "Postal Services ' Government Only",
      combined_description: "Postal Services ' Government Only",
      usda_description: "Postal Services ' Government Only",
      irs_description: 'Postal Services - Government Only',
      irs_reportable: 'No1.6041-3(p)(3)',
      id: 975,
    },
    {
      mcc: '9405',
      edited_description: "Intra ' Government Transactions",
      combined_description: "Intra ' Government Transactions",
      usda_description: "Intra ' Government Transactions",
      irs_description: 'U.S. Federal Government Agencies or Departments',
      irs_reportable: 'No1.6041-3(p)(3)',
      id: 976,
    },
    {
      mcc: '9700',
      edited_description: 'Automated Referral Service ( For Visa Only)',
      combined_description: 'Automated Referral Service ( For Visa Only)',
      usda_description: 'Automated Referral Service ( For Visa Only)',
      irs_description: '',
      irs_reportable: '',
      id: 977,
    },
    {
      mcc: '9701',
      edited_description: 'Visa Credential Service ( For Visa Only)',
      combined_description: 'Visa Credential Service ( For Visa Only)',
      usda_description: 'Visa Credential Service ( For Visa Only)',
      irs_description: '',
      irs_reportable: '',
      id: 978,
    },
    {
      mcc: '9702',
      edited_description: 'GCAS Emergency Services ( For Visa Only)',
      combined_description: 'GCAS Emergency Services ( For Visa Only)',
      usda_description: 'GCAS Emergency Services ( For Visa Only)',
      irs_description: '',
      irs_reportable: '',
      id: 979,
    },
    {
      mcc: '9950',
      edited_description: "Intra ' Company Purchases ( For Visa Only)",
      combined_description: "Intra ' Company Purchases ( For Visa Only)",
      usda_description: "Intra ' Company Purchases ( For Visa Only)",
      irs_description: 'Intra-Company Purchases',
      irs_reportable: 'No1.6041-3(c)',
      id: 980,
    },
  ].map((e: any): any => {
    return {
      key: e?.mcc,
      value: e?.mcc,
      text: `${e?.mcc} - ${e?.irs_description}`,
    };
  });
};
