import { makeObservable, observable, action, computed } from 'mobx';

class BankingContextStore {
  deposit = {
    error: false,
    getError(): boolean {
      return this.error;
    },
    setError(error: boolean): void {
      this.error = error;
    },
  };

  withdrawal = {
    error: false,
    getError(): boolean {
      return this.error;
    },
    setError(error: boolean): void {
      this.error = error;
    },
  };

  constructor() {
    makeObservable(this, {
      deposit: observable,
      withdrawal: observable,
      setDepositError: action,
      getDepositError: computed,
      setWithdrawalError: action,
      getWithdrawalError: computed,
    });
  }

  get getDepositError(): boolean {
    return this.deposit.getError();
  }
  setDepositError(error: boolean): void {
    this.deposit.setError(error);
  }

  get getWithdrawalError(): boolean {
    return this.withdrawal.getError();
  }
  setWithdrawalError(error: boolean): void {
    this.withdrawal.setError(error);
  }
}

const bankingContextStore = new BankingContextStore();

export default bankingContextStore;
