import * as Sentry from '@sentry/react';
import type { ErrorInfo, ReactNode } from 'react';
import React, { Component } from 'react';
import { Accordion, Icon, Message } from 'semantic-ui-react';

export class ErrorBoundary extends Component<
  { children: ReactNode },
  { hasError: boolean; error: string; showErrorDetails: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: 'An error has occurred, please contact us.',
      showErrorDetails: false,
    };
    this.toggleErrorDetails = this.toggleErrorDetails.bind(this);
  }

  toggleErrorDetails(): void {
    this.setState({ showErrorDetails: !this.state.showErrorDetails });
  }

  public static getDerivedStateFromError(): any {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
    this.setState({ error: error.message });
    Sentry.captureEvent(error);
  }

  private resetError(): void {
    this.setState({ hasError: false });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className="mt-body3" data-testid="error-container">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="card-login mb-3" style={{ width: '50em' }}>
              <Message
                negative
                size="large"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Icon name="warning" size="large" />
                    <span>
                      There was an error in the boarding application. Please
                      contact support.
                    </span>
                  </div>
                  <Accordion style={{ marginTop: '-1.5rem' }}>
                    <Accordion.Title
                      active={this.state.showErrorDetails}
                      index={0}
                      onClick={this.toggleErrorDetails}
                    >
                      <Icon name="dropdown" />
                      More Info
                    </Accordion.Title>
                    <Accordion.Content
                      active={this.state.showErrorDetails}
                      style={{ marginTop: '-1.5rem' }}
                    >
                      <span style={{ paddingLeft: '2rem' }}>
                        {String(this.state.error)}
                      </span>
                    </Accordion.Content>
                  </Accordion>
                </p>
              </Message>
            </div>
            <p className="small-small">
              Powered by{' '}
              <a href="/" className="small no-underline">
                Payabli
              </a>
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
