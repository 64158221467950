import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IMaskInput } from 'react-imask';
import { Popup } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

interface TextInputProps {
  iValidator?: any;
  iValue?: string;
  iComponent: string;
  iStoreName: string;
  iMetadata?: any;
  iRequired?: boolean;
  iToolTip?: string;
  iReadonly?: boolean;
  iType?: string;
  iTitle: string;
  iName: string;
  boarding?: any;
}

const TextInput: React.FC<TextInputProps> = inject('boarding')(
  observer((props) => {
    const [value, setValue] = useState<string>(props.iValue || '');
    const [isNewScreen, setIsNewScreen] = useState<boolean>(true);
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const error =
        validators.stringValidator(props.iValidator, props.iValue) ||
        (props.iRequired && validators.isEmpty(props.iValue));
      setValue(props.iValue || '');
      if (props.boarding) {
        props.boarding[props.iComponent].setProp(
          props.iStoreName,
          props.iValue,
          props.iMetadata
        );
        props.boarding[props.iComponent].setError(
          props.iStoreName,
          error,
          props.iMetadata
        );
      }
    }, [props.iValue]);

    useEffect(() => {
      if (value && inputRef.current) {
        inputRef.current.classList.add('field--not-empty');
      } else if (inputRef.current) {
        inputRef.current.classList.remove('field--not-empty');
      }
    }, [value]);

    const handleChange = (name: string, event: any, valueChange: any): void => {
      const newValue = valueChange.value !== undefined ? valueChange.value : '';
      const error =
        (props.iRequired && validators.isEmpty(newValue)) ||
        validators.stringValidator(props.iValidator, newValue);
      setValue(newValue);
      setIsNewScreen(false);
      if (props.boarding) {
        props.boarding[props.iComponent].setProp(
          name,
          newValue,
          props.iMetadata
        );
        props.boarding[props.iComponent].setError(name, error, props.iMetadata);
      }
    };

    const builderInput = (): any => {
      const error =
        props.boarding &&
        props.boarding[props.iComponent].getError(
          props.iStoreName,
          props.iMetadata
        ) === true;
      const { nextClicked } = props.boarding || {};
      return (
        <div
          className={
            (error && !isNewScreen) || (error && nextClicked)
              ? 'ui fluid input error right corner labeled'
              : 'ui fluid input right corner labeled'
          }
        >
          {props.iToolTip && (
            <Popup
              trigger={
                <BiInfoCircle
                  data-testid="tooltip-icon"
                  className="info-icon in-input"
                />
              }
            >
              {props.iToolTip}
            </Popup>
          )}
          <IMaskInput
            mask={String}
            name={props.iName}
            value={value ? String(value) : ''}
            autoComplete="off"
            defaultValue=""
            readOnly={props.iReadonly}
            type={props.iType ? props.iType : 'text'}
            unmask={true}
            onAccept={(event: any, valueAccept: any): void =>
              handleChange(props.iStoreName, event, valueAccept)
            }
            placeholder={props.iTitle + (props.iRequired === true ? ' *' : '')}
            className="field__input"
            id={props.iName}
          />
        </div>
      );
    };

    return (
      <div className={`field ${props.iName}`} ref={inputRef}>
        <label className="field__label" htmlFor={props.iName}>
          {props.iTitle} {props.iRequired === true ? ' *' : ''}
        </label>
        {builderInput()}
      </div>
    );
  })
);

export default TextInput;
