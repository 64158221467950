import '../../assets/css/signature.css';

import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Dropdown, Grid, Icon } from 'semantic-ui-react';

import { getAttachmentsType } from '../../utils/input-tools';

class FileUploadInput extends React.Component<any, any> {
  fileInputRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      value: '',
      name: '',
      note: '',
      type: '',
      size: 0,
      ftype: '',
      showNote: false,
      dragActive: false,
      dragOver: false,
    };
    this.fileInputRef = React.createRef();
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.onAddNote = this.onAddNote.bind(this);
    this.handleChangeNote = this.handleChangeNote.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.onClickClearFile = this.onClickClearFile.bind(this);
  }

  async getBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (res: any): void => {
        resolve(res.target.result);
      };
      reader.onerror = (err: any): void => reject(err);
      reader.readAsDataURL(file);
    });
  }

  async readAttachment(file: File): Promise<any> {
    try {
      return await this.getBase64(file);
    } catch (error) {
      return null;
    }
  }

  componentDidMount(): void {
    const index = this.props.iMetadata?.index;
    const attachments = this.props.boarding.attachments.elementsList;
    const fileDescriptor = JSON.parse(
      attachments[index]?.fileDescriptor || '{}'
    );
    if (
      attachments.length > 0 &&
      index >= 0 &&
      attachments[index]?.fileDescriptor &&
      attachments[index]?.fileDescriptor !== 'signature' &&
      attachments[index]?.fileDescriptor !== '{}'
    ) {
      this.setState({
        showNote: true,
        note: fileDescriptor?.note || '',
        type: fileDescriptor?.type || '',
        name: this.props.attachmentInfo.filename,
      });
    }
  }

  handleDragOver = (e: any): void => {
    e.preventDefault();
    this.setState({ dragOver: true });
  };

  handleDragLeave = (): void => {
    this.setState({ dragOver: false });
  };

  handleDrag(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      this.setState({ dragActive: true });
    } else if (e.type === 'dragleave') {
      this.setState({ dragActive: false });
    }
  }

  handleDrop(e: any): void {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragActive: false });
    this.checkAndHandleFiles(e.dataTransfer);
  }

  checkAndHandleFiles(target: any): void {
    if (target.files.length && target.files[0]) {
      const file = target.files[0];
      if (this.props.boarding.attachments.totalSize + file.size > 29000000) {
        this.props.boarding.setFileExceededSize(true);
        target.value = '';
      } else {
        this.setState(
          {
            name: file.name,
            ftype: file.type,
            size: file.size,
          },
          () => this.handleFiles(target.files[0])
        );
      }
    } else {
      this.onClickClearFile();
    }
  }

  handleChange(e: any): void {
    this.checkAndHandleFiles(e.target);
  }

  handleChangeNote(e: any): void {
    this.setState({ note: e.target.value }, (): void => {
      const fileDescriptor = JSON.parse(
        this.props.boarding.attachments.elementsList[this.props.iMetadata.index]
          .fileDescriptor || '{}'
      );
      fileDescriptor.note = e.target.value;
      this.props.boarding.attachments.setProp(
        'fileDescriptor',
        JSON.stringify(fileDescriptor),
        this.props.iMetadata
      );
    });
  }

  handleFiles(file: any): void {
    if (file !== undefined) {
      this.readAttachment(file).then((result: any): void => {
        this.setState({ value: result }, () => {
          this.props.boarding.attachments.setProp(
            'filename',
            file.name,
            this.props.iMetadata
          );
          this.props.boarding.attachments.setProp(
            'ftype',
            file.type.split('/').at(-1),
            this.props.iMetadata
          );
          this.props.boarding.attachments.setProp(
            'fsize',
            file.size,
            this.props.iMetadata
          );
          this.props.boarding.attachments.setProp(
            'fContent',
            result.split(',').at(-1),
            this.props.iMetadata
          );
        });
      });
    } else {
      this.onClickClearFile();
    }
  }

  onAddNote(): void {
    this.setState({ showNote: true });
  }

  onButtonClick(): void {
    this.state.value.current.click();
  }

  onClickClearFile(): void {
    this.setState(
      {
        value: '',
        name: '',
        ftype: '',
        type: '',
        size: 0,
        showNote: false,
        note: '',
      },
      () => {
        this.props.boarding.attachments.setProp(
          'filename',
          '',
          this.props.iMetadata
        );
        this.props.boarding.attachments.setProp(
          'ftype',
          '',
          this.props.iMetadata
        );
        this.props.boarding.attachments.setProp(
          'fsize',
          0,
          this.props.iMetadata
        );
        this.props.boarding.attachments.setProp(
          'fContent',
          '',
          this.props.iMetadata
        );
        const fileUploadInput: any = document.getElementById(
          `input-file-upload${this.props.iMetadata?.index}`
        );
        if (fileUploadInput) {
          fileUploadInput.value = '';
          fileUploadInput.type = 'file';
        }
      }
    );
  }

  handleChangeType(e: any, { value }: any): void {
    const index = this.props.iMetadata?.index;
    const attachments = this.props.boarding.attachments.elementsList;
    const fileDescriptor = JSON.parse(
      attachments[index].fileDescriptor || '{}'
    );
    if (
      attachments.length > 0 &&
      index >= 0 &&
      attachments[index].fileDescriptor &&
      attachments[index].fileDescriptor !== 'signature'
    ) {
      this.setState({ type: value }, (): void => {
        fileDescriptor.type = value;
        this.props.boarding.attachments.setProp(
          'fileDescriptor',
          JSON.stringify(fileDescriptor),
          this.props.iMetadata
        );
      });
    } else {
      this.setState({ type: value }, (): void => {
        const fileDescriptor1 = JSON.parse(
          this.props.boarding.attachments.elementsList[
            this.props.iMetadata.index
          ].fileDescriptor || '{}'
        );
        fileDescriptor1.type = value;
        this.props.boarding.attachments.setProp(
          'fileDescriptor',
          JSON.stringify(fileDescriptor1),
          this.props.iMetadata
        );
      });
    }
  }

  render(): React.ReactNode {
    const typeOptions = getAttachmentsType();
    const { type } = this.state;
    return (
      <>
        <Grid doubling columns={2}>
          <Grid.Row columns={2}>
            <Grid.Column>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowWrap: 'anywhere',
                }}
              >
                <label
                  className={
                    this.state.dragActive
                      ? 'label-file-upload drag-active'
                      : 'label-file-upload'
                  }
                  id={`input-file-upload${this.props.iMetadata?.index}`}
                  style={{
                    height: '7.5rem',
                    cursor: 'pointer',
                  }}
                  htmlFor={`input-file-upload${this.props.iMetadata?.index}`}
                >
                  <div
                    className={`file-upload ${
                      this.state.dragOver ? 'drag-over' : ''
                    }`}
                    onDragEnter={this.handleDrag}
                    onDragLeave={this.handleDrag}
                    onDragOver={this.handleDrag}
                    onDrop={this.handleDrop}
                    onClick={(): void => this.fileInputRef.current.click()}
                  >
                    <p
                      style={{
                        padding: '16px',
                        textAlign: 'center',
                        fontSize: '13px',
                      }}
                    >
                      {(this.state.name && <span>{this.state.name}</span>) ||
                        'Drag and drop a file or Upload File'}
                    </p>
                    <input
                      accept="image/jpeg,image/png,application/pdf"
                      ref={this.fileInputRef}
                      type="file"
                      className="input-file-upload"
                      id={`input-file-upload${this.props.iMetadata?.index}`}
                      multiple={false}
                      onChange={this.handleChange}
                      data-testid="input-file-upload"
                    />
                  </div>
                </label>
                <div
                  style={{
                    position: 'relative',
                    top: '-96px',
                    right: '0',
                    cursor: 'pointer',
                  }}
                >
                  {(this.state.name && (
                    <Icon
                      data-testid="clear-file-upload"
                      name="trash"
                      onClick={(): void => this.onClickClearFile()}
                    />
                  )) || <></>}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div
                className={'ui fluid input field field--not-empty'}
                style={{ marginBottom: '1rem' }}
              >
                <label
                  className="field__label"
                  htmlFor={`document-type-file-upload${this.props.iMetadata?.index}`}
                >
                  Document Type
                </label>

                <Dropdown
                  onChange={this.handleChangeType}
                  fluid
                  options={typeOptions}
                  style={{
                    height: '100%',
                    marginBottom: '1rem',
                  }}
                  placeholder="Choose a document type"
                  selection
                  id={`document-type-file-upload${this.props.iMetadata?.index}`}
                  name={`document-type-file-upload${this.props.iMetadata?.index}`}
                  value={type}
                />
              </div>
              {(!this.state.showNote && (
                <Button
                  data-testid="document-note-add-btn"
                  fluid
                  primary
                  onClick={this.onAddNote}
                >
                  <Button.Content visible>Add Note to document</Button.Content>
                </Button>
              )) || (
                <div className={'ui fluid input field--not-empty'}>
                  <label
                    className="field__label"
                    htmlFor={`document-note-file-upload${this.props.iMetadata?.index}`}
                  >
                    Document Note
                  </label>
                  <input
                    className="ui fluid input"
                    name={this.props.iName}
                    data-testid="document-note-file-upload"
                    onChange={(e: any): void => this.handleChangeNote(e)}
                    value={
                      this.state.note ||
                      this.props.boarding.attachments.elementsList[
                        this.props.iMetadata.index
                      ].fileDescriptor?.note
                    }
                  />
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

export default inject('boarding')(observer(FileUploadInput));
