import { inject, observer } from 'mobx-react';
import React from 'react';
import { Divider, Grid, Transition } from 'semantic-ui-react';

import { renderInput } from '../utils/input-tools';
import { getOptionsType } from '../utils/optionsTypes';
import CopyInfoInput from './inputs/CopyInfoInput';

class BusinessDetail extends React.Component<any, any> {
  businessData: Array<any> = new Array<any>();

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      sameBusinessAddress: true,
    };
    this.businessData = props.businessData;
    this.setSameBusinessAddress = this.setSameBusinessAddress.bind(this);
    this.onCopyLegalName = this.onCopyLegalName.bind(this);
  }

  componentDidMount(): void {
    this.setState(
      {
        visible: true,
        sameBusinessAddress:
          this.props.boarding.businessDetails.sameBusinessAddress,
      },
      () => {
        Object.entries(this.businessData || []).map(
          (business: any, index: number): any => {
            if (this.state.sameBusinessAddress || !business[1]?.visible) {
              const inputName = business[0];
              const businessItem = this.businessData[inputName];
              const value: any = businessItem?.value;
              const fieldMetadata = {
                name: inputName,
                storeName: inputName,
                restrictedDob: new Date(),
                index,
                value: value || this.props.boarding.businessDetails[inputName],
                boarding: this.props.boarding,
                identifyField: inputName[0],
                metadata: {
                  options: getOptionsType(businessItem?.stype),
                },
                locationFields: [
                  'address',
                  'address1',
                  'city',
                  'state',
                  'zip',
                  'country',
                ],
                component: 'businessDetails',
              };
              this.props.boarding[fieldMetadata.component].setProp(
                inputName,
                fieldMetadata.value,
                fieldMetadata.metadata
              );
            }
          }
        );
      }
    );
  }

  /**
   * It sets the state of the component to the value of the checkbox, and then sets the
   * sameBusinessAddress property of the businessDetails object to the same value
   * @param {any} event - any - this is the event that is triggered when the checkbox is clicked.
   */
  setSameBusinessAddress(event: any): void {
    const { checked } = event.target;
    this.setState({ sameBusinessAddress: checked });
    this.props.boarding.businessDetails.setProp('sameBusinessAddress', checked);
    if (checked) {
      this.props.boarding.businessDetails.sameAsBusinessAddress();
    }
  }

  onCopyLegalName(): void {
    this.props.boarding.copyLegalName();
  }

  render(): React.ReactNode {
    const mailingFields = [
      'maddress',
      'maddress1',
      'mcity',
      'mstate',
      'mzip',
      'mcountry',
    ];
    const findShowMaddress = Object.entries(this.businessData || []).find(
      (data) => data[0] === 'showMaddress'
    );
    const showMaddress = Array.isArray(findShowMaddress)
      ? findShowMaddress[1]
      : true;

    return (
      <Transition
        key="trans-business-details"
        duration={400}
        visible={this.state?.visible}
      >
        <Grid key="grid-business-details" doubling columns={2}>
          {Object.entries(this.businessData || []).map(
            (business: any, index: number): any => {
              const inputName = business[0];
              const businessItem = this.businessData[inputName];
              if (inputName === undefined || inputName === '') return null;
              if (businessItem === undefined || businessItem === '')
                return null;
              const value: any = businessItem?.value;
              if (inputName !== 'showMaddress') {
                const fieldMetadata = {
                  name: inputName,
                  storeName: inputName,
                  restrictedDob: new Date(),
                  label: businessItem?.label,
                  toolTip: businessItem?.toolTip,
                  index,
                  required: businessItem?.required,
                  readonly: businessItem?.readonly,
                  value:
                    value || this.props.boarding.businessDetails[inputName],
                  boarding: this.props.boarding,
                  identifyField: inputName[0],
                  metadata: {
                    options: getOptionsType(businessItem?.stype),
                  },
                  locationFields: [
                    'address',
                    'address1',
                    'city',
                    'state',
                    'zip',
                    'country',
                  ],
                  component: 'businessDetails',
                };
                if (inputName === 'taxfillname') {
                  return (
                    business[1]?.visible && (
                      <Grid.Column
                        key={`column${index}`}
                        floated={
                          index === 0 || index % 2 === 0 ? 'left' : 'right'
                        }
                        width={8}
                      >
                        {renderInput(businessItem.type, fieldMetadata)}
                        <CopyInfoInput
                          copyText="Tax Filing Name same as Business Legal Name"
                          copyHandlerClick={(): void => this.onCopyLegalName()}
                        />
                      </Grid.Column>
                    )
                  );
                }
                if (inputName === 'maddress') {
                  return (
                    <div key="div-maddress">
                      {showMaddress && (
                        <>
                          <Divider key="divider-business" />
                          <Grid.Column
                            floated="left"
                            width={16}
                            key={`scolumn${index}`}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                              className="icheck-primary"
                            >
                              <input
                                style={{
                                  margin: '.4rem',
                                  backgroundColor: '#10A0E3',
                                  borderColor: '#10A0E3',
                                  minHeight: '12px',
                                  marginTop: '6px!important',
                                  marginBottom: '6px!important',
                                  paddingLeft: 0,
                                  width: '18px',
                                  height: '18px',
                                }}
                                checked={this.state.sameBusinessAddress}
                                type="checkbox"
                                name="sameBusinessAddress"
                                id="sameBusinessAddress"
                                onChange={(e: any): void =>
                                  this.setSameBusinessAddress(e)
                                }
                              />
                              <label htmlFor="sameBusinessAddress">
                                Mailing Address same as Business Address
                              </label>
                            </div>
                          </Grid.Column>
                        </>
                      )}
                      {!this.state.sameBusinessAddress &&
                        business[1]?.visible && (
                          <Grid.Column
                            key={`column${index}`}
                            floated={
                              index === 0 || index % 2 === 0 ? 'left' : 'right'
                            }
                            width={16}
                          >
                            {renderInput(businessItem.type, fieldMetadata)}
                          </Grid.Column>
                        )}
                    </div>
                  );
                }
                if (inputName === 'baddress') {
                  return (
                    business[1]?.visible && (
                      <Grid.Column
                        key={`column${index}`}
                        floated={
                          index === 0 || index % 2 === 0 ? 'left' : 'right'
                        }
                        width={16}
                      >
                        {renderInput(businessItem.type, fieldMetadata)}
                      </Grid.Column>
                    )
                  );
                }
                if (mailingFields.includes(inputName)) {
                  return (
                    !this.state.sameBusinessAddress &&
                    business[1]?.visible && (
                      <Grid.Column
                        key={`column${index}`}
                        floated={
                          index === 0 || index % 2 === 0 ? 'left' : 'right'
                        }
                        width={8}
                      >
                        {renderInput(businessItem.type, fieldMetadata)}
                      </Grid.Column>
                    )
                  );
                }
                return (
                  business[1]?.visible && (
                    <Grid.Column
                      key={`column${index}`}
                      floated={
                        index === 0 || index % 2 === 0 ? 'left' : 'right'
                      }
                      width={8}
                    >
                      {renderInput(businessItem.type, fieldMetadata)}
                    </Grid.Column>
                  )
                );
              }
            }
          )}
        </Grid>
      </Transition>
    );
  }
}

export default inject('boarding')(observer(BusinessDetail));
