import { inject, observer } from 'mobx-react';
import React from 'react';
import { Container, Icon, Message } from 'semantic-ui-react';

class ErrorPopUp extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      referenceId: '',
      error: '',
      reloadError: false,
    };
  }

  componentDidMount(): void {
    if (this.props.error) {
      this.setState({
        error: this.props.error,
        reloadError: this.props.reloadError,
      });
    }
  }

  render(): React.ReactNode {
    return (
      <div className="mt-body3" data-testid="error-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="card-login mb-3" style={{ width: '35em' }}>
            {this.state.error && (
              <Message negative size="large">
                <p>
                  <Icon name="warning" size="large" />
                  {String(this.state.error)}
                </p>
              </Message>
            )}
            {this.state.reloadError && (
              <Container fluid textAlign="center">
                <button
                  className="btn-unborder-danger ui"
                  onClick={(): void => window.location.reload()}
                >
                  RELOAD
                </button>
              </Container>
            )}
          </div>
          <p className="small-small">
            Powered by{' '}
            <a href="/" className="small no-underline">
              Payabli
            </a>
          </p>
        </div>
      </div>
    );
  }
}
export default inject('boarding')(observer(ErrorPopUp));
