import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IMaskInput } from 'react-imask';
import { Popup } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

interface MaskedInputProps {
  iValidator?: string;
  iValue?: string;
  iComponent: string;
  iStoreName: string;
  iMetadata?: any;
  iRequired?: boolean;
  iToolTip?: string;
  iReadonly?: boolean;
  iType?: string;
  iTitle: string;
  iName: string;
  iMask?: string;
  identifyBy?: string;
  boarding?: any;
}

const MaskedInput: React.FC<MaskedInputProps> = ({
  iValidator,
  iValue = '',
  iComponent,
  iStoreName,
  iMetadata,
  iRequired = false,
  iToolTip,
  iReadonly = false,
  iType = 'text',
  iTitle,
  iName,
  iMask,
  boarding,
}) => {
  const [value, setValue] = useState<string>(iValue);
  const [isNewScreen, setIsNewScreen] = useState<boolean>(true);
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const validationError =
      validators.stringValidator(iValidator as string, iValue) ||
      (iRequired && validators.isEmpty(iValue));
    setValue(iValue);
    boarding[iComponent].setProp(iStoreName, iValue, iMetadata);
    boarding[iComponent].setError(iStoreName, validationError, iMetadata);
  }, [iValue]);

  useEffect(() => {
    if (value && fieldRef.current) {
      fieldRef.current.classList.add('field--not-empty');
    } else if (fieldRef.current) {
      fieldRef.current.classList.remove('field--not-empty');
    }
  }, [value]);

  const handleChange = (name: string, event: any, pvalue: any): void => {
    const newValue = pvalue.value !== undefined ? pvalue.value : '';
    const validationError =
      validators.stringValidator(iValidator as string, newValue) ||
      (iRequired && validators.isEmpty(newValue));
    setValue(newValue);
    setIsNewScreen(false);
    boarding[iComponent].setProp(name, newValue, iMetadata);
    boarding[iComponent].setError(name, validationError, iMetadata);
  };

  const maskValidator = (validation: string): any => {
    switch (validation) {
      case 'alpha':
        return /^[a-zA-Z\s]*$/;
      case 'numbers':
        return Number;
      case 'float':
        return Number;
      case 'alphanumeric':
        return /^[a-z0-9]+$/i;
      case 'alphanumericspaces':
        return /^[a-zA-Z0-9 ]+$/i;
      case 'text':
        return String;
      case 'email':
        return /^([a-zA-Z0-9][-._]?)+@?([a-zA-Z0-9][-._]?)*(\.)?([a-zA-Z]*)$/;
      case 'phone':
        return '(000) 000-0000';
      case 'routing':
        return '000000000';
      case 'accountNumber':
        return /^[0-9]+$/i;
      case 'cardDate':
        return '00/00';
      case 'cvv':
        return '000';
      case 'cvvamex':
        return '0000';
      case 'zipcode':
        return /^(?=.{0,6}$)([A-Za-z0-9])+$/g;
      case 'creditCard':
        return '0000 0000 0000 0000';
      case 'creditCardAmex':
        return '0000 000000 00000';
      case 'ein':
        return '00 0000000';
      case 'ssn':
        return '000000000';
      default:
        return String;
    }
  };

  const builderInput = (): any => {
    const validationError =
      boarding[iComponent].getError(iStoreName, iMetadata) === true;
    const { nextClicked } = boarding;
    return (
      <div
        className={
          (validationError && !isNewScreen) || (validationError && nextClicked)
            ? 'ui fluid input error'
            : 'ui fluid input'
        }
      >
        {iToolTip && (
          <Popup
            trigger={<BiInfoCircle className="info-icon masked-in-input" />}
          >
            {iToolTip}
          </Popup>
        )}
        <IMaskInput
          mask={iMask ? maskValidator(iMask) : String}
          name={iName}
          value={value ? String(value) : ''}
          autoComplete="off"
          defaultValue=""
          type={iType}
          unmask={true}
          readOnly={iReadonly}
          onAccept={(event, valueAccept): void =>
            handleChange(iStoreName, event, valueAccept)
          }
          placeholder={iTitle + (iRequired ? ' *' : '')}
          className="field__input"
          id={iName}
        />
      </div>
    );
  };

  return (
    <div className={`field ${iName}`} ref={fieldRef}>
      <label className="field__label" htmlFor={iName}>
        {iTitle} {iRequired ? ' *' : ''}
      </label>
      {builderInput()}
    </div>
  );
};

export default inject('boarding')(observer(MaskedInput));
