import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import NumberFormat from 'react-number-format';
import { Popup } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

interface MoneyInputProps {
  iValue?: string;
  iComponent: string;
  iStoreName: string;
  iMetadata?: any;
  iRequired?: boolean;
  iToolTip?: string;
  iReadonly?: boolean;
  iType?: string;
  iTitle: string;
  iName: string;
  iHide?: boolean;
  maxValue?: number;
  boarding?: any;
}

const MoneyInput: React.FC<MoneyInputProps> = ({
  iValue = '',
  iComponent,
  iStoreName,
  iMetadata,
  iRequired = false,
  iToolTip,
  iReadonly = false,
  iTitle,
  iName,
  iHide = false,
  maxValue = 0,
  boarding,
}) => {
  const [value, setValue] = useState<string>(iValue);
  const [isNewScreen, setIsNewScreen] = useState<boolean>(true);
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const validationError = iRequired && validators.isEmpty(iValue);
    boarding[iComponent].setProp(iStoreName, value, iMetadata);
    boarding[iComponent].setError(iStoreName, validationError, iMetadata);
  }, [value]);

  const handleChange = (name: string, valueChange: string): void => {
    setValue(valueChange);
    setIsNewScreen(false);
  };

  const builderInput = (): any => {
    const validationError =
      boarding[iComponent].getError(iStoreName, iMetadata) === true;
    const { nextClicked } = boarding;
    return (
      <div
        className={
          (validationError && !isNewScreen) || (validationError && nextClicked)
            ? 'ui fluid input error'
            : 'ui fluid input'
        }
      >
        {iToolTip && (
          <Popup
            trigger={<BiInfoCircle className="info-icon masked-in-input" />}
          >
            {iToolTip}
          </Popup>
        )}
        <NumberFormat
          onFocus={(e: any): void => {
            e.target.focus();
            e.target.setSelectionRange(0, 1000000000);
          }}
          thousandsGroupStyle="thousand"
          prefix="$"
          isAllowed={({ floatValue }: any): boolean =>
            maxValue > 0 ? floatValue <= maxValue : true
          }
          decimalSeparator="."
          displayType="input"
          type={iHide ? 'password' : 'text'}
          thousandSeparator={true}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          autoComplete="off"
          readOnly={iReadonly}
          className="field__input"
          id={iName}
          name={iName}
          value={value ? String(value) : '0'}
          placeholder={iTitle + (iRequired ? ' *' : '')}
          onValueChange={(values: any): void =>
            handleChange(iStoreName, values.value)
          }
        />
      </div>
    );
  };

  return (
    <div className={`field ${iName} field--not-empty`} ref={fieldRef}>
      <label className="field__label" htmlFor={iName}>
        {iTitle} {iRequired ? ' *' : ''}
      </label>
      {builderInput()}
    </div>
  );
};

export default inject('boarding')(observer(MoneyInput));
