import { countryTuples } from 'country-region-data';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

class CountrySelectInput extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: '',
      name: '',
      component: '',
      error: false,
      isNewScreen: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name: string, event: any, info: any): void {
    const error = this.props.iRequired && validators.isEmpty(info.value);
    this.setState(
      {
        value: info.value,
        isNewScreen: false,
        error,
      },
      (): void => {
        const field = document.getElementsByClassName(this.props.iName)[0];
        if (this.state.value) {
          field.classList.add('field--not-empty');
        } else {
          field.classList.remove('field--not-empty');
        }
        this.props.boarding[this.props.iComponent].setProp(
          name,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          name,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  }

  componentDidMount(): void {
    const error = this.props.iRequired && validators.isEmpty(this.props.iValue);
    this.setState(
      {
        component: this.props.iComponent,
        value: this.props.iValue,
        name: this.props.iName,
        error,
      },
      (): void => {
        const field = document.getElementsByClassName(this.props.iName)[0];
        if (this.state.value) {
          field.classList.add('field--not-empty');
        } else {
          field.classList.remove('field--not-empty');
        }
        this.props.boarding[this.props.iComponent].setProp(
          this.props.iStoreName,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          this.props.iStoreName,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const error = this.props.iRequired && validators.isEmpty(this.props.iValue);
    if (prevProps.iValue !== this.props.iValue) {
      this.setState({ value: this.props.iValue, error }, () => {
        this.props.boarding[this.props.iComponent].setError(
          this.props.iStoreName,
          this.state.error,
          this.props.iMetadata
        );
      });
    }
  }

  render(): React.ReactNode {
    const error =
      this.props.boarding[this.props.iComponent].getError(
        this.props.iName,
        this.props.iMetadata
      ) === true;
    const { nextClicked } = this.props.boarding;
    const options = countryTuples;
    const dropDownOptions = options.map((e: any): any => {
      return { key: e[1].toLowerCase(), value: e[1], text: e[0] };
    });
    return (
      <div className="">
        <div className={`ui fluid input field ${this.props.iName}`}>
          <label className="field__label" htmlFor={this.props.iName}>
            {this.props.iTitle} {this.props.iRequired === true ? ' *' : ''}
          </label>
          <Dropdown
            error={
              (error && !this.state.isNewScreen) ||
              (this.state.error && nextClicked)
            }
            placeholder={`Select ${this.props.iTitle}${
              this.props.iRequired === true ? ' *' : ''
            }`}
            selectOnNavigation={false}
            fluid
            search
            selection
            clearable
            options={dropDownOptions}
            disabled={this.props.iDisabled || this.props.iReadonly}
            name={this.props.iStoreName}
            id={this.props.iStoreName}
            onChange={(event: any, info: any): void =>
              this.handleChange(this.props.iStoreName, event, info)
            }
            value={this.state.value ? String(this.state.value) : ''}
          />
          {/* <select className='' disabled={this.props.iDisabled} name={this.props.iName} id={this.props.iName} value={this.state.value ? String(this.state.value) : 'US'} onChange={(event: any): void => this.handleChange(this.props.iName, event)}>
              {(options && options.length > 0) &&
                options.map((record: any, index: number) => (
                  <option value={record[1]} key={index}>{record[0]}</option>
                ))
              }
            </select> */}
          {/* <p>{this.props.iTitle} {this.props.iRequired === true ? ' *' : ''}</p> */}
        </div>
      </div>
    );
  }
}

export default inject('boarding')(observer(CountrySelectInput));
