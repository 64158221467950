import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Icon, Message } from 'semantic-ui-react';

class LoginBoarding extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      referenceId: '',
      error: '',
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  componentDidMount(): void {
    if (this.props.error) {
      this.setState({
        error: this.props.error,
      });
    }
  }

  handleTextChange(event: any): void {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const field = document.getElementsByClassName(event.target.name)[0];
      if (event.target.value) {
        field.classList.add('field--not-empty');
      } else {
        field.classList.remove('field--not-empty');
      }
    });
  }

  handleKeyDown(e: any): void {
    if (e.key === 'Enter') {
      this.props.getApplicationFromApi(
        this.state.email,
        this.state.referenceId
      );
    }
  }

  render(): React.ReactNode {
    return (
      <div className="mt-body3" data-testid="login-boarding-container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="card-login mb-3" style={{ width: '35em' }}>
            <p className="grey mb-4">
              Sign in to get access to your application{' '}
            </p>
            <div className={'field email mb-3'}>
              <div className="ui fluid input">
                <label className="field__label" htmlFor="email">
                  Email
                </label>
                <input
                  onKeyDown={(e: any): void => this.handleKeyDown(e)}
                  type="text"
                  value={this.state.email}
                  name="email"
                  className={
                    this.state.error ? 'field__input error' : 'field__input'
                  }
                  placeholder="Email"
                  onChange={(e: any): void => this.handleTextChange(e)}
                />
              </div>
            </div>
            <div className={'field referenceId mb-3'}>
              <div className="ui fluid input">
                <label className="field__label" htmlFor="referenceId">
                  Reference Id
                </label>
                <input
                  onKeyDown={(e: any): void => this.handleKeyDown(e)}
                  type="text"
                  value={this.state.referenceId}
                  name="referenceId"
                  className={
                    this.state.error ? 'field__input error' : 'field__input'
                  }
                  placeholder="Reference Id"
                  onChange={(e: any): void => this.handleTextChange(e)}
                />
              </div>
            </div>
            {this.state.error && (
              <Message negative size="mini">
                <p>
                  <Icon name="warning" size="large" />
                  {this.state.error}
                </p>
              </Message>
            )}

            <Button
              primary
              size="huge"
              fluid
              style={{ textTransform: 'uppercase' }}
              onClick={(): void =>
                this.props.getApplicationFromApi(
                  this.state.email,
                  this.state.referenceId
                )
              }
            >
              Validate
            </Button>
          </div>
          <p className="small-small">
            Powered by{' '}
            <a href="/" className="small no-underline">
              Payabli
            </a>
          </p>
        </div>
      </div>
    );
  }
}
export default inject('boarding')(observer(LoginBoarding));
