import '../../assets/css/signature.css';

import { inject, observer } from 'mobx-react';
import React from 'react';
import SignaturePad from 'react-signature-canvas';
import { Button, Icon } from 'semantic-ui-react';

class SignInput extends React.Component<any, any> {
  sigPad: any = {};

  colors: Array<string> = ['black', 'blue', 'red'];

  constructor(props: any) {
    super(props);
    this.state = {
      value: '',
      penColor: 'black',
      name: '',
    };
  }

  clear(): void {
    this.setState(
      {
        value: null,
      },
      (): void => {
        this.props.boarding?.termsCondition?.clearTerms();
        this.sigPad.clear();
        const existAttachemt =
          this.props.boarding.attachments.getByType('signature');
        if (existAttachemt >= 0) {
          this.props.boarding.attachments.remove(existAttachemt);
        }
      }
    );
  }

  trim(): void {
    this.setState(
      {
        value: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
      },
      (): void => {
        this.props.boarding?.termsCondition?.signTerms(this.state.value);
        const existAttachemt =
          this.props.boarding.attachments.getByType('signature');
        if (existAttachemt >= 0) {
          this.props.boarding.attachments.remove(existAttachemt);
          this.props.boarding.attachments.addAttachment(
            'image/png',
            'signature',
            JSON.stringify({ type: 'signature' }),
            '',
            this.state.value
          );
        } else {
          this.props.boarding.attachments.addAttachment(
            'image/png',
            'signature',
            JSON.stringify({ type: 'signature' }),
            '',
            this.state.value
          );
        }
      }
    );
  }

  componentDidMount(): void {
    const signature = this.props.boarding?.termsCondition?.signature;
    this.setState({
      value: signature || null,
    });
  }

  setPenColor(color: string): void {
    this.setState({
      penColor: color,
    });
  }

  builderInput(): React.ReactElement {
    // let { trimmedDataURL } = this.state
    return (
      <>
        <div className="container-sign">
          <div className="sigContainer">
            <SignaturePad
              penColor={this.state.penColor}
              canvasProps={{ className: 'signatureCanvas' }}
              ref={(ref): void => {
                this.sigPad = ref;
              }}
            />
          </div>
          <div className="sigPad__penColors">
            {this.colors.map((color) => (
              <span
                key={color}
                style={{
                  backgroundColor: color,
                  border: `${
                    color === this.state.penColor ? `2px solid ${color}` : ''
                  }`,
                }}
                onClick={(): void => this.setPenColor(color)}
              ></span>
            ))}
          </div>
          {/* {trimmedDataURL
        ? <img className='sigImage'
          src={trimmedDataURL} />
        : null} */}
        </div>
        <div className="buttons-action">
          <Button color="green" onClick={(): void => this.trim()}>
            <Icon name="checkmark" /> Save
          </Button>
          <Button color="red" onClick={(): void => this.clear()}>
            <Icon name="remove" /> Clear
          </Button>
        </div>
      </>
    );
  }

  render(): React.ReactNode {
    return (
      <>
        {this.builderInput()}
        <p>
          {this.props.iTitle} {this.props.iRequired === true ? ' *' : ''}
        </p>
      </>
    );
  }
}

export default inject('boarding')(observer(SignInput));
