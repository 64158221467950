import { action, makeObservable, observable } from 'mobx';

import type { IElement } from '../interfaces/IElement';
/* A generic class that can be used to create a store for any type of element. */
abstract class AbstractContainerStore<T extends IElement> {
  elementsList: Array<T> = new Array<T>();

  constructor() {
    makeObservable(this, {
      elementsList: observable,
      add: action,
      remove: action,
    });
  }

  /* Filtering the elementsList array and returning a new array with the element that has the id that
  is passed in removed. */
  remove(id: number): void {
    this.elementsList = this.elementsList.filter(
      (element) => element.id !== id
    );
  }

  /**
   * It adds an element to the elementsList array
   * @param {T} element - The element to be added to the list.
   */
  add(element: T): void {
    this.elementsList.push(element);
  }

  /**
   * It returns the first element in the elementsList array that has an id property that matches the id
   * argument
   * @param {number} id - number - the id of the element we want to get
   * @returns The element with the id that matches the id passed in.
   */
  get(id: number): T | undefined {
    return this.elementsList.find((element) => element.id === id);
  }

  /**
   * It takes a field name and a metadata object, and returns the value of the field in the first
   * element of the elementsList array
   * @param {K} field - The field name of the element you want to get the value of.
   * @param metadata - Record<string, string | Object>
   * @returns The value of the field of the element at the index specified in the metadata.
   */
  getValue<K extends keyof IElement>(
    field: K,
    metadata: Record<string, string | Object>
  ): any {
    const index = metadata?.index || 0;
    return this.elementsList[+index][field];
  }

  /**
   * It returns the next id for a new element in the list
   * @returns The next id that can be used for a new element.
   */
  getNextId(): number {
    return this.elementsList.length === 0
      ? 0
      : Math.max(
          ...this.elementsList.map((element: T) => {
            return element.id;
          })
        ) + 1;
  }
}
export default AbstractContainerStore;
