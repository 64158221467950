import { inject, observer } from 'mobx-react';
import React from 'react';

import payabli from '../assets/images/payabli.png';

class PageNotFound extends React.Component<any, any> {
  render(): React.ReactNode {
    return (
      <>
        <div className="root-page">
          <div
            style={{
              height: '100%',
              position: 'absolute',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '40em', textAlign: 'center' }}>
              <img alt="" src={payabli} className="mb-4" />
              <p className="small">
                <b>404</b> Page not found.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default inject('boarding')(observer(PageNotFound));
