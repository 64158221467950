import { inject, observer } from 'mobx-react';
import React from 'react';
import { GrLocation } from 'react-icons/gr';
// import { Input } from 'semantic-ui-react';
import { IMaskInput } from 'react-imask';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { Loader } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

class LocationSearchInput extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      address: '',
      name: '',
      component: '',
      error: false,
      isNewScreen: true,
    };
  }

  componentDidMount(): void {
    const error =
      validators.stringValidator(this.props.iValidator, this.props.iValue) ||
      (this.props.iRequired && validators.isEmpty(this.props.iValue));
    this.setState(
      {
        component: this.props.iComponent,
        address: this.props.iValue,
        name: this.props.iStoreName,
        error,
      },
      (): void => {
        this.props.boarding[this.props.iComponent].setProp(
          `${this.props.identifyBy}address`,
          this.state.address,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          this.state.name,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const error = this.props.iRequired && validators.isEmpty(this.props.iValue);
    if (this.props.iValue !== prevProps.iValue) {
      this.setState(
        {
          address: this.props.iValue,
          isNewScreen: false,
          error,
        },
        () => {
          this.props.boarding[this.props.iComponent].setProp(
            `${this.props.identifyBy}address`,
            this.props.iValue,
            this.props.iMetadata
          );
          this.props.boarding[this.props.iComponent].setError(
            this.state.name,
            this.state.error,
            this.props.iMetadata
          );
        }
      );
    }
  }

  handleChange = (address: any): any => {
    const error = this.props.iRequired && validators.isEmpty(address);
    this.setState(
      {
        address,
        isNewScreen: false,
        error,
      },
      () => {
        const field = document.getElementsByClassName(this.props.iName)[0];
        if (this.state.address) {
          field.classList.add('field--not-empty');
        } else {
          field.classList.remove('field--not-empty');
        }
        this.props.boarding[this.props.iComponent].setProp(
          `${this.props.identifyBy}address`,
          this.state.address,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          `${this.props.identifyBy}address`,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  };

  handleSelect = (address: any): any => {
    // const error = (this.props.iRequired && validators.isEmpty(address));
    geocodeByAddress(address).then((results: any) => {
      if (results[0] && results[0].address_components) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { address_components } = results[0];
        let address2 = '';
        let city = '';
        let state = '';
        let zipcode = '';
        let country = '';
        let nameField = '';

        address_components.forEach((item: any) => {
          if (item.types[0] === 'subpremise') {
            address2 += ` ${item.short_name}`;
          }
          if (item.types[0] === 'street_number') {
            nameField += ` ${item.short_name}`;
          }
          if (item.types[0] === 'route') {
            nameField += ` ${item.short_name}`;
          }
          if (item.types[0] === 'locality') {
            city = item.short_name;
          }
          if (item.types[0] === 'administrative_area_level_1') {
            state = item.short_name;
          }
          if (item.types[0] === 'administrative_area_level_2') {
            address2 += ` ${item.short_name}`;
          }
          if (item.types[0] === 'postal_code') {
            zipcode = item.short_name;
          }
          if (item.types[0] === 'country') {
            country = item.short_name;
          }
        });
        const nameAddress = nameField
        this.setState(
          {
            address: nameAddress,
            city,
            address1: address2,
            state,
            zip: zipcode,
            country,
          },
          () => {
            this.props.locationFields.map((field: any) => {
              this.props.boarding[this.props.iComponent].setProp(
                this.props.identifyBy + field,
                this.state[field],
                this.props.iMetadata
              );
              this.props.boarding[this.props.iComponent].setError(
                this.props.identifyBy + field,
                false,
                this.props.iMetadata
              );
            });
          }
        );
      }
    });
  };

  render(): React.ReactNode {
    const error =
      this.props.boarding[this.props.iComponent].getError(
        this.props.iStoreName,
        this.props.iMetadata
      ) === true;
    const { nextClicked } = this.props.boarding;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any): any => (
          <div>
            <div
              className={
                (error && !this.state.isNewScreen) || (error && nextClicked)
                  ? `error ui fluid input field ${this.props.iName}`
                  : `ui fluid input field ${this.props.iName}`
              }
            >
              <label className="field__label" htmlFor={this.props.iName}>
                {this.props.iTitle} {this.props.iRequired === true ? ' *' : ''}
              </label>
              <IMaskInput
                {...getInputProps({
                  placeholder:
                    this.props.iTitle +
                    (this.props.iRequired === true ? ' *' : ''),
                  className: 'location-search-input field__input',
                })}
                mask={String}
                name={this.props.iName}
                value={this.state.address ? String(this.state.address) : ''}
                autoComplete="off"
                defaultValue=""
                readOnly={this.props.iReadonly}
                type="text"
                unmask={true}
                id={this.props.iName}
              />
            </div>

            {suggestions.length > 0 && (
              <div className="places-autocomplete-dropdown-container">
                {loading && <Loader />}
                {suggestions.map((suggestion: any, index: number) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                      };
                  return (
                    <div
                      key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>
                        <GrLocation /> {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
export default inject('boarding')(observer(LocationSearchInput));
