import { getMCC } from './mccData';

/**
 * It returns an array of objects that are used to populate a dropdown list
 * @param {string} stype - This is the type of options you want to get.
 * @returns An array of objects.
 */
export const getOptionsType = (stype: string): Array<any> => {
  if (stype === 'btype') {
    return [
      {
        key: 'Limited Liability Company',
        value: 'Limited Liability Company',
        text: 'Limited Liability Company',
      },
      {
        key: 'Non-Profit Org',
        value: 'Non-Profit Org',
        text: 'Non-Profit Org',
      },
      { key: 'Partnership', value: 'Partnership', text: 'Partnership' },
      {
        key: 'Private Corp',
        value: 'Private Corp',
        text: 'Private Corp',
      },
      { key: 'Public Corp', value: 'Public Corp', text: 'Public Corp' },
      { key: 'Tax Exempt', value: 'Tax Exempt', text: 'Tax Exempt' },
      { key: 'Government', value: 'Government', text: 'Government' },
      {
        key: 'Sole Proprietor',
        value: 'Sole Proprietor',
        text: 'Sole Proprietor',
      },
    ];
  }
  if (stype === 'whencharged') {
    return [
      {
        key: 'When Service Provided',
        value: 'When Service Provided',
        text: 'When Service Provided',
      },
      { key: 'In Advance', value: 'In Advance', text: 'In Advance' },
    ];
  }
  if (stype === 'whenprovided') {
    return [
      {
        key: '30 Days or Less',
        value: '30 Days or Less',
        text: '30 Days or Less',
      },
      {
        key: '31 to 60 Days',
        value: '31 to 60 Days',
        text: '31 to 60 Days',
      },
      { key: '60+ Days', value: '60+ Days', text: '60+ Days' },
    ];
  }
  if (stype === 'whendelivered') {
    return [
      { key: '0-7 Days', value: '0-7 Days', text: '0-7 Days' },
      { key: '8-14 Days', value: '8-14 Days', text: '8-14 Days' },
      { key: '15-30 Days', value: '15-30 Days', text: '15-30 Days' },
      {
        key: 'Over 30 Days',
        value: 'Over 30 Days',
        text: 'Over 30 Days',
      },
    ];
  }
  if (stype === 'whenrefunded') {
    return [
      {
        key: 'Exchange Only',
        value: 'Exchange Only',
        text: 'Exchange Only',
      },
      {
        key: 'No Refund or Exchange',
        value: 'No Refund or Exchange',
        text: 'No Refund or Exchange',
      },
      {
        key: '30 Days or Less',
        value: '30 Days or Less',
        text: '30 Days or Less',
      },
      {
        key: 'More than 30 days',
        value: 'More than 30 days',
        text: 'More than 30 days',
      },
    ];
  }
  if (stype === 'card-pricing') {
    return [
      { key: '1', value: '1', text: 'IC Plus' },
      { key: '2', value: '2', text: 'Flat Rate' },
      { key: '3', value: '3', text: 'Pass-Through' },
      { key: '4', value: '4', text: 'Flat Rate + Pass-Through' },
    ];
  }
  if (stype === 'ach-pricing') {
    return [
      { key: '5', value: '5', text: 'Absorb' },
      { key: '3', value: '3', text: 'Pass-Through' },
      { key: '6', value: '6', text: 'Both' },
    ];
  }
  if (stype === 'bank-type') {
    return [
      { key: 'Checking', value: 'Checking', text: 'Checking' },
      { key: 'Savings', value: 'Savings', text: 'Savings' },
    ];
  }
  if (stype === 'discount-freq') {
    return [
      { key: 'Monthly', value: 'Monthly', text: 'Monthly' },
      { key: 'Daily', value: 'Daily', text: 'Daily' },
    ];
  }
  if (stype === 'funding-type') {
    return [
      {
        key: 'Separate Fees and Deposits',
        value: 'Separate Fees and Deposits',
        text: 'Separate Fees and Deposits',
      },
      {
        key: 'Net Fees and Deposits',
        value: 'Net Fees and Deposits',
        text: 'Net Fees and Deposits',
      },
      {
        key: 'Individual Batches',
        value: 'Individual Batches',
        text: 'Individual Batches',
      },
    ];
  }
  if (stype === 'mcc') {
    return getMCC();
  }
  return [];
};
