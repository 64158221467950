import { action, computed, makeObservable } from 'mobx';

import type { IAttachment } from '../interfaces/IAttachment';
import { STATUS } from '../interfaces/IAttachment';
import AbstractContainerStore from './AbstractContainerStore';

class AttachmentStore extends AbstractContainerStore<IAttachment> {
  constructor() {
    super();
    makeObservable(this, {
      setProp: action,
      addAttachment: action,
      setError: action,
      removeAttachment: action,
      totalSize: computed,
    });
    // this.addAttachment();
  }

  addAttachment(
    ftype: string = '',
    filename: string = '',
    fileDescriptor: string = '{}',
    furl: string = '',
    fContent: string = '',
    fsize: number = 0,
    status: STATUS = STATUS.new,
    zipName: string = ''
  ): void {
    const attachment: IAttachment = {
      id: this.getNextId(),
      ftype,
      filename,
      fileDescriptor,
      furl,
      fContent,
      fsize,
      errors: {},
      status,
      zipName,
    };
    this.add(attachment);
  }

  removeAttachment(attachmentId: number): void {
    if (
      this.elementsList[+attachmentId].status === STATUS.new ||
      this.elementsList[+attachmentId].status === STATUS.toDelete
    ) {
      this.remove(attachmentId);
    } else {
      this.elementsList[+attachmentId].status = STATUS.deleted;
      this.elementsList[+attachmentId].ftype = 'remove';
      this.elementsList[+attachmentId].filename =
        this.elementsList[+attachmentId].zipName;
    }
  }

  /**
   * It returns the index of the first element in the elementsList array that has a fileDescriptor
   * property that is a JSON object with a type property that matches the type parameter
   * @param {string} type - The type of the element you want to get the index of.
   * @returns The index of the element in the elementsList array that has a fileDescriptor property
   * that is a JSON object with a type property that matches the type parameter.
   */
  getByType(type: string): number {
    return this.elementsList.findIndex(
      (element) =>
        JSON.parse(
          element.fileDescriptor === '' ? '{}' : element.fileDescriptor
        ).type === type
    );
  }

  get totalSize(): number {
    return this.elementsList.reduce(
      (totalSize, element) => totalSize + element.fsize,
      0
    );
  }

  setPushed(): void {
    this.elementsList
      .filter((notDeleted) => notDeleted.status !== STATUS.deleted)
      .map((element) => (element.status = STATUS.pushed));
  }

  /**
   * It sets a property on an object in an array.
   * @param {K} name - The name of the property to set.
   * @param {V} value - The value to set the property to.
   * @param metadata - Record<string, string | Object>
   */
  setProp<K extends keyof IAttachment, V extends IAttachment[K]>(
    name: K,
    value: V,
    metadata: Record<string, string | Object>
  ): void {
    const index = metadata?.index || 0;
    this.elementsList[+index][name] = value;
    if (this.elementsList[+index].status === STATUS.pushed) {
      this.elementsList[+index].status = STATUS.updated;
    }
  }

  /**
   * It sets the error of the element at the given index.
   * @param {K} name - The name of the property that is being set.
   * @param {V} value - The value of the field.
   * @param metadata - {
   */
  setError<K extends keyof IAttachment, V extends IAttachment[K]>(
    name: K,
    value: V,
    metadata: Record<string, string | Object>
  ): void {
    const index = metadata.index || 0;
    this.elementsList[+index].errors[name] = value;
  }

  /**
   * It takes an array of objects, and returns an array of objects with some of the properties removed
   * @returns An array of objects with the keys 'id' and 'errors' removed.
   */
  getJson(): any {
    let attachmentsJson = this.elementsList
      .filter((element: any) =>
        [STATUS.new, STATUS.updated, STATUS.deleted].includes(element.status)
      )
      .reduce((acc: Array<any>, el: any): any => {
        const attachment = Object.fromEntries(Object.entries(el || []));
        delete attachment.id;
        delete attachment.errors;
        delete attachment.fsize;
        delete attachment.status;
        return acc.concat(attachment);
      }, []);
    if (attachmentsJson.length === 1) {
      const attachment = Object.values(attachmentsJson[0]).filter(
        (element: any) => !['', '{}'].includes(element)
      );
      if (attachment.length === 0) attachmentsJson = [];
    }
    return {
      attachments: attachmentsJson,
    };
  }
}

export default AttachmentStore;
