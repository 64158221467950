import { action, makeObservable, observable } from 'mobx';

import type { IContact } from '../interfaces/IContact';
import { OWNER_STATUS } from '../interfaces/IOwner';
import AbstractContainerStore from './AbstractContainerStore';

class ContactStore extends AbstractContainerStore<IContact> {
  contacts: IContact[];

  constructor() {
    super();
    this.contacts = this.elementsList;
    makeObservable(this, {
      setProp: action,
      addContact: action,
      setError: action,
      contacts: observable,
    });
    // this.addContact();
  }

  addContact(
    contactName: string = '',
    contactEmail: string = '',
    contactTitle: string = '',
    contactPhone: string = '',
    status: OWNER_STATUS = OWNER_STATUS.new
  ): void {
    const contact: IContact = {
      id: this.getNextId(),
      contactName,
      contactEmail,
      contactTitle,
      contactPhone,
      errors: {},
      status,
    };
    this.add(contact);
  }

  /**
   * It takes a key of type K, which is a key of the interface IContact, and a value of type V, which
   * is a value of the interface IContact[K], and a metadata object, and sets the value of the key of
   * the metadata object to the value of the value of the metadata object
   * @param {K} name - The name of the property to set.
   * @param {V} value - The value to set the property to.
   * @param metadata - Record<string, string | Object>
   */
  setProp<K extends keyof IContact, V extends IContact[K]>(
    name: K,
    value: V,
    metadata: Record<string, string | Object>
  ): void {
    const index = metadata.index || 0;
    this.elementsList[+index][name] = value;
  }

  setPosted(): void {
    // eslint-disable-next-line no-return-assign
    this.elementsList.map((element) => (element.status = OWNER_STATUS.posted));
  }

  /**
   * It takes a key of type K, which is a key of the IContact interface, and a value of type boolean,
   * and a metadata object, and sets the value of the errors object at the key of type K to the value
   * of type boolean
   * @param {K} name - The name of the field that has an error.
   * @param {boolean} value - The value of the field.
   * @param metadata - {
   */
  setError<K extends keyof IContact>(
    name: K,
    value: boolean,
    metadata: Record<string, string | Object>
  ): void {
    const index = metadata.index || 0;
    this.elementsList[+index].errors[name] = value;
  }

  /**
   * It takes a key of type K, which is a key of the interface IContact, and a metadata object, and
   * returns a boolean
   * @param {K} name - The name of the field to check for errors.
   * @param metadata - Record<string, string | Object>
   * @returns A boolean value.
   */
  getError<K extends keyof IContact>(
    name: K,
    metadata: Record<string, string | Object>
  ): boolean {
    const index = metadata.index || 0;
    return this.elementsList[+index].errors[name];
  }

  /**
   * It takes an array of objects, and returns an array of objects with the same properties, but
   * without the properties 'id' and 'errors'
   * @returns An array of objects.
   */
  getJson(): any {
    const contacts = this.elementsList.reduce(
      (acc: Array<any>, el: any): any => {
        const contact = Object.fromEntries(Object.entries(el || []));
        delete contact.id;
        delete contact.errors;
        delete contact.status;
        return acc.concat(contact);
      },
      []
    );
    return {
      contacts,
    };
  }
}
export default ContactStore;
