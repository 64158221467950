import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Transition } from 'semantic-ui-react';

import { renderInput } from '../utils/input-tools';
import { getOptionsType } from '../utils/optionsTypes';

class Processing extends React.Component<any, any> {
  processingData: Array<any> = new Array<any>();

  processingIndex: number = 0;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    this.processingData = props.processingData;
  }

  componentDidMount(): void {
    this.setState({
      visible: true,
    });
  }

  render(): React.ReactNode {
    return (
      <div key="processing-div-tab">
        <Transition
          key="processing-tranns-tab"
          duration={400}
          visible={this.state?.visible}
        >
          <Grid key="processing-grid-tab" doubling columns={2}>
            {Object.entries(this.processingData || []).map(
              (processing: any, index: number): any => {
                const inputName = processing[0];
                const processingItem = this.processingData[inputName];
                if (inputName === undefined || inputName === '') return <></>;
                if (processingItem === undefined || processingItem === '')
                  return <></>;
                const fieldMetadata = {
                  name: inputName,
                  storeName: inputName,
                  label: processingItem?.label,
                  index,
                  toolTip: processingItem?.toolTip,
                  required: processingItem?.required,
                  value:
                    processingItem?.value ||
                    this.props.boarding.processing[inputName],
                  boarding: this.props.boarding,
                  identifyField: inputName[0],
                  metadata: {
                    options: getOptionsType(processingItem?.stype),
                  },
                  component: 'processing',
                };
                return processing[1]?.visible ? (
                  <Grid.Column
                    key={`processing-input-tab${index}`}
                    floated={index === 0 || index % 2 === 0 ? 'left' : 'right'}
                    width={8}
                  >
                    {renderInput(processingItem.type, fieldMetadata)}
                  </Grid.Column>
                ) : (
                  this.props.boarding[fieldMetadata.component].setProp(
                    inputName,
                    fieldMetadata.value,
                    fieldMetadata.metadata
                  )
                );
              }
            )}
          </Grid>
        </Transition>
      </div>
    );
  }
}

export default inject('boarding')(observer(Processing));
