/**
 * It takes a string in the format of "YYYY-MM-DD" and returns a string in the format of "Month DD,
 * YYYY"
 * @param {string} dateString - The date string that you want to format.
 * @returns A string with the date in the format of "Month Day, Year"
 */
export const stringDateFormat = (dateString: string): any => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(dateString);
  return `${monthNames[date.getMonth()]} ${
    date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
  }, ${date.getFullYear()}`;
};

/**
 * It returns an array of strings, each of which is a month of the year
 * @returns An array of strings.
 */
export const getMonths = (): Array<string> => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
};
/**
 * It takes a date in the format of a string or a Date object and returns a Date object
 * @param {any} pdate - The date value to be converted to a date object.
 * @returns A date object
 */
export const getDateFromValue = (pdate: any): any => {
  let date = pdate;
  if (typeof pdate === 'object') {
    date = pdate.toString();
  }
  let newDate = new Date();
  if (date !== null && date.length > 0) {
    const f = date.split('T');
    const strDate = f[0].split('-');
    if (strDate.length === 3) {
      newDate = new Date(+strDate[0], +strDate[1] - 1, +strDate[2]);
    }
    return newDate;
  }
  return null;
};
