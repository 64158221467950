import { inject, observer } from 'mobx-react';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

class SelectionInput extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: '',
      name: '',
      component: '',
      error: false,
      isNewScreen: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name: string, event: any, info: any): void {
    if (name === 'btype')
      this.props.boarding.setValidOwner(
        !/Non-Profit Org|Government/.test(info.value)
      );
    const error = this.props.iRequired && validators.isEmpty(info.value);
    this.setState(
      {
        value: info.value,
        isNewScreen: false,
        error,
      },
      (): void => {
        const field = document.getElementsByClassName(this.props.iName)[0];
        if (this.state.value) {
          field.classList.add('field--not-empty');
        } else {
          field.classList.remove('field--not-empty');
        }
        this.props.boarding[this.props.iComponent].setProp(
          name,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          name,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  }

  componentDidMount(): void {
    const error = this.props.iRequired && validators.isEmpty(this.props.iValue);
    this.setState(
      {
        error,
        component: this.props.iComponent,
        value: this.props.iValue,
        name: this.props.iStoreName,
      },
      (): void => {
        const field = document.getElementsByClassName(this.props.iName)[0];
        if (this.state.value) {
          field.classList.add('field--not-empty');
        } else {
          field.classList.remove('field--not-empty');
        }
        this.props.boarding[this.props.iComponent].setProp(
          this.state.name,
          this.state.value,
          this.props.iMetadata
        );
        this.props.boarding[this.props.iComponent].setError(
          this.props.iStoreName,
          this.state.error,
          this.props.iMetadata
        );
      }
    );
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const error = this.props.iRequired && validators.isEmpty(this.props.iValue);
    if (prevProps.iValue !== this.props.iValue) {
      this.setState({ value: this.props.iValue, error }, (): void => {
        this.props.boarding[this.props.iComponent].setError(
          this.props.iStoreName,
          this.state.error,
          this.props.iMetadata
        );
      });
    }
  }

  render(): React.ReactNode {
    const error =
      this.props.boarding[this.props.iComponent].getError(
        this.props.iStoreName,
        this.props.iMetadata
      ) === true;
    const { nextClicked } = this.props.boarding;
    const dropDownOptions = this.props.iMetadata?.options || [];
    return (
      <div className="">
        <div className={`ui fluid input field ${this.props.iName}`}>
          <label className="field__label" htmlFor={this.props.iName}>
            {this.props.iTitle} {this.props.iRequired === true ? ' *' : ''}
          </label>

          <Dropdown
            placeholder={`Select ${this.props.iTitle.slice(0, 27)}${
              this.props.iRequired === true ? ' *' : ''
            }`}
            selectOnNavigation={false}
            clearable
            error={
              (error && !this.state.isNewScreen) ||
              (this.state.error && nextClicked)
            }
            fluid
            selection
            options={dropDownOptions}
            disabled={this.props.iDisabled || this.props.iReadonly}
            name={this.props.iName}
            id={this.props.iName}
            onChange={(event: any, info: any): void =>
              this.handleChange(this.props.iStoreName, event, info)
            }
            value={this.state.value ? String(this.state.value) : ''}
          />
        </div>
      </div>
    );
  }
}

export default inject('boarding')(observer(SelectionInput));
