import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Transition } from 'semantic-ui-react';

import { OWNER_STATUS } from '../interfaces/IOwner';
import { renderInput } from '../utils/input-tools';

class OwnerContact extends React.Component<any, any> {
  contactData: Array<any> = new Array<any>();

  contactIndex: number = 0;

  contactInfo: any;

  contactBuilder: any;

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    this.contactData = props.contactData;
    this.contactIndex = props.contactIndex;
    this.contactBuilder = props.contactBuilder;
    this.contactInfo = props.contactInfo;
  }

  componentDidMount(): void {
    this.setState(
      {
        visible: true,
      },
      () => {
        Object.entries(this.contactBuilder || []).map(
          (contact: any, index: number): any => {
            const inputName = contact[0];
            const contactItem = this.contactData[0][inputName];
            const fieldMetadata = {
              name: `o_${this.contactInfo.id}_${inputName}`,
              storeName: inputName,
              label: contactItem?.label,
              index,
              required: contactItem?.required,
              value:
                this.contactInfo.status === OWNER_STATUS.new
                  ? this.contactBuilder[inputName]?.value
                  : this.contactInfo[inputName],
              boarding: this.props.boarding,
              component: 'contacts',
              metadata: { index: this.contactIndex },
            };
            if (!contact[1]?.visible) {
              this.props.boarding[fieldMetadata.component].setProp(
                inputName,
                fieldMetadata.value,
                fieldMetadata.metadata
              );
            }
          }
        );
      }
    );
  }

  render(): React.ReactNode {
    return (
      <>
        <Transition duration={400} visible={this.state?.visible}>
          <Grid doubling columns={2}>
            {Object.entries(this.contactBuilder || []).map(
              (contact: any, index: number): any => {
                const inputName = contact[0];
                const contactItem = this.contactData[0][inputName];
                if (inputName === undefined || inputName === '') {
                  return null;
                }
                if (contactItem === undefined || contactItem === '') {
                  return null;
                }
                const fieldMetadata = {
                  name: `o_${this.contactInfo.id}_${inputName}`,
                  storeName: inputName,
                  label: contactItem?.label,
                  index,
                  required: contactItem?.required,
                  value:
                    this.contactInfo.status === OWNER_STATUS.new
                      ? this.contactBuilder[inputName]?.value
                      : this.contactInfo[inputName],
                  boarding: this.props.boarding,
                  component: 'contacts',
                  metadata: { index: this.contactIndex },
                };
                return (
                  contact[1]?.visible && (
                    <Grid.Column
                      key={`grid-fileds${index}`}
                      floated={
                        index === 0 || index % 2 === 0 ? 'left' : 'right'
                      }
                      width={8}
                    >
                      {renderInput(contactItem.type, fieldMetadata)}
                    </Grid.Column>
                  )
                );
              }
            )}
          </Grid>
        </Transition>
      </>
    );
  }
}

export default inject('boarding')(observer(OwnerContact));
