import './index.css';
import './utils/axiosInterceptor';

import * as Sentry from '@sentry/react';
import promiseFinally from 'promise.prototype.finally';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

promiseFinally.shim();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const tracesSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || ''
);
const replaysSessionSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_REPLAY_SAMPLE_RATE || ''
);
const replaysOnErrorSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_ONERROR_SAMPLE_RATE || ''
);
const profilesSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_PROFILE_SAMPLE_RATE || ''
);
let masking = {};
if (process.env.REACT_APP_SENTRY_MASKING) {
  masking = {
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
  };
}
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      networkDetailAllowUrls: [
        window.location.origin,
        /^https:\/\/(?:\w+\.)?payabli\.com/,
      ],
      ...masking,
    }),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/(?:\w+\.)?payabli\.com/],
  // Performance Monitoring
  tracesSampleRate, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate,
});
const AppWrapper = (): React.ReactElement => {
  return (
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  );
};

root.render(AppWrapper());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
