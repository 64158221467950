import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import NumberFormat from 'react-number-format';
import { Popup } from 'semantic-ui-react';

import { validators } from '../../utils/input-tools';

interface PercentInputProps {
  iValue?: string;
  iComponent: string;
  iStoreName: string;
  iMetadata?: any;
  iRequired?: boolean;
  iToolTip?: string;
  iReadonly?: boolean;
  iTitle: string;
  iName: string;
  boarding?: any;
}

const PercentInput: React.FC<PercentInputProps> = ({
  iValue = '',
  iComponent,
  iStoreName,
  iMetadata,
  iRequired = false,
  iToolTip,
  iReadonly = false,
  iTitle,
  iName,
  boarding,
}) => {
  const [value, setValue] = useState<string>(iValue);
  const [error, setError] = useState<boolean>(false);
  const [isNewScreen, setIsNewScreen] = useState<boolean>(true);
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const validationError = iRequired && validators.isEmpty(iValue);
    setError(validationError);
    const percentError = boarding[iComponent]?.overflowPercent || false;
    boarding[iComponent].setProp(iStoreName, value, iMetadata);
    boarding[iComponent].setError(iStoreName, error || percentError, iMetadata);
  }, [value]);

  const handleChange = (name: string, valueChange: string): void => {
    if (parseFloat(valueChange) > 100) {
      valueChange = valueChange.substring(
        0,
        valueChange.split('.')[0].length - 1
      );
    }
    setValue(parseInt(valueChange).toString());
    setIsNewScreen(false);
  };

  const builderInput = (): any => {
    const validationError =
      boarding[iComponent].getError(iStoreName, iMetadata) === true;
    const { nextClicked } = boarding;
    return (
      <div
        className={
          (validationError && !isNewScreen) || (validationError && nextClicked)
            ? 'ui fluid input error'
            : 'ui fluid input'
        }
      >
        {iToolTip && (
          <Popup
            trigger={<BiInfoCircle className="info-icon masked-in-input" />}
          >
            {iToolTip}
          </Popup>
        )}
        <NumberFormat
          onFocus={(e: any): void => {
            e.target.focus();
            const percentError = boarding[iComponent]?.overflowPercent || false;
            boarding[iComponent].setError(
              iStoreName,
              error || percentError,
              iMetadata
            );
            e.target.setSelectionRange(0, 1000000000);
          }}
          thousandsGroupStyle="thousand"
          suffix="%"
          decimalSeparator="."
          displayType="input"
          type="text"
          thousandSeparator={false}
          allowNegative={false}
          decimalScale={0}
          fixedDecimalScale={false}
          readOnly={iReadonly}
          autoComplete="off"
          className="field__input"
          id={iName}
          name={iName}
          value={value ? String(value) : '0'}
          defaultValue={value ? String(value) : '0'}
          placeholder={iTitle + (iRequired ? ' *' : '')}
          onValueChange={(values: any): void =>
            handleChange(iStoreName, values.value)
          }
        />
      </div>
    );
  };

  return (
    <div className={`field ${iName} field--not-empty`} ref={fieldRef}>
      <label className="field__label" htmlFor={iName}>
        {iTitle} {iRequired ? ' *' : ''}
      </label>
      {builderInput()}
    </div>
  );
};

export default inject('boarding')(observer(PercentInput));
