import { action, computed, makeObservable, observable } from 'mobx';

import type { IBoardingData } from '../interfaces/IBoardingData';

class ProcessingStore implements IBoardingData {
  mcc: string = '';

  bsummary: string = '';

  whenCharged: string = '';

  whenProvided: string = '';

  whenDelivered: string = '';

  whenRefunded: string = '';

  binperson: number = 0;

  binphone: number = 0;

  binweb: number = 0;

  avgmonthly: number = 0;

  ticketamt: number = 0;

  highticketamt: number = 0;

  annualRevenue: number = 0;

  errors: any = {};

  constructor() {
    makeObservable(this, {
      mcc: observable,
      bsummary: observable,
      whenCharged: observable,
      whenProvided: observable,
      whenDelivered: observable,
      whenRefunded: observable,
      binperson: observable,
      binphone: observable,
      binweb: observable,
      avgmonthly: observable,
      ticketamt: observable,
      highticketamt: observable,
      annualRevenue: observable,
      errors: observable,
      setProp: action,
      setError: action,
      overflowPercent: computed,
    });
  }

  /**
   * If the sum of the three percentage values is greater than 100, then return true
   * @returns A boolean value.
   */
  get overflowPercent(): boolean {
    const totalPercent = +this.binperson + +this.binphone + +this.binweb;
    if (totalPercent > 100 || totalPercent <= 0) {
      this.setError('binperson', true);
      this.setError('binphone', true);
      this.setError('binweb', true);
      return true;
    }
    this.setError('binperson', false);
    this.setError('binphone', false);
    this.setError('binweb', false);
    return false;
  }

  /**
   * It takes a key of the ProcessingStore interface and a value of any type, and sets the value of the
   * key to the value
   * @param {N} name - The name of the property to set.
   * @param {any} value - any
   */
  setProp<N extends keyof ProcessingStore>(name: N, value: any): void {
    this[name] = value;
  }

  /**
   * It takes a key of the ProcessingStore interface and returns the value of that key
   * @param {K} field - K - The field we want to get the value of.
   * @returns The value of the field.
   */
  getValue<K extends keyof ProcessingStore>(field: K): any {
    return this[field];
  }

  /**
   * It takes a key of the ProcessingStore interface and a boolean value and sets the value of the key
   * in the errors object to the boolean value
   * @param {N} name - The name of the property in the errors object.
   * @param {boolean} value - The value to set the error to.
   */
  setError<N extends keyof ProcessingStore>(name: N, value: boolean): void {
    this.errors[name] = value;
  }

  /**
   * It takes a string that is a key of the errors object and returns a boolean
   * @param {N} name - The name of the processing state to check.
   * @returns A boolean value.
   */
  getError<N extends keyof ProcessingStore>(name: N): boolean {
    return this.errors[name];
  }
}
export default ProcessingStore;
